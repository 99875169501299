/* eslint-disable no-script-url */

import React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles' // withStyles
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { getTableFetchParams, graphQLFetch } from '@myx/console-utils'
import DataTable, { DataTableRowActions, DataTableSortLabel } from '../DataTable'
import { useMediaQuery, useTheme } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  adjLineHeight: {
    lineHeight: '0.95rem',
  },
  noWrap: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

function KioskRowActions({ subMatch, kiosk }) {
  const history = useHistory()

  return (
    <DataTableRowActions
      onDetailsClick={() => {
        history.push(`${subMatch.url}/${encodeURIComponent(kiosk.id)}`)
      }}
    />
  )
}

function KioskTable(props) {

  const classes = useStyles()

  const tableState = props.tableState

  const theme = useTheme()

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <>
      <DataTable
        variant={isSmallDevice ? 'mobile' : null}
        tableState={tableState}
        dataSource={() =>
          (props.kiosksFetch ? props.kiosksFetch(tableState) : graphQLFetch(`
            query($map: [MapEntryInput!]!, $limit: Long!, $offset: Long!) {
              getKiosks(
                map: $map,
                limit: $limit,
                offset: $offset
              ) {
                total
                kiosks {
                  id
                  assetCode
                  companyId
                  address1
                  city
                  state
                  zip
                  pour30Days
                  pour1Day
                  serviceAlertCount
                  inventoryAlertCount
                  accountDetail {
                    accountCode
                  }
                  serialDetail {
                    serialNumber
                  }
                }
              }
            }
          `, getTableFetchParams(
            tableState,
            {
              kiosk: [ 'string', 'serialDetail.serialNumber' ],
              accountCode: [ 'string', 'accountDetail.accountCode' ],
              address1: [ 'string', 'address1' ],
              city: [ 'string', 'city' ],
              pour30Days: [ 'string', 'pour30Days' ],
              pour1Day: [ 'string', 'pour1Day' ],
              serviceAlertCount: [ 'string', 'serviceAlertCount' ],
              inventoryAlertCount: [ 'string', 'inventoryAlertCount' ]
            }
          ))).then(({ getKiosks: { total, kiosks: data } }) =>
            ({ total, data })
          )
        }
      >
        {(data, subMatch) => (
          <>
            <colgroup>
              {isSmallDevice ? <>
                <col style={{ width: '35%' }}/>
                <col style={{ width: '35%' }}/>
                <col style={{ width: '25%' }}/>
                <col style={{ width: '10%' }}/>
              </> : <>
                <col style={{ width: '10%' }}/>
                <col style={{ width: '15%' }}/>
                <col style={{ width: '25%' }}/>
                <col style={{ width: '20%' }}/>
                <col style={{ width: '10%' }}/>
                <col style={{ width: '10%' }}/>
                <col style={{ width: '10%' }}/>
                <col style={{ width: '10%' }}/>
                <col style={{ width: '10%' }}/>
              </>
              }
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <DataTableSortLabel column="kiosk">Kiosk</DataTableSortLabel>
                </TableCell>
                <TableCell>
                  <DataTableSortLabel column="assetCode">Asset</DataTableSortLabel>
                </TableCell>
                {isSmallDevice ? <TableCell>Alerts</TableCell>
                  : <>
                    <TableCell>
                      <DataTableSortLabel column="address1">Street Address</DataTableSortLabel>
                    </TableCell>
                    <TableCell>
                      <DataTableSortLabel column="city">City</DataTableSortLabel>
                    </TableCell>
                    <TableCell align="center" className={classes.adjLineHeight}>
                      <DataTableSortLabel column="pour30Days">30 Day Pours</DataTableSortLabel>
                    </TableCell>
                    <TableCell align="center" className={classes.adjLineHeight}>
                      <DataTableSortLabel column="pour1Day">24 Hour Pours</DataTableSortLabel>
                    </TableCell>
                    <TableCell align="center" className={classes.adjLineHeight}>
                      <DataTableSortLabel column="serviceAlertCount">Service Alerts</DataTableSortLabel>
                    </TableCell>
                    <TableCell align="center" className={classes.adjLineHeight}>
                      <DataTableSortLabel column="inventoryAlertCount">Inventory Alerts</DataTableSortLabel>
                    </TableCell> </>
                }
                <TableCell align="center">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(row => (
                <TableRow key={row.id}>
                  <TableCell>
                    {/* row.assetCode || '—' */}
                    {(row.serialDetail && row.serialDetail.serialNumber) || '—'}
                  </TableCell>
                  <TableCell>
                    {row.assetCode || '—'}
                  </TableCell>
                  {isSmallDevice ? <TableCell>{row.serviceAlertCount + row.inventoryAlertCount}</TableCell>
                    : <>
                      <TableCell>
                        <span className={classes.noWrap}>{row.address1}</span></TableCell>
                      <TableCell>
                        <span className={classes.noWrap}>
                          {row.city && `${row.city}, `}{row.state}
                        </span>
                      </TableCell>
                      <TableCell align="center">{row.pour30Days}</TableCell>
                      <TableCell align="center">{row.pour1Day}</TableCell>
                      <TableCell align="center">{row.serviceAlertCount}</TableCell>
                      <TableCell align="center">{row.inventoryAlertCount}</TableCell>
                    </>
                  }
                  <TableCell align="center">
                    <KioskRowActions
                      subMatch={subMatch}
                      kiosk={row}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </DataTable>
    </>
  )

}

export default KioskTable
