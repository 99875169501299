import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

import AsyncSwitch from '../utils/AsyncSwitch'
import ExpiryDateDisplay from '../format/ExpiryDateDisplay'
import LevelPercentDisplay from '../format/LevelPercentDisplay'

const useStyles = makeStyles((theme) => ({
  noWrap: {
    whiteSpace: 'nowrap'
  },
  link: {
    color: `${theme.palette.primary.main}`,
    '&:visited': {
      color: `${theme.palette.primary.main}`
    },
    '&:hover': {
      color: `${theme.palette.secondary.main}`
    }
  }
}))

function EnabledSwitch(props) {
  return (
    <AsyncSwitch
      action={(updatedValue) =>
        props
          .updatePositionsEnabled(
            props.kioskPositionId,
            updatedValue
          )
          .then(({ updateKioskPositionEnabled: { enabled } }) => enabled)
      }
      initialChecked={props.enabled}
    />
  )
}

const getDailyUsePercent = (useData, positionId) => {
  const percentUse = useData.find((val) => val.kioskPositionId === positionId)
  return {
    dailyUsePercent: percentUse
      ? Number.parseFloat(percentUse.dailyUsePercent).toFixed(0)
      : '-'
  }
}

const getDaysRemaining = (useData, positionId) => {
  const percentUse = useData.find((val) => val.kioskPositionId === positionId)
  const remainingDays = percentUse
    ? Number.parseFloat(percentUse.remainingDays).toFixed(0)
    : '-'
  return { remainingDays: isNaN(remainingDays) ? '-' : remainingDays }
}

function KioskPositionsTable(props) {
  const classes = useStyles()

  const isPositionEmpty = (row) => {
    return row.ingredientContainerDetail === null
  }

  const theme = useTheme()

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Table size="small">
      {isSmallDevice ? <colgroup>
        <col style={{ width: '5%' }}/>
        <col style={{ width: '15%' }}/>
        <col style={{ width: '15%' }}/>
        <col style={{ width: '8%' }}/>
        <col style={{ width: '8%' }}/>
      </colgroup>
        : <colgroup>
          <col style={{ width: '10%' }}/>
          <col style={{ width: '20%' }}/>
          <col style={{ width: '20%' }}/>
          <col style={{ width: '20%' }}/>
          <col style={{ width: '10%' }}/>
          <col style={{ width: '12%' }}/>
          <col style={{ width: '10%' }}/>
          <col style={{ width: '10%' }}/>
        </colgroup>}
      <TableHead>
        <TableRow>
          <TableCell align="center">#</TableCell>
          <TableCell>Container Name</TableCell>
          <TableCell>Serial #</TableCell>
          {isSmallDevice ? null : <React.Fragment>
            <TableCell>Expiry</TableCell>
            <TableCell>Daily Use</TableCell>
          </React.Fragment>}
          <TableCell>Level</TableCell>
          <TableCell>~ Days Left</TableCell>

          {isSmallDevice ? null : <TableCell align="center">Enabled</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.tableData.positionData.map((row) => (
          <TableRow key={row.id}>
            <TableCell align="center">{row.position}</TableCell>
            <TableCell className={isSmallDevice ? null : classes.noWrap}>
              {isPositionEmpty(row)
                ? '—'
                : row.ingredientContainerDetail.ingredientDetail.name}
            </TableCell>
            <TableCell className={isSmallDevice ? null : classes.noWrap}>
              {isPositionEmpty(row)
                ? '—'
                : <RouterLink
                  className={classes.link}
                  to={`/containers/${encodeURIComponent(row.ingredientContainerDetail.id)}`}>
                  {row.ingredientContainerDetail.mfgSerialNumber}
                </RouterLink>
              }
            </TableCell>
            {isSmallDevice ? null
              : <React.Fragment>
                <TableCell className={isSmallDevice ? null : classes.noWrap}>
                  {isPositionEmpty(row) ? (
                    '—'
                  ) : (
                    <ExpiryDateDisplay
                      dt={row.ingredientContainerDetail.soonestExpireOn}
                    />
                  )}
                </TableCell>

                <TableCell>
                  {
                    getDailyUsePercent(props.tableData.positionUse, row.id)
                      .dailyUsePercent
                  }
                  %
                </TableCell>
              </React.Fragment>
            }
            <TableCell>
              {isPositionEmpty(row)
                ? '—'
                : row.ingredientContainerDetail &&
                  <LevelPercentDisplay percent={row.ingredientContainerDetail.remainingPercent || 0}/>
              }
            </TableCell>
            <TableCell>
              {
                getDaysRemaining(props.tableData.positionUse, row.id)
                  .remainingDays
              }
            </TableCell>
            {isSmallDevice ? null
              : <TableCell align="center">
                {props.editable ? (
                  <EnabledSwitch
                    updatePositionsEnabled={props.updatePositionsEnabled}
                    kioskId={props.kioskId}
                    kioskPositionId={row.id}
                    enabled={row.enabled}
                  />
                ) : row.enabled ? (
                  'Yes'
                ) : (
                  'No'
                )}
              </TableCell>
            }
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default KioskPositionsTable
