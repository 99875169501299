import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import { graphQLFetch, useAppModal } from '@myx/console-utils'
import OperatorContactDialog from '../dialogs/OperatorContactDialog'
import EmailDisplay from '../format/EmailDisplay'
import PhoneDisplay from '../format/PhoneDisplay'

function SettingsContactCard({ operator, onComplete }) {
  const openEditDialog = useAppModal(
    {
      title: 'Edit Contact',
      resultMessage: 'Contact saved successfully',
      initialValues: {
        ...operator
      },
      action: (formData) => graphQLFetch(
        `
          mutation ($data: UpdateCompanyContactInput!) {
            updateCompanyContact(update: $data) {
              id
            }
          }
        `,
        {
          data: {
            contactGivenName: formData.contactGivenName,
            contactFamilyName: formData.contactFamilyName,
            contactEmail: formData.contactEmail,
            contactPhoneCountry: formData.contactPhoneCountry,
            contactPhone: formData.contactPhone,
            contactPhoneExtension: formData.contactPhoneExtension,
          }
        }
      ),
      onComplete
    },
    <OperatorContactDialog/>
  )

  return (
    <Card>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            onClick={openEditDialog}
          >
            <EditIcon/>
          </IconButton>
        }
        title="Operator Contact"
      />
      <CardContent>
        <Table size="medium">
          <colgroup>
            <col style={{ width: '25%' }}/>
            <col style={{ width: '75%' }}/>
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>
                {(operator.contactGivenName || operator.contactFamilyName)
                  ? `${operator.contactGivenName} ${operator.contactFamilyName}`
                  : <em>Please add an organizational contact</em>
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>
                {operator.contactEmail
                  ? <EmailDisplay email={operator.contactEmail}/>
                  : <em>Please add a contact email address</em>
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phone</TableCell>
              <TableCell>
                {operator.contactPhone
                  ? (
                    <PhoneDisplay
                      country={operator.contactPhoneCountry}
                      phone={operator.contactPhone}
                      extension={operator.contactPhoneExtension}
                    />
                  )
                  : <em>Please add a contact number</em>
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default SettingsContactCard
