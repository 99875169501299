/* eslint-disable max-statements */
import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import { graphQLFetch, useAppModal } from '@myx/console-utils'
import KioskSizesDialog from '../dialogs/KioskSizesDialog'

async function processSizeChanges(kioskId, formData) {
  // trust latest backend values over component/props
  const { getKioskSizes: { kioskSizes } } = await graphQLFetch(
    `
      query($id: String!) {
        getKioskSizes(
          map: [{ key: "orderBy", value: "size"}],
          limit: 1000,
          offset: 0,
          kioskId: $id
        ) {
          kioskSizes {
            id
          }
        }
      }
    `,
    {
      id: kioskId
    }
  )

  // store the database id of the size where defaultSize === true
  let defaultSizeId = null

  // check for deletions vs. updates (update all sizes that aren't being deleted)
  for (const size of kioskSizes) {
    const updatedSize = formData.sizes.find(sz => sz.id === size.id)
    if (updatedSize) {
      // eslint-disable-next-line no-await-in-loop
      await graphQLFetch(
        `
          mutation ($kioskId: String!, $sizeId: String!, $update: UpdateKioskSizeInput!) {
            updateKioskSize(kioskId: $kioskId, sizeId: $sizeId, update: $update) { id }
          }
        `,
        {
          kioskId: kioskId,
          sizeId: size.id,
          update: {
            name: updatedSize.name,
            size: updatedSize.size,
          }
        }
      )
      // if this is the defaultSize then update the id
      if (updatedSize.defaultSize) {
        defaultSizeId = updatedSize.id
      }
    } else {
      // eslint-disable-next-line no-await-in-loop
      await graphQLFetch(
        `
          mutation ($kioskId: String!, $sizeId: String!) {
            removeKioskSize(kioskId: $kioskId, sizeId: $sizeId) { id }
          }
        `,
        {
          kioskId: kioskId,
          sizeId: size.id,
        }
      )
    }
  }

  // checking for additions (note - new sizes have a tempId)
  for (const size of formData.sizes) {
    if (size.tempId) {
      // eslint-disable-next-line no-await-in-loop
      const { addKioskSize: { id: newSizeId } } = await graphQLFetch(
        `
          mutation ($kioskId: String!, $add: AddKioskSizeInput!) {
            addKioskSize(kioskId: $kioskId, add: $add) { id }
          }
        `,
        {
          kioskId: kioskId,
          add: {
            name: size.name,
            size: size.size
          }
        }
      )
      // if this size is the defaultSize then update the id
      if (size.defaultSize) {
        defaultSizeId = newSizeId
      }
    }
  }

  // set default size
  if (defaultSizeId) { // smoke-check
    await graphQLFetch(
      `
        mutation ($kioskId: String!, $sizeId: String!, $update: UpdateKioskSizeDefaultInput!) {
          updateKioskSizeDefault(kioskId: $kioskId, sizeId: $sizeId, update: $update) { id }
        }
      `,
      {
        kioskId: kioskId,
        sizeId: defaultSizeId,
        update: {
          defaultSize: true,
        }
      }
    )
  }

}

function KioskSizeActionButton(props) {
  const openDialog = useAppModal(
    {
      title: 'Sizes',
      initialValues: { sizes: props.sizes },
      action: (formData) => processSizeChanges(props.kioskId, formData),
      onComplete: props.onComplete
    },
    (form) => (
      <KioskSizesDialog
        form={form}
        // sizes={form.values.sizes}
      />
    )
  )

  return (
    <IconButton aria-label="Edit Sizes" onClick={openDialog}>
      <EditIcon/>
    </IconButton>
  )
}

function KioskSizesCard({ kiosk, editable, onComplete }) {
  const convertOzToMl = (ounces) => Math.round((29.574 * ounces * 10) / 10)

  return (
    <Card>
      <CardHeader
        action={editable &&
          <KioskSizeActionButton kioskId={kiosk.id} sizes={kiosk.sizes} onComplete={onComplete}/>
        }
        title="Sizes"
      />
      <CardContent>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell>Size Oz (mL)</TableCell>
              <TableCell>Default Size</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(kiosk.sizes.length === 0) &&
              <TableRow>
                <TableCell colSpan="3"><em>No Sizes Defined</em></TableCell>
              </TableRow>
            }
            {
              kiosk.sizes.map(size => (
                <TableRow key={size.size}>
                  <TableCell>{size.name}</TableCell>
                  <TableCell>{size.size} ({convertOzToMl(size.size)})</TableCell>
                  <TableCell>{size.defaultSize ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )

}

export default KioskSizesCard
