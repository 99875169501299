import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import DateDisplay from '../format/DateDisplay'
import DaysSinceDateDisplay from '../format/DaysSinceDateDisplay'
import { Typography, useMediaQuery, useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 350,
  },
  smallTable: {
    borderBottom: '1.5px solid #c4c4c4',
  },
  faultTypography: {
    fontSize: '11px',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  link: {
    color: `${theme.palette.primary.main}`,
    '&:visited': {
      color: `${theme.palette.primary.main}`,
    },
    '&:hover': {
      color: `${theme.palette.secondary.main}`,
    },
  },
}))

const ContainerLink = React.forwardRef((props, ref) => {
  const classes = useStyles()
  const { container, ...otherProps } = props

  return (
    <RouterLink
      className={classes.link}
      innerRef={ref}
      to={`/containers/${encodeURIComponent(container.id)}`}
      {...otherProps}
    />
  )
})

function SmallDeviceAlertTable({ tableData }) {
  const classes = useStyles()

  return (<>
    {(tableData.length === 0) &&
      <Table size="small" className={classes.table}>
        <colgroup>
          <col style={{ width: '50%' }}/>
          <col style={{ width: '50%' }}/>
        </colgroup>
        <TableBody>
          <TableRow>
            <TableCell colSpan="4"><em>No Active Service Alerts</em></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    }
    {
      tableData.map((alert, index) => (
        <Table size="small" className={classes.smallTable} style={{ backgroundColor: (index % 2) === 0 ? '#f8f7f7' : '#ffffff' }}>
          <colgroup>
            <col style={{ width: '20%' }}/>
            <col style={{ width: '80%' }}/>
          </colgroup>
          <TableBody>
            <TableRow key={`faultcode-${alert.id}`}>
              <TableCell>Code</TableCell>
              <TableCell>
                <Typography className={classes.faultTypography} variant="p">{alert.faultCode}</Typography>
              </TableCell>
            </TableRow>
            <TableRow key={`occurredon-${alert.id}`}>
              <TableCell>Date</TableCell>
              <TableCell><DateDisplay dt={alert.occurredOn} displayTime format24Hours /></TableCell>
            </TableRow>
            <TableRow key={`dayssince-${alert.id}`}>
              <TableCell>Age</TableCell>
              <TableCell>
                <DaysSinceDateDisplay dt={alert.occurredOn} /> Days
              </TableCell>
            </TableRow>
            {
              alert.ingredientContainerDetail && <>
                <TableRow key={`serial-${alert.id}`}>
                  <TableCell>Serial#</TableCell>
                  <TableCell className={classes.noWrap}>
                    <ContainerLink container={alert.ingredientContainerDetail}>{alert.ingredientContainerDetail.mfgSerialNumber}</ContainerLink>
                  </TableCell>
                </TableRow>
                <TableRow key={`flavor-${alert.id}`}>
                  <TableCell>Flavor</TableCell>
                  <TableCell>
                    {alert.ingredientContainerDetail.ingredientDetail ? alert.ingredientContainerDetail.ingredientDetail.name : '—'}
                  </TableCell>
                </TableRow>
              </>
            }
          </TableBody>
        </Table>
      ))
    }</>)
}

function AlertTable({ isIngredientAlerts, tableData }) {
  const classes = useStyles()
  return (<Table size="small" className={classes.table}>
    <colgroup>
      { isIngredientAlerts
        ? (<>
          <col style={{ width: '20%' }}/>
          <col style={{ width: '25%' }}/>
          <col style={{ width: '15%' }}/>
          <col style={{ width: '10%' }}/>
          <col style={{ width: '30%' }}/>
        </>)
        : (<>
          <col style={{ width: '30%' }}/>
          <col style={{ width: '40%' }}/>
          <col style={{ width: '30%' }}/>
        </>)
      }
    </colgroup>
    <TableHead>
      <TableRow>
        <TableCell>Code</TableCell>
        <TableCell>Date</TableCell>
        <TableCell>Age</TableCell>
        {isIngredientAlerts &&
          <>
            <TableCell>Serial #</TableCell>
            <TableCell>Flavor</TableCell>
          </>
        }
      </TableRow>
    </TableHead>
    <TableBody>
      {(tableData.length === 0) &&
        <TableRow>
          <TableCell colSpan="4"><em>No Active Service Alerts</em></TableCell>
        </TableRow>
      }
      {
        tableData.map(alert => (
          <TableRow key={alert.id}>
            <TableCell>{alert.faultCode}</TableCell>
            <TableCell>
              <DateDisplay dt={alert.occurredOn} displayTime format24Hours />
            </TableCell>
            <TableCell>
              <DaysSinceDateDisplay dt={alert.occurredOn} /> Days
            </TableCell>
            {isIngredientAlerts &&
              <>
                <TableCell className={classes.noWrap}>
                  <ContainerLink container={alert.ingredientContainerDetail}>{alert.ingredientContainerDetail.mfgSerialNumber}</ContainerLink>
                </TableCell>
                <TableCell>
                  {alert.ingredientContainerDetail.ingredientDetail ? alert.ingredientContainerDetail.ingredientDetail.name : '—'}
                </TableCell>
              </>
            }
          </TableRow>
        ))
      }
    </TableBody>
  </Table>
  )
}

function KioskAlertsTable(props) {

  const isIngredientAlerts = (props.tableData.length > 0 && props.tableData[0].ingredientContainerDetail)

  const theme = useTheme()

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('xs'))

  return (isSmallDevice ? <SmallDeviceAlertTable isIngredientAlerts={isIngredientAlerts} tableData={props.tableData}/>
    : <AlertTable isIngredientAlerts={isIngredientAlerts} tableData={props.tableData}/>)

}

export default KioskAlertsTable
