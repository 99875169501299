import { ThemeOptions } from '@material-ui/core'

export default {
  palette: {
    chart: [
      '#ff0000', '#00ff00', '#0000ff',
      '#FFFF00', '#00FFFF', '#FF00FF',
      '#808080', '#800000', '#808000',
      '#008000', '#000080', '#800080'
    ],
    toolbar: {
      main: 'rgba(37, 37, 37, 1)',
      secondary: '#454545'
    },
    secondary: {
      light: '#927ABF', // this is lighter than default theme
      main: '#6B4FA1',
      dark: '#52348c',
      contrastText: '#fff',
    },
    primary: {
      light: '#CE5DDE',
      main: '#c53bd9',
      dark: '#9D02B3',
      contrastText: '#000',
    },
    background: {
      paper: 'rgba(51,55,75,0.91)',
      default: 'rgba(0,0,0,0.87)'
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(200, 200, 200, 0.54)',
      disabled: 'rgba(200, 200, 200, 0.38)',
      hint: 'rgba(200, 200, 200, 0.38)'
    },
    action: {
      active: 'rgba(200, 200, 200, 0.54)',
      hover: 'rgba(200, 200, 200, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(200, 200, 200, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(200, 200, 200, 0.26)',
      disabledBackground: 'rgba(200, 200, 200, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(200, 200, 200, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12
    }
  },
} as ThemeOptions

