import React from 'react'
import { graphQLFetch, PageView, TOUCHLESS_URL } from '@myx/console-utils'
import { useHistory, useRouteMatch } from 'react-router'
import ChangeContainerAtKioskPage from './ChangeContainerAtKioskPage'
import { Paper, Typography } from '@material-ui/core'
import QrReader from 'react-qr-reader'
import ErrorView from './ErrorView'

const parseKioskToken = (url) => {
  // eslint-disable-next-line no-unused-vars
  const [ _urlBase, searchParams ] = url.split('?')

  const urlParams = new URLSearchParams(searchParams)
  return urlParams.get('myx-session-token') || url
}

const parseQrCodeOrNull = (url) => {
  // eslint-disable-next-line no-unused-vars
  const [ _urlBase, searchParams ] = url.split('?')

  const urlParams = new URLSearchParams(searchParams)
  return urlParams.get('myx-session-token')
}

// eslint-disable-next-line max-statements
function ChangeContainerPage({ match }) {

  // Load QR code from url if token is provided in request params.
  const [ qrCode, setQrCode ] = React.useState(parseQrCodeOrNull(window.location.href))

  const [ scanning, setScanning ] = React.useState(false)

  const history = useHistory()

  const handleScan = data => {
    setQrCode(data)
  }

  if (qrCode && !scanning) {
    const parsedQr = parseKioskToken(qrCode)

    setScanning(true)

    fetch(`${TOUCHLESS_URL}/kiosk/connect`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: parsedQr })
    }).then(() => {
      return graphQLFetch(`
          mutation($qrCode: KioskQrAddInput!) {
             addQrLogin(add: $qrCode) {
                kioskId
                code
             }
          }
      `, {
        qrCode: { code: parsedQr }
      }).then(({ addQrLogin: data }) => {
        setQrCode(null)
        setScanning(false)
        history.push(`${match.url}/${encodeURIComponent(data.kioskId)}/${encodeURIComponent(parsedQr)}`)
      })
    }).catch(() => {
      setQrCode(null)
      setScanning(false)
    })
  }

  const atKioskMatch = useRouteMatch({
    path: `${match.path}/:id/:token`
  })

  const connectErrorMatch = useRouteMatch({
    path: '/changecontainer/connectionfailed',
    exact: true
  })

  if (atKioskMatch) {
    return <ChangeContainerAtKioskPage match={atKioskMatch}/>
  }

  return (<PageView url={match.url} title="Scan Kiosk QR Code">
    {connectErrorMatch
      ? <ErrorView
        title="Kiosk Connection Failed"
        message="Please rescan and try again."
        onConfirm={() => history.push('/changecontainer')}
      />
      : <Paper>
        <Typography variant="body2" color="secondary" align="center">
          Scan the Qr Code on the kiosk to begin.
        </Typography>
        <QrReader
          facingMode={'environment'}
          delay={300}
          onScan={handleScan}
          style={{ width: '100%', height: '75%' }}
        />
      </Paper>}
  </PageView>)
}

export default ChangeContainerPage
