import React from 'react'

import { Link as RouterLink } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import MenuIcon from '@material-ui/icons/Menu'

import { Box, useTheme } from '@material-ui/core'
import UserAvatarMenu from './UserAvatorMenu'
import { UserSession } from '../@types/UserSession'
import { MyxLogoSVG } from './brand/MyxLogoSVG'

// regarding z-index below - drawer is 1200, and when temporary it's 1300, so initially set + 101 = 1301 (1400 prob safest)
// however this caused an issue with top bar not being covered by modal/popups and menus spilling out, so now add +1
const useStyles = makeStyles(theme => ({
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

type Props = {
  session: UserSession | null,
  onMenuClick: () => void,
  pageUrl: string
}

function Header({ session, onMenuClick, pageUrl }: Props) {

  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <AppBar position="fixed">
      <Toolbar>
        <Box className={classes.iconContainer}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Menu"
            onClick={onMenuClick}>
            <MenuIcon/>
          </IconButton>
        </Box>
        <Box>
          <RouterLink
            to="/dashboard"
            style={{
              flex: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textDecoration: 'none'
            }}>
            <MyxLogoSVG
              height={'2em'}
              width={'2em'}
              color={'#FFFFFF'}
            />
            <Typography
              component="h1"
              variant="h6"
              noWrap
              style={{
                color: theme.palette.common.white,
                textDecoration: 'none'
              }}>
              MYX Admin
            </Typography>
          </RouterLink>
        </Box>
        <Box style={{ flexGrow: 1 }}/>
        {
          session && <UserAvatarMenu session={session} pageUrl={pageUrl}/>
        }
      </Toolbar>
    </AppBar>
  )
}

export default Header
