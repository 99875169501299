/**
 * Consider turning this into an API call for the kiosk model...
 */
const e430PosDesOrder = {
  1: 9,
  2: 10,
  3: 11,
  4: 12,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 1,
  10: 2,
  11: 3,
  12: 4,
}

export const e430PosSort = (p1, p2) => e430PosDesOrder[p1] > e430PosDesOrder[p2] ? 1 : -1
