import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import { graphQLFetch, useAppModal } from '@myx/console-utils'
import DateDisplay from '../format/DateDisplay'

const useStyles = makeStyles(() => ({
  noWrap: {
    whiteSpace: 'nowrap',
  },
}))

function DeleteRowAction({ invite, onComplete }) {

  const openDeleteDialog = useAppModal(
    {
      title: 'Delete Invite',
      action: () => graphQLFetch(
        `
          mutation removeUser($id: String!) {
            removeUserRegistration(id: $id) {
              id
            }
          }
        `,
        {
          id: invite.id
        }
      ),
      onComplete,
      actionLabel: 'Delete',
      resultMessage: 'Deleted successfully'
    },
    <Typography variant="body1">Really delete user invite for {invite.email}?</Typography>
  )

  return (
    <Box display="flex" justifyContent="flex-end" mx={-1}>
      <Tooltip title="Delete Invite">
        <IconButton color="primary" size="small" onClick={openDeleteDialog} aria-label="Delete Invite">
          <CloseIcon/>
        </IconButton>
      </Tooltip>
    </Box>
  )
}

function UserInvitesTable({ tableData, refresh }) {

  const classes = useStyles()

  return (
    <>
      <Table size="small">
        <colgroup>
          <col style={{ width: '50%' }}/>
          <col style={{ width: '30%' }}/>
          <col style={{ width: '10%' }}/>
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Invited On</TableCell>
            <TableCell align="right">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length === 0 &&
            <TableRow>
              <TableCell colSpan="3"><em>No user invites are pending.</em></TableCell>
            </TableRow>
          }
          {tableData.length > 0 && tableData.map(invite => (
            <TableRow key={invite.id}>
              <TableCell className={classes.noWrap}>
                {invite.email}
              </TableCell>
              <TableCell className={classes.noWrap}>
                <DateDisplay dt={invite.createdOn}/>
              </TableCell>
              <TableCell>
                <DeleteRowAction invite={invite} onComplete={refresh}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )

}

export default UserInvitesTable
