import React, { useEffect, useRef } from 'react'
import { Formik } from 'formik'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useAsyncCallback } from 'react-async-hook'
import { useModal } from 'react-modal-hook'
import { useAppSnack } from './AppSnack'

// contain async state inside subcomponent to clear it when modal is hidden
const AppModalInstance = ({
  title,
  initialValues = {},
  action = () => Promise.resolve(),
  actionLabel = 'Save',
  actionDisabled,
  resultMessage = 'Saved successfully',
  onComplete,
  open = false,
  hideModal,
  onExited,
  children,
  maxWidth,
  noSave = false,
}) => {
  const showSnack = useAppSnack()

  // stash dynamic values on a ref to avoid re-triggering effect
  const resultMessageRef = useRef(resultMessage)
  resultMessageRef.current = resultMessage
  const onCompleteRef = useRef(onComplete)
  onCompleteRef.current = onComplete

  // wrap action output in a truthy object that also saves initial inputs
  const actionAsync = useAsyncCallback(
    values => action(values).then(result => ({ values, result }))
  )

  // handle success outcome
  useEffect(() => {
    if (!actionAsync.result) {
      return
    }

    const { values, result } = actionAsync.result

    const
      onCompleteLatest = onCompleteRef.current,
      resultMessageLatest = resultMessageRef.current

    const resultMessageValue = typeof resultMessageLatest === 'function'
      ? resultMessageLatest(result, values)
      : resultMessageLatest

    if (resultMessageValue) {
      showSnack(resultMessageValue)
    }

    hideModal()

    if (onCompleteLatest) {
      onCompleteLatest(result, values)
    }
  }, [ actionAsync.result, showSnack, hideModal ])

  // handle error outcome
  useEffect(() => {
    if (!actionAsync.error) {
      return
    }

    showSnack('Action unsuccessful')
  }, [ actionAsync.error, showSnack ])

  return (
    <Dialog open={open} onClose={hideModal} onExited={onExited} fullWidth
      maxWidth ={maxWidth}>
      <Formik
        initialValues={initialValues}
        // validateOnChange={false}
        onSubmit={(values, form) => {
          form.setSubmitting(false) // clear leftover flag

          if (actionAsync.loading) {
            return
          }

          actionAsync.execute(values)
        }}
      >
        {(form) => (
          <form onSubmit={form.handleSubmit} autoComplete="off" noValidate>
            <DialogTitle>{title}</DialogTitle>

            <DialogContent dividers>
              {typeof children === 'function' ? children(form, hideModal) : children}
            </DialogContent>

            <DialogActions>
              {!actionAsync.loading && actionAsync.error
                ? <Box
                  pl={2}
                  mr={2}
                  lineHeight={1}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  color="error.main"
                >
                  Error: {actionAsync.error.message || actionAsync.error.toString()}
                </Box>
                : null
              }
              <Button onClick={hideModal} color="primary">
                Cancel
              </Button>
              {
                noSave ? <React.Fragment/> : <Button type="submit" color="primary"
                  disabled={actionAsync.loading || (actionDisabled && actionDisabled(form))}>
                  {actionLabel}
                </Button>
              }
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

export function useAppModal(
  spec,
  contents,
  refreshDeps = [],
  maxWidth = 'sm'
) {

  const specRef = useRef(spec)
  specRef.current = spec

  const contentsRef = useRef(contents)
  contentsRef.current = contents

  // modal state
  const [ showModal, hideModal ] = useModal(
    ({ in: open, onExited }) =>
      <AppModalInstance
        {...specRef.current}
        open={open}
        hideModal={hideModal}
        onExited={onExited}
        maxWidth={maxWidth}
        noSave={!specRef.current.action}
      >
        { spec.passProps ? React.cloneElement(contentsRef.current, {
          hideModal: hideModal,
          onComplete: spec.onComplete,
        }) : contentsRef.current}
      </AppModalInstance>
    ,
    refreshDeps // refresh when indicated dependencies change
  )
  return showModal
}
