import React from 'react'
import { DateTime } from 'luxon'

type Props = {
  dt: string | null | undefined,
  displayTime?: boolean,
  format24Hours?: boolean,
  color?: string,
}

function DateDisplay({ dt, displayTime = false, format24Hours = false, color = 'black' }: Props) {

  if (!dt) {
    return <span>—</span>
  }

  // graphql response includes offset (+0000)
  const date = DateTime.fromISO(dt)

  return (
    <span style={{ color: color }}>
      {date.toISODate()}
      {displayTime &&
        (format24Hours
          ? ` ${date.toFormat('hh:mm:ss')}`
          : ` ${date.toFormat('tt')}`)}
    </span>
  )
}

export default DateDisplay
