import React from 'react'
import { useAsync } from 'react-async-hook'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { graphQLFetch } from '@myx/console-utils'
import ProgressWrapper from '../components/ProgressWrapper'
import ContainerHistoryTable from '../components/tables/ContainerHistoryTable'
import TimezoneDisplay from '../components/format/TimezoneDisplay'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(0)}px`,
    overflow: 'auto',
  },
}))

function ContainerHistoryPanel(props) {
  const classes = useStyles()

  // assume that query always returns container history in relevant chronological order
  const historyDataAsync = useAsync(() => {
    return graphQLFetch(`
      query($containerId: String!) {
        getIngredientContainerHists(
          containerId: $containerId
        ) {
          id
          action
          remainingPercent
          punctureOn
          changedOn
          kioskPositionDetail {
            id
            position
            kioskDetail {
              id
              serialDetail {
                id
                serialNumber
              }
            }
          }
          changedByUser {
            givenName
            familyName
          }
          ingredientDetail {
            id
            name
          }
        }
      }
    `, {
      containerId: props.containerId,
    }).then(({ getIngredientContainerHists: data }) => (
      data
    ))
  }, [], {
    // preserve previously loaded data while new data is being fetched
    setLoading: state => ({ ...state, loading: true })
  })

  const history = historyDataAsync.result

  return (
    <ProgressWrapper pending={historyDataAsync.loading || !historyDataAsync.result}>
      {history &&
        <Paper className={classes.paper}>
          <Typography component="h2" variant="h6" color="primary">
            Container History
          </Typography>
          <ContainerHistoryTable history={history}/>
          <Box pl={2} mt={2} mb={2}>
            Timezone: <TimezoneDisplay/>
          </Box>
        </Paper>
      }
    </ProgressWrapper>
  )
}

export default ContainerHistoryPanel
