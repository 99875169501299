import React, { useEffect } from 'react'
import { useAsyncCallback } from 'react-async-hook'

import Switch from '@material-ui/core/Switch'

import { useAppSnack } from './AppSnack'

type Props = {
  action: (checked: boolean) => void
  initialChecked: boolean
}

/**
 * Given an async `action` such as output from a graphQLFetch, and an `initialChecked` state for initial render,
 * this component updates a Material UI Switch and displays a Snackbar on success and error. The Switch is
 * disabled while the action is loading.
 */
function AsyncSwitch({ action, initialChecked }: Props) {
  const showSnack = useAppSnack()

  // setLoading trick preserves existing results data when a new request is loading/pending - refer to react-async-hook docs
  const asyncAction = useAsyncCallback(action, {
    setLoading: (state) => ({ ...state, loading: true }),
    setError: (error, state) => ({ ...state, error: error, loading: false })
  })

  useEffect(() => {
    if (asyncAction.result === undefined) {
      return
    }

    showSnack(`State changed to ${asyncAction.result ? 'enabled' : 'disabled'}`)
  }, [ asyncAction.result, showSnack ])

  useEffect(() => {
    if (!asyncAction.error) {
      return
    }

    showSnack('Error changing position state')
  }, [ asyncAction.error, showSnack ])

  const currentEnabledValue =
    asyncAction.result === undefined ? initialChecked : asyncAction.result

  return (
    <Switch
      size="small"
      checked={currentEnabledValue}
      disabled={asyncAction.loading}
      onChange={(event) => asyncAction.execute(event.target.checked)}
      color="primary"
    />
  )
}

export default AsyncSwitch
