import React, { useMemo } from 'react'
import { useField } from 'formik'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import FilterEntryTypeInput from './FilterEntryTypeInput'
import FilterEntryValueInput from './FilterEntryValueInput'

export default function FilterEntryField({ name, onRemove, filterOptions, usedFilterTypes }) {
  const filterUsageMap = useMemo(() => {
    const lookupMap = {}

    usedFilterTypes.forEach(filterType => {
      lookupMap[filterType] = usedFilterTypes.some(item => item === filterType)
    })

    return lookupMap
  }, [ usedFilterTypes ])

  const [ typeField ] = useField(`${name}.type`)

  return (
    <Box display="flex" alignItems="center" mb={2}>
      <Box flexGrow={1} width={0}>
        <FilterEntryTypeInput
          name={name}
          filterOptions={filterOptions}
          filterUsageMap={filterUsageMap}
        />
      </Box>

      <Box pl={1} />

      <Box flexGrow={2} width={0}>
        <FilterEntryValueInput
          name={name}
          filterTypeOptions={filterOptions[typeField.value]}
        />
      </Box>

      <Box pl={1} />

      <IconButton
        color="default"
        size="small"
        onClick={() => {
          onRemove()
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  )
}
