import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import { graphQLFetch, useAppModal } from '@myx/console-utils'

import KioskAssetDialog from '../dialogs/KioskAssetDialog'

function KioskAssetCard({ kiosk, editable, onComplete }) {
  const openEditDialog = useAppModal(
    {
      title: 'Edit Asset',
      resultMessage: 'Asset saved successfully',
      initialValues: {
        assetCode: kiosk.assetCode,
        accountId: kiosk.accountDetail && kiosk.accountDetail.id,
      },
      action: (formData) => graphQLFetch(
        `
          mutation ($kioskId: String!, $data: UpdateKioskAssetInput!) {
            updateKioskAsset(kioskId: $kioskId, update: $data) {
              id
            }
          }
        `,
        {
          kioskId: kiosk.id,
          data: {
            assetCode: formData.assetCode,
            accountId: formData.accountId
          }
        }
      ),
      onComplete
    },
    <KioskAssetDialog/>
  )

  return (
    <Card>
      <CardHeader
        action={editable &&
          <IconButton
            aria-label="Edit Asset Information"
            onClick={openEditDialog}
          >
            <EditIcon/>
          </IconButton>
        }
        title="Asset"
      />
      <CardContent>
        <Table size="medium">
          <colgroup>
            <col style={{ width: '50%' }}/>
            <col style={{ width: '50%' }}/>
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Model</TableCell>
              <TableCell>{(kiosk.modelDetail && kiosk.modelDetail.name) || '—'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Asset</TableCell>
              <TableCell>{kiosk.assetCode || '—'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Account</TableCell>
              <TableCell>{(kiosk.accountDetail && kiosk.accountDetail.accountCode) || '—'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )

}

export default KioskAssetCard
