import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import Kiosk from './Kiosk'
import { PageView } from '@myx/console-utils'
import { usePaginatedTable } from '../components/PaginatedTable'
import KiosksView from '../KiosksView'

function Machines({ match, kiosksFetch = null }) {

  const tableState = usePaginatedTable({
    match,
    filterSlugs: {},
    defaultSortColumn: 'kiosk',
    defaultPageSize: 10
  })

  const kioskMatch = useRouteMatch({
    path: `${tableState.subMatch.path}/:id`
  })

  if (kioskMatch) {
    return <Kiosk match={kioskMatch}/>
  }

  return (
    <PageView url={match.url} title="Kiosks">
      <KiosksView match={match} kiosksFetch={kiosksFetch}/>
    </PageView>
  )

}

export default Machines
