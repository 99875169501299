import React from 'react'
import { GoogleIcon } from './icons'
import { createStyles, withStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles/createTheme'

type Props = {
  label?: string,
  disabled?: boolean,
  tabIndex?: number,
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void,
  type?: string,
  style?: any
}

const defaultProps: Props = {
  label: 'Sign in with Google',
  disabled: false,
  type: 'dark',
  tabIndex: 0,
  // eslint-disable-next-line no-empty-function
  onClick: () => {
  },
  style: {},
}

const styles = (theme: Theme) => createStyles({
  root: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    height: '50px',
    border: 'none',
    textAlign: 'center',
    verticalAlign: 'center',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.25)',
    fontSize: '16px',
    lineHeight: '48px',
    display: 'block',
    borderRadius: '1px',
    transition: 'background-color .218s, border-color .218s, box-shadow .218s',
    fontFamily: 'Roboto,arial,sans-serif',
    cursor: 'pointer',
    userSelect: 'none',

    width: '100%',
    // dark mode styling.. but looks good with light mode too imo..
    backgroundColor: '#4285f4',
    color: '#fff',

    '&:hover': {
      boxShadow: '0 0 3px 3px rgba(66,133,244,.3)',
      transition: 'background-color .218s, border-color .218s, box-shadow .218s'
    }
  }
})

const GoogleDivWrapper = withStyles(styles)((props: any) => <div
  {...props}
  className={props.classes.root}
  role="button"
>
  {props.children}
</div>)

function GoogleButton(props: Props) {
  return (
    <GoogleDivWrapper{...props}>
      <GoogleIcon {...props} />
      <span>{props.label}</span>
    </GoogleDivWrapper>
  )
}

GoogleButton.defaultProps = defaultProps

export default GoogleButton
