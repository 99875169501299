import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import { graphQLFetch, useAppModal } from '@myx/console-utils'
import MyAccountAlertsDialog from '../dialogs/MyAccountAlertsDialog'

function MyAccountAlertsCard({ user, onComplete }) {

  const openEditDialog = useAppModal(
    {
      title: 'Alert Notifications',
      resultMessage: 'Notification preferences saved successfully',
      initialValues: {
        ...user
      },
      action: (formData) => graphQLFetch(
        `
          mutation ($userId: String!, $data: UpdateUserAlertInput!) {
            updateUserAlert(userId: $userId, update: $data) {
              id
            }
          }
        `,
        {
          userId: user.id,
          data: {
            serviceAlert: formData.serviceAlert,
            inventoryAlert: formData.inventoryAlert,
            alertWithEmail: formData.alertWithEmail,
            alertWithSms: formData.alertWithSms,
          }
        }
      ),
      onComplete
    },
    <MyAccountAlertsDialog/>
  )

  return (
    <Card>
      <CardHeader
        action={
          <IconButton
            aria-label="edit alert notifications"
            onClick={openEditDialog}
          >
            <EditIcon/>
          </IconButton>
        }
        title="Alert Notifications"
      />
      <CardContent>
        <Table size="medium">
          <colgroup>
            <col style={{ width: '40%' }}/>
            <col style={{ width: '60%' }}/>
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Service Alerts</TableCell>
              <TableCell>
                {(user.serviceAlert && (user.alertWithEmail || user.alertWithSms))
                  ? (
                    <span>
                      {user.alertWithEmail && 'Email'}
                      {user.alertWithEmail && user.alertWithSms && ', '}
                      {user.alertWithSms && 'SMS'}
                    </span>
                  )
                  : <em>No Notifications</em>
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Inventory Alerts</TableCell>
              <TableCell>
                {(user.inventoryAlert && (user.alertWithEmail || user.alertWithSms))
                  ? (
                    <span>
                      {user.alertWithEmail && 'Email'}
                      {user.alertWithEmail && user.alertWithSms && ', '}
                      {user.alertWithSms && 'SMS'}
                    </span>
                  )
                  : <em>No Notifications</em>
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default MyAccountAlertsCard
