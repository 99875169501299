import React from 'react'
import { Field } from 'formik'
import Checkbox from '@material-ui/core/Checkbox'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

/**
 * Present a set of checkboxes that toggle individual (indpendent) boolean flags i.e. with their own names
 * rather than as an array.
 */
function AppFormCheckboxFieldGroup(props) {

  const error = false // !!(meta.touched && meta.error)

  const namePrefix = props.name ? props.name + '.' : ''

  return (
    <FormControl component="fieldset" fullWidth margin="normal">
      <FormLabel component="legend">{props.label}</FormLabel>
      <FormGroup row>
        {Object.keys(props.options).map((option) => (
          <FormControlLabel
            key={option}
            control={
              <Field as={Checkbox} type="checkbox" name={`${namePrefix}${option}`} />
            }
            label={props.options[option]}
          />
        ))}
      </FormGroup>
      {(error || props.helperText) &&
        <FormHelperText>{/* (error && meta.error) || props.helperText */}</FormHelperText>
      }
    </FormControl>
  )
}

export default AppFormCheckboxFieldGroup
