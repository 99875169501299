import React from 'react'
import ContainerInfo from './ContainerInfo'
import ContainerQRScanModal from './dialogs/ContainerQRScanModal'
import ContainerKeyboardModal from './dialogs/ContainerKeyboardModal'
import { enterMethods } from './cards/ChangePositionContainersCard'
import { catchKioskConnectError, kioskApiFetch } from '../touchlessKioskFetch'
import { useHistory } from 'react-router'
import { CONNECT_REDIRECT_TO } from '../pages/ChangeContainerAtKioskPage'
import { useAppModal } from '@myx/console-utils'

function PositionChangeContainerController({ onComplete, token, position, enterMethod = enterMethods.qr }) {

  const history = useHistory()

  const container = position.ingredientContainerDetail
  const ingredient = container ? container.ingredientDetail : null

  const changeContainerProps = {
    installedContainer: container,
    position: position,
  }

  const containerChangeModal = useAppModal({
    title: `Update Position ${position.position}`,
    resultMessage: 'Update Sent To Kiosk',
    initialValues: {
      serialNumber: null
    },
    action: (formData) => kioskApiFetch(`/v1/service/position/${position.id}/container`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'myx-session-token': token
      },
      body: JSON.stringify({ mfgSerialNumber: formData.serialNumber })
    }).catch((ex) => catchKioskConnectError(ex, history, CONNECT_REDIRECT_TO)),
    // eslint-disable-next-line no-console
    onComplete: onComplete
  },
  (form) => enterMethod === enterMethods.keyboard
    ? <ContainerKeyboardModal form={form} {...changeContainerProps}/>
    : <ContainerQRScanModal form={form} {...changeContainerProps}/>
  )

  return (<ContainerInfo
    onClick={containerChangeModal}
    position={position.position}
    key={position.id}
    isEnabled={position.enabled}
    container={container}
    ingredient={ingredient}
  />)
}

export default PositionChangeContainerController
