import React from 'react'
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom'
import { PageView } from '@myx/console-utils'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'

import SettingsMainPanel from './SettingsMainPanel'
import SettingsAccountsPanel from './SettingsAccountsPanel'
import { usePermissions } from '../permissions'
import IngredientSubscriptionPage from './IngredientSubscriptionPage'

// import SettingsRoutesPanel from './SettingsRoutesPanel'

function LinkTab(props) {
  return (
    <Tab
      component={Link}
      {...props}
    />
  )
}

function Settings({ match }) {
  // const [ value, setValue ] = React.useState(0)

  const tabMatch = useRouteMatch({
    path: `${match.path}/:subTab?`
  })

  const perms = usePermissions()

  if (!perms.userCanAccessSettings()) {
    return <PageView titleIsPending={false} url={match.url} title="Not Authorized">Not Authorized</PageView>
  }

  return (
    <PageView url={match.url} title="Operator Settings" titleIsPending={false}>
      <AppBar position="static">
        <Tabs
          value={tabMatch.params.subTab || 'settings'}
          centered
          variant="fullWidth"
        >
          <LinkTab label="Settings" value="settings" to={match.url}/>
          <LinkTab label="Accounts" value="accounts" to={`${match.url}/accounts`}/>
          <LinkTab label="Container Auto Order" value="container-reorder" to={`${match.path}/container-reorder`}/>
          {/* <LinkTab label="Routes" to={`${match.url}/routes`} /> */}
        </Tabs>
      </AppBar>
      <Box pt={3} pb={3}>
        <Switch>
          <Route exact path={`${match.path}`} component={SettingsMainPanel}/>
          <Route path={`${match.path}/accounts`} component={SettingsAccountsPanel}/>
          <Route path={`${match.path}/container-reorder`} component={IngredientSubscriptionPage}/>
          {/* <Route path={`${match.path}/routes`} component={SettingsRoutesPanel} /> */}
        </Switch>
      </Box>
    </PageView>
  )
}

export default Settings
