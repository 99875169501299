import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import AddressDisplay from '../format/AddressDisplay'

import { graphQLFetch, useAppModal } from '@myx/console-utils'

import KioskLocationDialog from '../dialogs/KioskLocationDialog'

function KioskLocationCard({ kiosk, editable, onComplete }) {
  const openEditDialog = useAppModal(
    {
      title: 'Edit Location',
      resultMessage: 'Location saved successfully',
      initialValues: {
        ...kiosk
        // email: user.email,
      },
      action: (formData) => graphQLFetch(
        `
          mutation ($kioskId: String!, $addressData: UpdateKioskAddressInput!) {
            updateKioskAddress(kioskId: $kioskId, update: $addressData) {
              id
            }
          }
        `,
        {
          kioskId: kiosk.id,
          addressData: {
            address1: formData.address1,
            address2: formData.address2,
            address3: formData.address3,
            city: formData.city,
            state: formData.state,
            country: formData.country,
            zip: formData.zip,
            accessInstructions: formData.accessInstructions,
          },
        }
      ),
      onComplete
    },
    <KioskLocationDialog/>
  )

  return (
    <Card>
      <CardHeader
        action={editable &&
          <IconButton
            aria-label="Edit Asset Location"
            onClick={openEditDialog}
          >
            <EditIcon/>
          </IconButton>
        }
        title="Location"
      />
      <CardContent>
        <Table size="medium">
          <colgroup>
            <col style={{ width: '30%' }}/>
            <col style={{ width: '70%' }}/>
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>
                <AddressDisplay
                  address={{
                    address1: kiosk.address1,
                    address2: kiosk.address2,
                    address3: kiosk.address3,
                    city: kiosk.city,
                    state: kiosk.state,
                    country: kiosk.country,
                    zip: kiosk.zip
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Access Instructions</TableCell>
              <TableCell>{kiosk.accessInstructions || '—'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )

}

export default KioskLocationCard
