import React from 'react'
import { DateTime } from 'luxon'

function TimezoneDisplay() {
  const local = DateTime.local()

  return (
    <span>
      {local.zoneName}
    </span>
  )
}

export default TimezoneDisplay
