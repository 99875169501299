import React from 'react'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { Box } from '@material-ui/core'

type Props = {
  isDark: boolean,
  handleThemeChange: () => void
}

const ThemeToggle = ({ isDark, handleThemeChange }: Props) => {

  return (
    <Box>
      Theme&nbsp;
      <ToggleButtonGroup
        value={isDark}
        exclusive
        onChange={handleThemeChange}>
        <ToggleButton value={false} aria-label="bold">Light</ToggleButton>
        <ToggleButton value={true} aria-label="italic">Dark</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default ThemeToggle
