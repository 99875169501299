import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import PhoneDisplay from '../format/PhoneDisplay'
import EmailDisplay from '../format/EmailDisplay'

import { graphQLFetch, useAppModal } from '@myx/console-utils'

import KioskContactDialog from '../dialogs/KioskContactDialog'

function KioskContactCard({ kiosk, editable, onComplete }) {
  const openEditDialog = useAppModal(
    {
      title: 'Edit Contact',
      resultMessage: 'Kiosk kioskUser saved successfully',
      initialValues: {
        ...kiosk
        // email: user.email,
      },
      action: (formData) => graphQLFetch(
        `
          mutation ($kioskId: String!, $contactData: UpdateKioskContactInput!) {
            updateKioskContact(kioskId: $kioskId, update: $contactData) {
              id
            }
          }
        `,
        {
          kioskId: kiosk.id,
          contactData: {
            contactGivenName: formData.contactGivenName,
            contactFamilyName: formData.contactFamilyName,
            contactPhoneCountry: formData.contactPhoneCountry,
            contactPhone: formData.contactPhone,
            contactPhoneExtension: formData.contactPhoneExtension,
            contactEmail: formData.contactEmail,
          }
        }
      ),
      onComplete
    },
    <KioskContactDialog/>
  )

  return (
    <Card>
      <CardHeader
        action={editable &&
          <IconButton
            aria-label="Edit Kiosk Contact"
            onClick={openEditDialog}
          >
            <EditIcon/>
          </IconButton>
        }
        title="Contact"
      />
      <CardContent>
        <Table size="medium">
          <colgroup>
            <col style={{ width: '30%' }}/>
            <col style={{ width: '70%' }}/>
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Contact</TableCell>
              <TableCell>
                {(kiosk.contactGivenName || kiosk.contactFamilyName)
                  ? `${kiosk.contactGivenName} ${kiosk.contactFamilyName}`
                  : '—'
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contact Phone</TableCell>
              <TableCell>
                <PhoneDisplay
                  country={kiosk.contactPhoneCountry}
                  phone={kiosk.contactPhone}
                  extension={kiosk.contactPhoneExtension}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contact Email</TableCell>
              <TableCell>
                <EmailDisplay email={kiosk.contactEmail}/>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )

}

export default KioskContactCard
