import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({

  countdown: {
    position: 'relative',
    margin: 'auto',
    height: '50px',
    width: '50px',
    textAlign: 'center',
  },

  countdownNumber: {
    color: theme.palette.secondary.main,
    lineHeight: '50px',
  },

  svg: {
    position: 'absolute',
    top: '-4px',
    right: '5px',
    width: '50px',
    height: '50px',
    transform: 'rotateY(-180deg) rotateZ(-90deg)',
  },
  svgCircle: {
    strokeDasharray: '113px',
    strokeDashoffset: '0px',
    strokeLinecap: 'round',
    strokeWidth: '4px',
    stroke: theme.palette.secondary.main,
    fill: 'none',
    animation: '$countdown 30s linear infinite forwards',
  },
  '@keyframes countdown': {
    from: {
      strokeDashoffset: '0px'
    },
    to: {
      strokeDashoffset: '113px'
    }
  }

}))

function OneTimePinCountDownWheel({
  updateTOTP,
  style,
  children }) {

  const theme = useTheme()
  const classes = useStyles(theme)

  function getCurrentKey() {
    return Math.floor(Date.now() / 30000)
  }

  const [ lastKey, setLastKey ] = React.useState(0)
  const [ timeRemaining, setTimeRemaining ] = React.useState(-1)
  const [ showCircle, setShowCircle ] = React.useState(false)

  React.useEffect(() => {
    const ticker = setInterval(() => {
      const currentSeconds = (new Date(Date.now())).getSeconds()
      const _remaining = (currentSeconds > 30) ? 60 - currentSeconds : 30 - currentSeconds
      setTimeRemaining(_remaining)
      if (timeRemaining !== -1 && _remaining > timeRemaining) {
        if (!showCircle) {
          setShowCircle(_remaining === 30)
        }
        setLastKey(getCurrentKey())
        updateTOTP()
      }
    }, 1000)

    return () => clearInterval(ticker)
  }, [ lastKey, showCircle, timeRemaining, updateTOTP ])

  return (
    <div className={classes.countdown}>
      <div className={classes.countdownNumber}>
        {timeRemaining}
        {showCircle && <svg className={classes.svg}><circle className={classes.svgCircle} r="18" cx="20" cy="20"/></svg>}
      </div>
    </div>
  )
}

export default OneTimePinCountDownWheel
