import React, { useRef } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'

import SettingsAccountDialog from '../dialogs/SettingsAccountDialog'

import { getTableFetchParams, graphQLFetch, useAppModal } from '@myx/console-utils'
import DataTable, { DataTableSortLabel } from '../DataTable' // also DataTableRowActions

const useStyles = makeStyles(theme => ({
  tableActions: {
    marginTop: theme.spacing(2),
  },
  rowActions: {
    '& button': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  }
}))

function AccountCreateButton({ onComplete }) {
  const openDialog = useAppModal(
    {
      title: 'Add Account',
      initialValues: {
        accountCode: '',
        name: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        state: '',
        zip: '',
        country: 'USA',
      },
      action: (formData) => graphQLFetch(
        `
          mutation ($data: AccountInput!) {
            addAccount(add: $data) {
              id
            }
          }
        `,
        {
          data: {
            accountCode: formData.accountCode,
            name: formData.name,
            address1: formData.address1,
            address2: formData.address2,
            address3: formData.address3,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            country: formData.country,
          }
        }
      ),
      onComplete
    },
    <SettingsAccountDialog/>
  )

  return (
    <Button color="primary" onClick={openDialog}>
      Add Account
    </Button>
  )
}

function AccountEditButton({ account, onComplete }) {
  const openDialog = useAppModal(
    {
      title: 'Edit Account',
      initialValues: {
        ...account
      },
      action: (formData) => graphQLFetch(
        `
          mutation ($accountId: String!, $data: AccountInput!) {
            updateAccount(accountId: $accountId, update: $data) {
              id
            }
          }
        `,
        {
          accountId: account.id,
          data: {
            accountCode: formData.accountCode,
            name: formData.name,
            address1: formData.address1,
            address2: formData.address2,
            address3: formData.address3,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            country: formData.country,
          }
        }
      ),
      onComplete
    },
    <SettingsAccountDialog/>
  )

  return (
    <IconButton color="primary" size="small" onClick={openDialog}>
      <EditIcon/>
    </IconButton>
  )
}

function AccountDeleteButton({ account, onComplete }) {
  const openDialog = useAppModal(
    {
      title: 'Delete Account',
      action: (formData) => graphQLFetch(
        `
          mutation ($accountId: String!) {
            removeAccount(accountId: $accountId) {
              id
            }
          }
        `,
        {
          accountId: account.id,
        }
      ),
      onComplete,
      actionLabel: 'Delete',
      resultMessage: 'Deleted successfully',
    },
    <Typography variant="body1">Really delete account?</Typography>
  )

  return (
    <IconButton color="primary" size="small" onClick={openDialog}>
      <CloseIcon/>
    </IconButton>
  )
}

function SettingsAccountsTable(props) {
  const classes = useStyles()

  const tableRef = useRef()

  return (
    <>
      <DataTable
        ref={tableRef}
        tableState={props.tableState}
        dataSource={() =>
          graphQLFetch(`
            query($map: [MapEntryInput!]!, $limit: Long!, $offset: Long!) {
              getAccounts(
                map: $map,
                limit: $limit,
                offset: $offset
              ) {
                total
                accounts {
                  id
                  companyId
                  accountCode
                  name
                  totalKiosks
                  changedBy
                  changedOn
                  address1
                  address2
                  address3
                  city
                  state
                  zip
                  country
                }
              }
            }
          `, getTableFetchParams(
            props.tableState,
            {
              accountCode: [ 'string', 'accountCode' ],
              name: [ 'string', 'name' ],
            }
          )).then(({ getAccounts: { total, accounts: data } }) =>
            ({ total, data })
          )
        }
      >
        {(data, subMatch, refresh) => <>
          <TableHead>
            <TableRow>
              <TableCell>
                <DataTableSortLabel column="accountCode">Account Code</DataTableSortLabel>
              </TableCell>
              <TableCell>
                <DataTableSortLabel column="name">Account Name</DataTableSortLabel>
              </TableCell>
              <TableCell align="center">
                <DataTableSortLabel column="totalKiosks">Kiosks</DataTableSortLabel>
              </TableCell>
              <TableCell align="center">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(account => (
              <TableRow key={account.id}>
                <TableCell>{account.accountCode}</TableCell>
                <TableCell>{account.name}</TableCell>
                <TableCell align="center">{account.totalKiosks}</TableCell>
                <TableCell className={classes.rowActions} align="right">
                  <AccountEditButton account={account} onComplete={refresh}/>
                  <AccountDeleteButton account={account} onComplete={refresh}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </>}
      </DataTable>
      <div className={classes.tableActions}>
        <AccountCreateButton onComplete={() => tableRef.current.refresh()}/>
      </div>
    </>
  )
}

export default SettingsAccountsTable
