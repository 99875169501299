import React, { useRef } from 'react'
import { SnackbarProvider, useSnackbar } from 'notistack'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

// global snack state that persists across content route changes/unmounts/etc
export const AppSnackProvider = ({ children }) => {
  const notistackRef = useRef()

  return (
    <SnackbarProvider
      maxSnack={8}
      ref={notistackRef}
      action={messageKey =>
        <IconButton
          aria-label="Close"
          color="inherit"
          onClick={() => {
            notistackRef.current.closeSnackbar(messageKey)
          }}
        >
          <CloseIcon />
        </IconButton>
      }
    >
      {children}
    </SnackbarProvider>
  )
}

export function useAppSnack() {
  // expose the Notistack trigger directly
  const { enqueueSnackbar } = useSnackbar()

  return enqueueSnackbar
}
