import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import { graphQLFetch, useAppModal } from '@myx/console-utils'
import MyAccountPasswordDialog from '../dialogs/MyAccountPasswordDialog'

function MyAccountPasswordCard({ user, onComplete }) {

  const openEditDialog = useAppModal(
    {
      title: 'Edit Password',
      resultMessage: 'Password saved successfully',
      initialValues: {
        ...user
      },
      action: (formData) => graphQLFetch(
        `
          mutation ($data: UpdateUserPasswordInput!) {
            updateMyPassword(update: $data) {
              id
            }
          }
        `,
        {
          userId: user.id,
          data: {
            oldPassword: formData.oldPassword,
            newPassword: formData.newPassword
          }
        }
      ),
      onComplete
    },
    <MyAccountPasswordDialog/>
  )

  return (
    <Card>
      <CardHeader
        action={
          <IconButton
            aria-label="edit password"
            onClick={openEditDialog}
          >
            <EditIcon/>
          </IconButton>
        }
        title="Password"
      />
      <CardContent>
        <Table size="medium">
          <colgroup>
            <col style={{ width: '40%' }}/>
            <col style={{ width: '60%' }}/>
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Password</TableCell>
              <TableCell>******</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default MyAccountPasswordCard
