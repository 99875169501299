import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import AppFormTextField from '../form/AppFormTextField'
import AppFormPhoneCountryField from '../form/AppFormPhoneCountryField'
import AppFormCheckboxFieldGroup from '../form/AppFormCheckboxFieldGroup'
import AppFormCheckboxListField from '../form/AppFormCheckboxListField'
import Button from '@material-ui/core/Button'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { useMediaQuery, useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  stepperRoot: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
}))

function UserInfoForm() {
  const theme = useTheme()

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Box mb={3}>
      <Box display="flex" justifyContent="space-between">
        <Box mr={1} flex={1}>
          <AppFormTextField
            name="givenName"
            label="First Name"
            autoFocus
          />
        </Box>
        <Box ml={1} flex={1}>
          <AppFormTextField
            name="familyName"
            label="Last Name"
          />
        </Box>
      </Box>
      <AppFormTextField
        name="email"
        type="email"
        required
        label="Email Address"
      />
      {isSmallDevice
        ? <>
          <Box display="flex">
            <Box mr={1} flex={1} style={{ maxWidth: '30%' }}>
              <AppFormPhoneCountryField
                name="phoneCountry"
                label="Country Code"
              />
            </Box>
            <Box mr={1} ml={1} flex={1}>
              <AppFormTextField
                name="phoneExtension"
                label="Extension"
                validateWholeNumber
              />
            </Box>
          </Box>
          <Box display="flex">
            <Box ml={1} flex={1}>
              <AppFormTextField
                name="phone"
                label="Phone Number"
                type="text" // @TODO: react-text-mask - https://next.material-ui.com/components/text-fields/
              />
            </Box>
          </Box>
        </>
        : <Box display="flex">
          <Box mr={1} flex={1} style={{ maxWidth: '30%' }}>
            <AppFormPhoneCountryField
              name="phoneCountry"
              label="Country Code"
            />
          </Box>
          <Box mr={1} ml={1} flex={1}>
            <AppFormTextField
              name="phone"
              label="Phone Number"
              type="text" // @TODO: react-text-mask - https://next.material-ui.com/components/text-fields/
            />
          </Box>
          <Box ml={1} flex={1} style={{ maxWidth: '25%' }}>
            <AppFormTextField
              name="phoneExtension"
              label="Extension"
              validateWholeNumber
            />
          </Box>
        </Box>}
    </Box>
  )
}

function UserAlertsForm() {
  return (
    <Box mt={2}>
      <AppFormCheckboxFieldGroup
        label="Alert Notifications"
        options={{
          serviceAlert: 'Service Alerts',
          inventoryAlert: 'Inventory Alerts',
        }}
      />
      <AppFormCheckboxFieldGroup
        label="Alert Notification Preferences"
        options={{
          alertWithEmail: 'Email',
        }}
      />
    </Box>
  )
}

function UserRolesForm() {
  return (
    <Box mt={2}>
      <AppFormCheckboxListField
        name="roles"
        label="User Roles"
        options={{
          fulfillment: 'Fulfillment (Access Kiosks to Change Containers)',
          servicetech: 'Service Tech (Access Kiosks to Service Them)',
          operator: 'Operator (Manage Users, View Reports, Edit Settings)',
        }}
      />
    </Box>
  )
}

function UserAddFormDialog() {
  const classes = useStyles()

  const [ activeStep, setActiveStep ] = useState(0)
  const steps = [ 'User Info', 'Notifications', 'Roles' ]

  const form = useFormikContext()

  const handleNext = () => {
    // touch each field so validation errors are shown for all fields
    Object.keys((form.values) as any).forEach(element => form.setFieldTouched(element, true))

    // validate form and only proceed if there are no errors
    form.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
        form.setFieldValue('stepsComplete', (steps.length - 1) === activeStep + 1)
      }
    })
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)

    // prevent 'save' action on dialog if user goes back a step
    form.setFieldValue('stepsComplete', false)
  }

  return (
    <React.Fragment>
      <Stepper
        activeStep={activeStep}
        classes={{
          root: classes.stepperRoot,
        }}
      >
        <Step>
          <StepLabel>{/* completed={completed[index]} */}
            {steps[0]}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            {steps[1]}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            {steps[2]}
          </StepLabel>
        </Step>
      </Stepper>
      <Box px={2}>
        {activeStep === 0 &&
          <UserInfoForm/>
        }
        {activeStep === 1 &&
          <UserAlertsForm/>
        }
        {activeStep === 2 &&
          <UserRolesForm/>
        }
        <Box display="flex" justifyContent="flex-end" mt={1}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            color="primary"
          >
            Back
          </Button>
          {(activeStep !== steps.length - 1) &&
            <Box ml={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleNext}
              >
                Next
              </Button>
            </Box>
          }
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default UserAddFormDialog
