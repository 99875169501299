import React from 'react'

import Box from '@material-ui/core/Box'
// import { makeStyles } from '@material-ui/core/styles'
import AppFormCheckboxListField from '../form/AppFormCheckboxListField'

function UserRolesFormDialog() {
  // const classes = useStyles()

  return (
    <>
      <Box>
        <AppFormCheckboxListField
          label="Roles"
          name="roles"
          options={{
            fulfillment: 'Fulfillment (Access Kiosks to Change Containers)',
            servicetech: 'Service Tech (Access Kiosks to Service Them)',
            operator: 'Operator (Manage Users, View Reports, Edit Settings)',
          }}
        />
      </Box>
    </>
  )
}

export default UserRolesFormDialog
