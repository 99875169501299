import React from 'react'

function AddressDisplay({ address }) {
  return (
    <span>
      {address.address3 || ''}
      {address.address3 && <br />}
      {address.address1 || ''}
      {address.address1 && <br />}
      {address.address2 || ''}
      {address.address2 && <br />}
      {address.city ? address.city + ', ' : ''}
      {address.state ? address.state + ', ' : ''}
      {address.country || ''}
      <br />
      {address.zip || ''}
    </span>
  )
}

export default AddressDisplay
