import React from 'react'
import { useAsync } from 'react-async-hook'
import { makeStyles } from '@material-ui/core/styles'
import { graphQLFetch, KioskPositionsPanelView } from '@myx/console-utils'
import ProgressWrapper from '../components/ProgressWrapper'
import { usePermissions } from '../permissions'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(4)}px`,
    overflow: 'auto',
  },
}))

const ContainerLink = React.forwardRef((props, ref) => {
  const classes = useStyles()
  const { container, ...otherProps } = props

  return (container && container.ingredientContainerDetail
    ? <RouterLink
      className={classes.link}
      innerRef={ref}
      to={`/containers/${encodeURIComponent(container.ingredientContainerDetail.id)}`}
      {...otherProps}
    /> : null
  )
})

function KioskPositionsPanelController(props) {
  const perms = usePermissions()

  const positionDataAsync = useAsync(() => {
    return graphQLFetch(`
      query($id: String!) {

        getKioskWaterFilter(kioskId: $id) {
            id
            enabled
            changedOn
            changedBy
            installedOn
            expireOn
        }

        getKioskCo2(kioskId: $id) {
            id
            enabled
            changedOn
            changedBy
        }
        getKioskCo2LatestReading(kioskId: $id) {
            id
            psi
            time
        }
        getKioskPositions(
          map: [ { key: "orderBy", value: "position" } ],
          limit: 1000,
          offset: 0,
          kioskId: $id
        ) {
          total
          kioskPositions{
            id
            kioskId
            position
            enabled
            ingredientContainerDetail {
              id
              soonestExpireOn
              manufactureLotCode
              companyId
              mfgSerialNumber
              remainingPercent
              ingredientDetail {
                name
              }
            }
          }
        }

        getKioskPositionDailyUse(map: [ { key: "kioskId", value: $id } ]) {
          kioskPositionId
          kioskId
          dailyUsePercent
          remainingDays
        }
      }
    `, {
      id: props.kioskId,
    }).then(({
      getKioskCo2,
      getKioskCo2LatestReading,
      getKioskWaterFilter,
      getKioskPositionDailyUse,
      getKioskPositions: { kioskPositions: positionData }
    }) => (
      {
        co2Detail: getKioskCo2,
        waterFilterDetail: getKioskWaterFilter, // adds co2Detail and waterFilterDetail
        positionUse: getKioskPositionDailyUse,
        getKioskCo2LatestReading: getKioskCo2LatestReading,
        positionData
      }
    ))
  }, [], {
    // preserve previously loaded data while new data is being fetched
    setLoading: state => ({ ...state, loading: true })
  })

  const positionData = positionDataAsync.result

  return (
    <ProgressWrapper pending={positionDataAsync.loading || !positionDataAsync.result}>
      {positionData && <KioskPositionsPanelView
        positionData={positionData}
        kioskId={props.kioskId}
        perms={perms}
        containerLink={(container) => <ContainerLink
          container={container}>{container.ingredientContainerDetail.mfgSerialNumber}</ContainerLink>}
        updateActions={{
          updatePositionsEnabled: (kioskPositionId, updatedValue) => graphQLFetch(
            `
          mutation ($kioskId: String!, $kioskPositionId: String!, $enabled: Boolean!) {
            updateKioskPositionEnabled(kioskId: $kioskId, kioskPositionId: $kioskPositionId, update: {
              enabled: $enabled
            }) {
              enabled
            }
          }
        `,
            {
              kioskId: props.kioskId,
              kioskPositionId: kioskPositionId,
              enabled: updatedValue
            }
          ),
          updateCo2Enabled: (updatedValue) => graphQLFetch(
            `
          mutation ($kioskId: String!, $enabled: Boolean!) {
            updateKioskCo2Enabled(kioskId: $kioskId, update: {
              enabled: $enabled
            }) {
              enabled
            }
          }
        `,
            {
              kioskId: props.kioskId,
              enabled: updatedValue
            }
          ),
          updateKioskWaterFilterEnabled: (updatedValue) => graphQLFetch(
            `
          mutation ($kioskId: String!, $enabled: Boolean!) {
            updateKioskWaterFilterEnabled(kioskId: $kioskId, update: {
              enabled: $enabled
            }) {
              enabled
            }
          }
        `,
            {
              kioskId: props.kioskId,
              enabled: updatedValue
            }
          ),
        }}
      />}
    </ProgressWrapper>
  )
}

export default KioskPositionsPanelController
