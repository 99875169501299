import { TOUCHLESS_URL } from '@myx/console-utils'
import { useEffect, useRef } from 'react'

export async function kioskApiFetch(path, options, raw = false) {
  // @todo more friendly error messages
  const response = await fetch(`${TOUCHLESS_URL}${path}`, {
    ...(options || {}),
  })

  const responseJSON = await response.json()

  if (!responseJSON) {
    throw createErrorObject('Expected a response data object', 901)
  }

  if (!response.ok || !responseJSON.success) {
    throw createErrorForResponse(responseJSON)
  }

  if (raw) {
    return responseJSON
  }
  return responseJSON.data
}

export function createErrorObject(message, errorCode) {
  // throw elsewhere for proper stack trace
  const errorObject = new Error(message || 'Unexpected error')
  errorObject.kioskErrorCode = errorCode

  return errorObject
}

function createErrorForResponse(responseJSON) {
  const [ firstError ] = responseJSON.errors || []
  const { message: firstErrorMessage, code: firstErrorCode } = firstError || {}

  return createErrorObject(
    firstErrorMessage || 'Request unsuccessful',
    firstErrorCode
  )
}

// eslint-disable-next-line max-params
export function catchKioskConnectError(ex, history, redirectTo = '/dashboard', doThrow = true) {
  if (ex.kioskErrorCode === 'KIOSK_CONNECTION_FAILED' || ex.kioskErrorCode === 'KIOSK_TIMEOUT') {
    history.push(redirectTo)
  }
  if (doThrow) {
    throw ex
  }
}

export function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [ callback ])

  // Set up the interval.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [ delay ])
}
