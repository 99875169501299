import React from 'react'
import { DateTime } from 'luxon'

function DateDisplay({ dt, displayTime, format24Hours }) {
  if (!dt) {
    return <span>—</span>
  }

  // graphql response includes offset (+0000)
  const date = DateTime.fromISO(dt)

  return (
    <span>
      { date.toISODate() }
      { displayTime && (format24Hours
        ? ` ${date.toFormat('hh:mm:ss')}`
        : ` ${date.toFormat('tt')}`
      )}
    </span>
  )
}

export default DateDisplay
