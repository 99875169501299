import React from 'react'
import { useFormikContext, useField } from 'formik'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

function getFilterTypeLabel(filterTypeOptions) {
  if (typeof filterTypeOptions === 'object') {
    return filterTypeOptions.label
  }

  return filterTypeOptions.toString()
}

export default function FilterEntryTypeInput({ name, filterOptions, filterUsageMap }) {
  const form = useFormikContext()
  const [ field ] = useField(`${name}.type`)

  return (
    <FormControl fullWidth>
      <InputLabel>Filter</InputLabel>
      <Select
        displayEmpty
        {...field}

        onChange={(event) => {
          form.setFieldValue(`${name}.type`, event.target.value)
          form.setFieldValue(`${name}.value`, '') // force-clear the value field
        }}
      >
        {Object.keys(filterOptions).map(type =>
          // disable the option if already used in other entries,
          // unless it is the current one
          <MenuItem
            value={type}
            disabled={type !== field.value && filterUsageMap[type]}
            key={type}
          >
            {getFilterTypeLabel(filterOptions[type])}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}
