import React from 'react'
import Box from '@material-ui/core/Box'
import { Button, Typography } from '@material-ui/core'

function ErrorView({ title, message, onConfirm }) {
  return <>
    <Box textAlign="center">
      {title && <Typography variant="h3" style={{ color: 'red' }}>
        {title}
      </Typography>}

      {message && <Typography variant="h4" style={{ color: 'red' }}>
        {message}
      </Typography>}
      <Button textSizeLarge variant="contained" color="primary" align="center" onClick={onConfirm}>Ok</Button>
    </Box>
  </>
}

export default ErrorView
