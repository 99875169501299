import React from 'react'
import { Typography } from '@material-ui/core'
import ContainerSerialNumberAutoComplete from '../form/ContainerSerialNumberAutoComplete'
import ContainerInfo from '../ContainerInfo'

function ContainerKeyboardModal({ form, position, defaultContainer = null, containerAsyncFetch = null }) {

  const [ selectedContainer, setSelectedContainer ] = React.useState(defaultContainer)

  const updateSelected = (container) => {
    const setSerialNumber = container && container.mfgSerialNumber ? container.mfgSerialNumber : null

    form.setFieldValue('serialNumber', setSerialNumber)
    setSelectedContainer(container)
  }
  return (<>
    <Typography color="primary" variant="h5">Enter the serial number of the new container</Typography>
    <ContainerSerialNumberAutoComplete
      containerAsyncFetch={containerAsyncFetch}
      onChanged={(__, newVal) => updateSelected(newVal)}
      additionalFilters={[
        { key: 'nu:kioskPositionId', value: '0' }
      ]}
    />
    {
      selectedContainer && selectedContainer.ingredientDetail &&
      <>
        <Typography>Replace with</Typography>
        <ContainerInfo
          isEnabled={position.isEnabled}
          container={selectedContainer}
          ingredient={selectedContainer.ingredientDetail}
        />
      </>
    }
  </>)
}

export default ContainerKeyboardModal
