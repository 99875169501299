import React from 'react'
import { useAsync } from 'react-async-hook'
import { graphQLFetch } from '../../backend'
import { PageView } from '../../context/PageContext'
import NewDashboardView from './NewDashboardView'
import { useHistory } from 'react-router-dom'

type Props = {
  match: any,
  userCanOnlyScanContainers: boolean,
  userCanAccessUsers: boolean,
  imageMap: { [index: number]: any },
  additionalParams?: Array<{ key: string, value: string }>,
}

const createFaultLookup = (faults: Array<any>): Map<string, Array<any>> => {
  const returnMap = new Map()
  faults.forEach((fault) => {
    const faultList = returnMap.get(fault.kioskId) || []
    faultList.push({
      ...fault,
      faultCode: [ fault.faultCode ]
    })
    returnMap.set(fault.kioskId, faultList)
  })
  return returnMap
}

const flattenByPosition = (faults: Array<any>): Array<any> => {
  const mappedByPos = new Map<number, any>()
  faults.forEach((fault) => {
    const position = fault.ingredientContainerDetail?.kioskPositionDetail?.position
    if (position) {
      const faultByPos = mappedByPos.get(position)
      if (faultByPos) {
        mappedByPos.set(position, {
          ...faultByPos,
          faultCode: [ ...faultByPos.faultCode, ...fault.faultCode ],
        })
      } else {
        mappedByPos.set(position, fault)
      }
    }
  })
  return Array.from(mappedByPos.values())
}

export const transformKioskData = (kiosks: Array<any>, containerFaults: Array<any>, faults: Array<any>): Array<any> => {
  const containerFaultLookup = createFaultLookup(containerFaults)
  const faultLookup = createFaultLookup(faults)
  const results = kiosks.map((kiosk) => {
    return {
      ...kiosk,
      inventoryAlerts:
        flattenByPosition(containerFaultLookup.get(kiosk.id) || []),
      serviceAlerts:
        faultLookup.get(kiosk.id) || []
    }
  })
  return results
}

function NewDashboardController(props: Props) {
  const history = useHistory()

  if (props.userCanOnlyScanContainers) {
    history.push('/scancontainer')
  }

  const dashboardDataAsync = useAsync(() => {
    return graphQLFetch(`
      query {

        getKiosks(map: [], limit: 1, offset: 0) {
          total
        }

        getIngredientContainers(map: [], limit: 1, offset: 0) {
          total
        }

        getTotalUsers(map: [])
}
    `, {}).then(({
      getKiosks: { total: totalKiosks },
      getIngredientContainers: { total: totalContainers },
      getTotalUsers
    }) => (

      {
        totalKiosks,
        totalContainers,
        totalUsers: getTotalUsers
      }
    ))
  }, [])

  const defaultParams: Array<{ key: string, value: string }> = [ { key: 'gt:totalAlertCount', value: '0' },
    { key: 'orderByDesc', value: 'totalAlertCount' }
  ]
  defaultParams.push(...(props.additionalParams || []))

  const kioskDataAsync = useAsync(() => {
    return graphQLFetch(`
      query($map: [MapEntryInput!]!) {
        getKiosks(map: $map, limit: 1000000, offset: 0) {
          total
          kiosks {
           id
           serialNumber
           assetCode
           usernameUUID
           serviceAlertCount
           inventoryAlertCount
           companyDetail {
              id
              name
           }
           onlineDetail {
            online
            changedOn
           }
          }
        }
        getAllKioskFaultIncidents(map: [], limit: 1000000, offset: 0) {
          total
          kioskFaultIncidents {
           id
           kioskId
           faultCode
          }
        }
        getAllKioskContainerFaultIncidents(map: [], limit: 1000000, offset: 0) {
          total
          kioskContainerFaultIncidents {
           id
           faultCode
           kioskId
           ingredientContainerDetail {
             mfgSerialNumber
             kioskPositionDetail {
               position
             }
             ingredientDetail {
               id
               name
             }
           }
         }
      }
}`, {
      map: defaultParams
    })
  }, [])

  const dashboardData = dashboardDataAsync.result
  const kioskTableData = kioskDataAsync.result ? transformKioskData(
    kioskDataAsync.result.getKiosks?.kiosks || [],
    kioskDataAsync.result.getAllKioskContainerFaultIncidents?.kioskContainerFaultIncidents,
    kioskDataAsync.result.getAllKioskFaultIncidents?.kioskFaultIncidents
  ) : undefined

  return (
    <PageView url={props.match.url} title="Dashboard">
      <NewDashboardView
        totalKiosks={dashboardData?.totalKiosks}
        totalContainers={dashboardData?.totalContainers}
        totalUsers={dashboardData?.totalUsers}
        userCanAccessUsers={props.userCanAccessUsers}
        kioskInventoryAlerts={kioskTableData}
        imageMap={props.imageMap}
      />
    </PageView>
  )
}

export default NewDashboardController
