import React from 'react'
import LuxonUtils from '@date-io/luxon'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import Grid from '@material-ui/core/Grid'

// import MockPieChart from '../charts/MockPieChart'
import { PageView } from '@myx/console-utils'
import { usePermissions } from '../permissions'
import ReportsPourCard from '../components/cards/ReportsPourCard'
import ReportsAlertsCard from '../components/cards/ReportsAlertsCard'

function Reports({ match }) {
  const perms = usePermissions()

  if (!perms.userCanAccessReports()) {
    return <PageView url={match.url} title="Not Authorized">Not Authorized</PageView>
  }

  return (
    <PageView url={match.url} title="Reports">
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ReportsPourCard/>
          </Grid>

          {/*
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h6" component="h2" color="primary">
                Ingredient Report
                </Typography>
                <Typography>
                Ingredient Box details and usage by Date Range (inclusive)
                </Typography>
                <Grid container justify="space-around">
                  <KeyboardDatePicker label="Date From" format="yyyy-MM-dd" margin="normal" value={selectedDate} onChange={handleDateChange} />
                  <KeyboardDatePicker label="Date To" format="yyyy-MM-dd" margin="normal" value={selectedDate} onChange={handleDateChange} />
                </Grid>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button className={classes.actionButton} size="small" color="primary" variant="outlined">
                  <FileDownloadIcon />
                CSV
                </Button>
                <Button className={classes.actionButton} size="small" color="primary" variant="outlined">
                  <FileDownloadIcon />
                XLSX
                </Button>
              </CardActions>
            </Card>
          </Grid>
          */}

          <Grid item xs={12} md={6}>
            <ReportsAlertsCard/>
          </Grid>

          {/*
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h6" component="h2" color="primary">
                User Activity Report
                </Typography>
                <Typography>
                User Activity by Date Range (inclusive)
                </Typography>
                <Grid container justify="space-around">
                  <KeyboardDatePicker label="Date From" format="yyyy-MM-dd" margin="normal" value={selectedDate} onChange={handleDateChange} />
                  <KeyboardDatePicker label="Date To" format="yyyy-MM-dd" margin="normal" value={selectedDate} onChange={handleDateChange} />
                </Grid>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button className={classes.actionButton} size="small" color="primary" variant="outlined">
                  <FileDownloadIcon />
                CSV
                </Button>
                <Button className={classes.actionButton} size="small" color="primary" variant="outlined">
                  <FileDownloadIcon />
                XLSX
                </Button>
              </CardActions>
            </Card>
          </Grid>
          */}

        </Grid>
      </MuiPickersUtilsProvider>
    </PageView>
  )
}

export default Reports
