/* eslint-disable no-console */
import qs from 'querystring'
import { DateTime } from 'luxon'
import * as events from 'events'

// number of allowed beverage sizes for a given kiosk
export const MAX_ALLOWED_SIZES = 4

// @ts-ignore
const env = window._env_ ? window._env_ : {}
export const BASE_URL = env.BASE_URL
export const AUTH_BASE = env.AUTH_URL
const VIRTUAL_URL = env.VIRTUAL_URL
export const USER_RESOURCE_URL = `https://${BASE_URL}/user-resource/graphql`
export const AUTH_URL = `https://${AUTH_BASE}/consumer-auth/oauth/token`
export const TOUCHLESS_URL = `https://${BASE_URL}/touchless-resource`

export const MENDER_URL = env.MENDER_URL || 'https://updates.mydrinksmart.com'

const CLIENT_BASIC_AUTH = '64eefcac-a01c-4619-9892-8bcf98ddfcb4:jz5DtC1IZZRlhIw0tbe2JWxU9meKEpCZrHuvNlX9IQ1ihdarQL'
export const VIRTUAL_SERVICE_URL = `https://${VIRTUAL_URL}/dev`
const REPORT_RESOURCE_URL = `https://${BASE_URL}/user-resource/report`

export const CONSUMER_URL = env.CONSUMER_URL || 'https://myxdrinks.com'

// localStorage item keys
const STORAGE_ACCESS_TOKEN = 'moc_ac'
const STORAGE_REFRESH_TOKEN = 'moc_re'

export const backendErrors = new events.EventEmitter()

export function reportUnauthorizedAccess() {
  backendErrors.emit('unauthorizedAccessError')
}

export function signIn(email: string, password: string, additionalHeaders = {}) {
  return fetch(AUTH_URL, {
    method: 'post',
    headers: {
      Authorization: `Basic ${btoa(CLIENT_BASIC_AUTH)}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      ...additionalHeaders,
    },
    body: [
      'grant_type=password',
      `username=${encodeURIComponent(email)}`,
      `password=${encodeURIComponent(password)}`
    ].join('&')
  }).then(response => {
    if (response.ok) {
      return response.json()
    }

    if (response.status === 400) {
      throw new Error('Incorrect email or password')
    }

    throw new Error('Error during authentication')
  }).then(responseJSON => {
    // update current session state to store the new tokens
    const accessToken = responseJSON.access_token
    const refreshToken = responseJSON.refresh_token
    storeTokens(accessToken, refreshToken)
  })
}

export function storeTokens(accessToken: string, refreshToken: string) {
  localStorage.setItem(STORAGE_ACCESS_TOKEN, accessToken)
  localStorage.setItem(STORAGE_REFRESH_TOKEN, refreshToken)
}

// Checks if the access token is within 10 minutes of expiring.
function isAccessTokenExpired(accessToken: string) {
  return ((DateTime.utc().toMillis() / 1000) > (JSON.parse(atob(accessToken.split('.')[1])).exp - 10))
}

function accessTokenFetch() {
  return fetch(AUTH_URL, {
    method: 'post',
    headers: {
      Authorization: `Basic ${btoa(CLIENT_BASIC_AUTH)}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: [
      'grant_type=refresh_token',
      `refresh_token=${localStorage.getItem(STORAGE_REFRESH_TOKEN)}`
    ].join('&')
  }).then(response => {
    if (response.ok) {
      return response.json()
    }
    backendErrors.emit('authError')
    throw new Error('Please sign in')
  }).then((responseJSON) => {

    // update current session state to store the new tokens
    const accessToken = responseJSON.access_token
    const refreshToken = responseJSON.refresh_token

    localStorage.setItem(STORAGE_ACCESS_TOKEN, accessToken)
    localStorage.setItem(STORAGE_REFRESH_TOKEN, refreshToken)
  })
}

export function signOut() {
  localStorage.removeItem(STORAGE_ACCESS_TOKEN)
  localStorage.removeItem(STORAGE_REFRESH_TOKEN)
}

/**
 * @param {import('./components/PaginatedTable').PaginatedTableState} tableState
 */
export function getTableFetchParams(tableState: any, fieldMap: { [key: string]: any } = {}): { limit: number, offset: number, map: { [key: string]: any } } {
  // get full info about sort column or fall back
  const [ , sortGQLFieldName ] = fieldMap[tableState.sortColumn] || [ null, tableState.sortColumn ]

  const sortSpec = {
    key: tableState.sortReverse ? 'orderByDesc' : 'orderBy',
    value: sortGQLFieldName
  }

  const specList = Object.keys(fieldMap).reduce((list: any, filterKey: string) => {
    const filterValue = tableState.filter[filterKey]

    // continue if not present
    if (filterValue === null || filterValue === undefined) {
      return list
    }

    // switch based on field type
    const [ fieldType, gqlFieldName ] = fieldMap[filterKey]

    switch (fieldType) {
    case 'string':
      return [ ...list, {
        key: gqlFieldName === 'includeAll' ? gqlFieldName : `CO:${gqlFieldName}`,
        value: filterValue
      } ]
    case 'id':
      return [ ...list, { key: `EQ:${gqlFieldName}`, value: filterValue } ]
    case 'dateRange': {
      const [ dateA, dateB ] = filterValue.split(';')
      const dtA = dateA && DateTime.fromISO(dateA)
      const dtB = dateB && DateTime.fromISO(dateB).plus({ days: 1 })

      // format timestamp to be Java-friendly (no colon in tz offset)
      const dateConditions: ConcatArray<never>[] = [].concat(
        dtA ? [ { key: `AF:${gqlFieldName}`, value: dtA.toFormat('yyyy-MM-dd\'T\'HH:mm:ss.SSSZZZ') } as never ] : []
      ).concat(
        dtB ? [ { key: `BF:${gqlFieldName}`, value: dtB.toFormat('yyyy-MM-dd\'T\'HH:mm:ss.SSSZZZ') } as never ] : []
      )

      return [ ...list, ...dateConditions ]
    }
    case 'dateTimeRange': {
      const [ dateA, dateB ] = filterValue.split(';')
      const dtA = dateA && DateTime.fromISO(dateA)
      const dtB = dateB && DateTime.fromISO(dateB)

      // format timestamp to be Java-friendly (no colon in tz offset)
      const dateConditions: ConcatArray<{ key: string; value: string; }>[] = [].concat(
        dtA ? [ {
          key: `AF:${gqlFieldName}`,
          value: encodeURI(dtA.toFormat('yyyy-MM-dd\'T\'HH:mm:ss.SSSZZZ'))
        } as never ] : []
      ).concat(
        dtB ? [ {
          key: `BF:${gqlFieldName}`,
          value: encodeURI(dtB.toFormat('yyyy-MM-dd\'T\'HH:mm:ss.SSSZZZ'))
        } as never ] : []
      )

      return [ ...list, ...dateConditions ]
    }
    default:
      throw new Error(`unknown filter field type: ${fieldType}`)
    }
  }, [ sortSpec ])

  return {
    limit: tableState.pageSize,
    offset: tableState.pageIndex * tableState.pageSize,
    map: specList
  }
}

export function graphQLFetch(query: any, params: any) {
  const accessToken = localStorage.getItem(STORAGE_ACCESS_TOKEN)

  const querySuffix = params ? `?${qs.stringify({ variables: JSON.stringify(params) })}` : ''

  // if there is no access token at all.. throw auth error and redirect
  if (!accessToken) {
    backendErrors.emit('authError')
    throw new Error('Not signed in')
  }

  const graphQlFetchF = () => fetch(`${USER_RESOURCE_URL}${querySuffix}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/graphql',
      Authorization: `Bearer ${localStorage.getItem(STORAGE_ACCESS_TOKEN)}`
    },
    body: query
  }).then(response => {
    if (response.status === 401) {
      backendErrors.emit('authError')
      throw new Error('Not signed in')
    }

    if (!response.ok) {
      backendErrors.emit('networkError')
      throw new Error('Unexpected error')
    }

    return response.json().then(gqlResponse => {
      if (gqlResponse.errors) {
        const firstError = gqlResponse.errors[0]
        throw new Error(firstError.message || firstError.toString())
      }

      return gqlResponse.data
    })
  })

  return isAccessTokenExpired(accessToken)
    ? accessTokenFetch().then(() => graphQlFetchF())
    : graphQlFetchF()

}

export function getAuthorizationHeader() {
  const accessToken = localStorage.getItem(STORAGE_ACCESS_TOKEN)
  if (!accessToken || isAccessTokenExpired(accessToken)) {
    backendErrors.emit('authError')
    throw new Error('Not signed in')
  }
  return `Bearer ${accessToken}`
}

export function getReportURL(token: string) {
  return `${REPORT_RESOURCE_URL}?token=${encodeURIComponent(token)}&myx-timezone=${encodeURIComponent(DateTime.local().zoneName)}`
}

export function getAggregateReportURL(token: string, reports: Array<string>) {
  return `${REPORT_RESOURCE_URL}/aggregate?token=${encodeURIComponent(token)}&myx-timezone=${encodeURIComponent(DateTime.local().zoneName)}&reports=${reports.join(',')}`
}

function parseJwt(token: string): any {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (ccc) {
    return '%' + ('00' + ccc.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export function getTokenDetails(): any {
  const token = localStorage.getItem(STORAGE_ACCESS_TOKEN)
  return token ? parseJwt(token) : {}
}
