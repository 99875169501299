import React from 'react'
import IngredientInventoryTable from './tables/IngredientInventoryTable'
import Panel from './Panel'

type Props = {
  dataTableFetch?: Promise<any>,
  companyId?: number | null,
  ingredients: { [key: string]: string } | undefined,
}

function IngredientInventoryView({ dataTableFetch = undefined, companyId = null, ingredients }: Props) {
  return (
    <Panel
      title="Inventory"
      hasFilter={false}
      onFilterClick={null}>
      <IngredientInventoryTable
        dataTableFetch={dataTableFetch}
        companyId={companyId}
        ingredients={ingredients}
      />
    </Panel>
  )
}

export default IngredientInventoryView
