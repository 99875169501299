import React from 'react'
import { useAsync } from 'react-async-hook'
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom'

// import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { graphQLFetch, PageView } from '@myx/console-utils'
import ContainerOverviewPanel from './ContainerOverviewPanel'
import ContainerHistoryPanel from './ContainerHistoryPanel'

function LinkTab(props) {
  return (
    <Tab
      component={Link}
      {...props}
    />
  )
}

function Container(props) {

  const { match } = props
  const containerId = decodeURIComponent(match.params.containerId)

  const tabMatch = useRouteMatch({
    path: `${match.path}/:subTab?`
  })

  const containerDataAsync = useAsync(() => {
    return graphQLFetch(`
      query($id: String!) {
        getIngredientContainers(
          map: [ { key: "eq:id", value: $id } ],
          limit: 1,
          offset: 0
        ) {
          ingredientContainers {
            id
            mfgSerialNumber
          }
        }
      }
    `, {
      id: containerId,
    }).then(({
      getIngredientContainers: { ingredientContainers: data },
    }) => (
      data[0]
    ))
  }, [], {
    // preserve previously loaded data while new data is being fetched
    setLoading: state => ({ ...state, loading: true })
  })

  const container = containerDataAsync.result

  return (
    <PageView
      url={match.url}
      title={`Container > ${container ? `#${container.mfgSerialNumber}` : ''}`}
      titleIsPending={!container}
    >
      <AppBar position="static">
        <Tabs
          value={tabMatch.params.subTab || 'overview'}
          centered
          variant="fullWidth"
        >
          <LinkTab label="Overview" value="overview" to={`${match.url}`}/>
          <LinkTab label="History" value="history" to={`${match.url}/history`}/>
        </Tabs>
      </AppBar>

      <Box pt={3} pb={3}>
        <Switch>
          <Route exact path={`${match.path}`} render={() =>
            <ContainerOverviewPanel containerId={containerId}/>
          }/>
          <Route path={`${match.path}/history`} render={() =>
            <ContainerHistoryPanel containerId={containerId}/>
          }/>
        </Switch>
      </Box>
    </PageView>
  )

}

export default Container
