import React from 'react'
import { useAsync } from 'react-async-hook'

import Grid from '@material-ui/core/Grid'

import { graphQLFetch } from '@myx/console-utils'
import ProgressWrapper from '../components/ProgressWrapper'
import SettingsBusinessDetailsCard from '../components/cards/SettingsBusinessDetailsCard'
import SettingsConsolePreferencesCard from '../components/cards/SettingsConsolePreferencesCard'
import SettingsContactCard from '../components/cards/SettingsContactCard'
import SettingsAddressesCard from '../components/cards/SettingsAddressesCard'

function SettingsMainPanel() {

  const operatorDataAsync = useAsync(() => {
    return graphQLFetch(`
      query {
        getCompany {
          id
          name
          contactGivenName
          contactFamilyName
          contactEmail
          contactPhone
          contactPhoneCountry
          contactPhoneExtension
          timezone
          locale
          changedBy
          changedOn
          billingAddressDetail {
            address1
            address2
            address3
            city
            state
            zip
            country
          }
          shippingAddressDetail {
            address1
            address2
            address3
            city
            state
            zip
            country
          }
        }
      }
    `, {}).then(({ getCompany: data }) => (
      { ...data }
    ))
  }, [], {
    // preserve previously loaded data while new data is being fetched
    setLoading: state => ({ ...state, loading: true })
  })

  const operator = operatorDataAsync.result
  const onComplete = () => operatorDataAsync.execute()

  return (
    <ProgressWrapper pending={operatorDataAsync.loading || !operatorDataAsync.result}>
      {operator && <Grid container spacing={2}>

        <Grid item xs={12} md={12}>
          <SettingsBusinessDetailsCard
            operator={operator}
            onComplete={onComplete}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <SettingsConsolePreferencesCard
            operator={operator}
            onComplete={onComplete}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <SettingsContactCard
            operator={operator}
            onComplete={onComplete}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <SettingsAddressesCard
            companyName={operator.name}
            billingAddress={operator.billingAddressDetail}
            shippingAddress={operator.shippingAddressDetail}
            onComplete={onComplete}
          />
        </Grid>

      </Grid>}
    </ProgressWrapper>
  )
}

export default SettingsMainPanel
