import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import KioskAlertsTable from '../components/tables/KioskAlertsTable'
import TimezoneDisplay from '../components/format/TimezoneDisplay'

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeader: {
    minHeight: 64, // ensure consistent height both without and with an action
    // padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    // backgroundColor: '#eee',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  cardHeaderAction: {
    marginTop: 0, // override -8px marginTop and marginRight so title and action icon line up
  },
  cardContent: {
    // padding: 0,
    '&:last-child': {
      // paddingBottom: theme.spacing(4),
    },
  },
  tableHeaderTopBorder: {
    // borderTop: `1px solid ${theme.palette.grey}`,
  },

  cardActions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  actionButton: {
    '& span > svg': {
      display: 'block',
      marginRight: theme.spacing(0.5),
    },
  },
  noWrap: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

// @todo - make date reporting timezone aware
function KioskAlertsPanel({ serviceAlerts = [], inventoryAlerts = [] }) {
  const classes = useStyles()

  return (<Grid container spacing={3}>
    <Grid item xs={12} md={12}>
      <Card className={classes.card}>
        <CardHeader
          title="Service Alerts"
          classes={{
            root: classes.cardHeader,
            action: classes.cardHeaderAction
          }}
          titleTypographyProps={{
            variant: 'h6',
            color: 'primary',
          }}
        />
        <CardContent className={classes.cardContent}>
          <KioskAlertsTable tableData={serviceAlerts} />
        </CardContent>
      </Card>
    </Grid>

    <Grid item xs={12} md={12}>
      <Card className={classes.card}>
        <CardHeader
          title="Inventory Alerts"
          classes={{
            root: classes.cardHeader,
            action: classes.cardHeaderAction
          }}
          titleTypographyProps={{
            variant: 'h6',
            color: 'primary',
          }}
        />
        <CardContent className={classes.cardContent}>
          <KioskAlertsTable tableData={inventoryAlerts} />
        </CardContent>
      </Card>
    </Grid>

    <Grid item xs={12}>
      <Box pl={2}>
            Timezone: <TimezoneDisplay />
      </Box>
    </Grid>
  </Grid>)
}

export default KioskAlertsPanel
