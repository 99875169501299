import React from 'react'
import { useFormikContext, useField } from 'formik'
import LuxonUtils from '@date-io/luxon'
import { DateTime } from 'luxon'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

function parseDateRange(value) {
  const [ dateA, dateB ] = value.split(':')

  return [
    dateA || null,
    dateB || null
  ]
}

function serializeDateRange(dateA, dateB) {
  // return range if at least one date is given
  if (dateA || dateB) {
    return `${dateA || ''}:${dateB || ''}`
  }

  return ''
}

export default function FilterEntryValueInput({ name, filterTypeOptions }) {
  const form = useFormikContext()
  const [ field ] = useField(`${name}.value`)

  const typeOptionsObject = typeof filterTypeOptions === 'object' ? filterTypeOptions : {}

  if (typeOptionsObject.options) {
    const valueOptionMap = typeOptionsObject.options

    return (
      <FormControl fullWidth>
        <InputLabel>Value</InputLabel>
        <Select
          displayEmpty
          {...field}
        >
          {Object.keys(valueOptionMap).map(valueOption =>
            <MenuItem
              value={valueOption}
              key={valueOption}
            >
              {valueOptionMap[valueOption]}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    )
  }

  if (typeOptionsObject.dateRange) {
    const [ dateA, dateB ] = parseDateRange(field.value)

    // @todo how to deal with missing date (or both)
    return (
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <Box display="flex" width="100%">
          <KeyboardDatePicker
            label="Date From"
            format="yyyy-MM-dd"
            value={dateA && DateTime.fromISO(dateA)}
            onChange={(newDate) => {
              form.setFieldValue(
                field.name,
                serializeDateRange(newDate && newDate.toFormat('yyyy-MM-dd'), dateB)
              )
            }}
          />

          <Box pl={1} />

          <KeyboardDatePicker
            label="Date To"
            format="yyyy-MM-dd"
            value={dateB && DateTime.fromISO(dateB)}
            onChange={(newDate) => {
              form.setFieldValue(
                field.name,
                serializeDateRange(dateA, newDate && newDate.toFormat('yyyy-MM-dd'))
              )
            }}
          />
        </Box>
      </MuiPickersUtilsProvider>
    )
  }

  // show text input by default
  return (
    <TextField
      label="Value"
      type="text"
      fullWidth
      {...field}
    />
  )
}
