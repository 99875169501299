import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import DateTimeToolTip from '../labels/DateTimeToolTip'
import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/core/styles'
import { green, red } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  connected: {
    color: green[800],
  },
  offline: {
    color: red[800],
  },
}))

function KioskConfigurationCard({ kiosk }) {
  const classes = useStyles()
  return (
    <Card>
      <CardHeader title="Configuration" />
      <CardContent>
        <Table size="medium">
          <TableBody>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>
                <DateTimeToolTip
                  title="Since: "
                  dateTime={kiosk.statusDetail ? kiosk.statusDetail.changedOn : null}>
                  <span>{(kiosk.statusDetail && kiosk.statusDetail.name) || '—'}&nbsp;</span>
                </DateTimeToolTip>
                {kiosk.statusDetail && kiosk.statusDetail.id !== 5 ? <CheckIcon className={classes.connected} fontSize="small" /> : <WarningIcon className={classes.offline} fontSize="small" />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Configuration</TableCell>
              <TableCell>{kiosk.freePlayMode ? 'Free-to-Pour' : 'Pay-to-Pour'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Locale</TableCell>
              <TableCell>{kiosk.locale}</TableCell>
            </TableRow>
            {/*
            // @future commented out for MVP, may require its own card and table
            <TableRow>
              <TableCell>USB Peripherals</TableCell>
              <TableCell>NFC</TableCell>
            </TableRow>
            */}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )

}

export default KioskConfigurationCard
