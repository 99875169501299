import React from 'react'
import { useAsync } from 'react-async-hook'

import Grid from '@material-ui/core/Grid'

import { graphQLFetch, PageView } from '@myx/console-utils'
import ProgressWrapper from '../components/ProgressWrapper'

import { useSessionContext } from '../components/SessionContext'
import MyAccountProfileCard from '../components/cards/MyAccountProfileCard'
import MyAccountPasswordCard from '../components/cards/MyAccountPasswordCard'
import MyAccountAlertsCard from '../components/cards/MyAccountAlertsCard'
import { usePermissions } from '../permissions'

function MyAccount({ match }) {

  const session = useSessionContext()

  const perms = usePermissions()

  const userDataAsync = useAsync(() => {
    return graphQLFetch(`
      query {
        getUser {
          id
          givenName
          familyName
          email
          phoneCountry
          phone
          phoneExtension
          inventoryAlert
          serviceAlert
          alertWithEmail
          alertWithSms
        }
      }
    `, {}).then(({ getUser: data }) => (
      { ...data }
    ))
  }, [], {
    // preserve previously loaded data while new data is being fetched
    setLoading: state => ({ ...state, loading: true })
  })

  const user = userDataAsync.result

  // refresh the session as well in case the name or email has changed (e.g. Header, etc)
  const onComplete = () => {
    userDataAsync.execute()
    session.refresh()
  }

  return (
    <PageView url={match.url} title="My Account">

      <ProgressWrapper pending={userDataAsync.loading || !userDataAsync.result}>
        {user && <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <MyAccountProfileCard user={user} onComplete={onComplete}/>
          </Grid>

          <Grid item xs={12} md={6}>
            <MyAccountPasswordCard user={user} onComplete={onComplete}/>
          </Grid>

          { perms.userCanOnlyScanContainers() ? <></>
            : <Grid item xs={12} md={6}>
              <MyAccountAlertsCard user={user} onComplete={onComplete}/>
            </Grid>
          }
        </Grid>}
      </ProgressWrapper>

    </PageView>
  )
}

export default MyAccount
