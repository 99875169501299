import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import { graphQLFetch, useAppModal } from '@myx/console-utils'
import PhoneDisplay from '../format/PhoneDisplay'
import EmailDisplay from '../format/EmailDisplay'
import MyAccountProfileDialog from '../dialogs/MyAccountProfileDialog'

function MyAccountProfileCard({ user, onComplete }) {

  const openEditDialog = useAppModal(
    {
      title: 'Edit User',
      resultMessage: 'User saved successfully',
      initialValues: {
        ...user
      },
      action: (formData) => graphQLFetch(
        `
          mutation ($data: UpdateUserInput!) {
            updateMyUser(update: $data) {
              id
            }
          }
        `,
        {
          userId: user.id,
          data: {
            givenName: formData.givenName,
            familyName: formData.familyName,
            email: formData.email,
            phoneCountry: formData.phoneCountry,
            phone: formData.phone,
            phoneExtension: formData.phoneExtension,
          }
        }
      ),
      onComplete
    },
    <MyAccountProfileDialog/>
  )

  return (
    <Card>
      <CardHeader
        action={
          <IconButton
            aria-label="edit contact information"
            onClick={openEditDialog}
          >
            <EditIcon/>
          </IconButton>
        }
        title="Contact Information"
      />
      <CardContent>
        <Table size="medium">
          <colgroup>
            <col style={{ width: '40%' }}/>
            <col style={{ width: '60%' }}/>
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{user.givenName} {user.familyName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>
                <EmailDisplay email={user.email}/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phone</TableCell>
              <TableCell>
                <PhoneDisplay
                  country={user.phoneCountry}
                  phone={user.phone}
                  extension={user.phoneExtension}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default MyAccountProfileCard
