import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import * as backend from '@myx/console-utils'
import { useAsync } from 'react-async-hook'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

/**
 * Give the user a choice of all kiosks, deployed, or pick a kiosk
 *
 * @param deployedOnly -- show the user deployed kiosks only?
 * @param selectedKioskId -- null or the kiosk serial number the user has selected
 * @param handleKioskIdSelected -- called when the user selects a kiosk from the dropdown.
 * @returns {JSX.Element} --
 */
const KioskIdPicker = ({ selectedKioskId, handleKioskIdSelected }) => {

  const [ kioskList, setKioskList ] = React.useState()

  const getKioskListAsync = useAsync(() => {
    return backend.graphQLFetch(`
      query {
        getKiosks(map: [],limit:1000, offset:0) {
           kiosks {
              id
              assetCode
              serialNumber
          }
        }
      }
    `, {}).then(({ getKiosks: kiosks }) => {
      setKioskList(kiosks.kiosks)
    })
  }, [], {
    // preserve previously loaded data while new data is being fetched
    setLoading: loadingState => ({ ...loadingState, loading: true })
  })

  return (
    <Grid container
      display={'flex'}
      direction={'column'}
      align={'center'}>
      <Grid item>
        <Typography gutterBottom variant="h6"> Select A Kiosk:</Typography></Grid>
      <Grid item>
        {
          getKioskListAsync.loading || !kioskList ? <CircularProgress/>
            : <Select

              value={selectedKioskId && kioskList ? selectedKioskId : ''}
              displayEmpty
              onChange={(event) => handleKioskIdSelected(event.target.value)}
            >
              {kioskList && !getKioskListAsync.loading
                ? kioskList.sort((kioskA, kioskB) => (kioskA.assetCode > kioskB.assetCode) ? 1 : -1).map((kiosk) => {
                  return (<MenuItem
                    key={kiosk.id}
                    value={kiosk.id}>
                    <div data-testid={'kiosk-picker-kiosk-asset-code'}>{kiosk.assetCode}</div>
                    <div data-testid={'kiosk-picker-kiosk-sn-' + kiosk.serialNumber}>({kiosk.serialNumber})</div>
                  </MenuItem>)
                }) : null
              }
            </Select>
        }
      </Grid>
    </Grid>
  )
}

KioskIdPicker.propTypes = {
  deployedOnly: PropTypes.bool.isRequired,
  handleDeployedChanged: PropTypes.func,
  selectedKioskId: PropTypes.string,
  handleKioskIdSelected: PropTypes.func.isRequired
}

export default KioskIdPicker
