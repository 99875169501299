import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { usePaginatedTable } from '../components/PaginatedTable'
import { IngredientInventoryView, LinkTab, PageView, getTokenDetails } from '@myx/console-utils'
import { useSessionContext } from '../components/SessionContext'
import Container from './Container'
import ContainersView from './ContainersView'
import Tabs from '@material-ui/core/Tabs'
import { AppBar } from '@material-ui/core'
import { UserSession } from '@myx/console-utils/dist/@types/UserSession'

type Props = {
  match: { path: string, url: string }
}

// Temporary workaround until sessioncontext issue is resolved.
const getCompanyIdFromToken = () => {
  // eslint-disable-next-line no-console
  console.log('Token Details ', getTokenDetails())
  return getTokenDetails().company_id
}

function ContainersController({ match }: Props) {

  const session: UserSession = useSessionContext()

  const tableState = usePaginatedTable({
    match,
    filterSlugs: {
      mfgSerialNumber: 'serial',
      manufactureLotCode: 'lot',
      kiosk: 'kiosk', // kiosk serial number
      soonestExpireOn: 'exp',
      ingredientId: 'ing'
    },
    defaultSortColumn: 'name',
    defaultPageSize: 10
  })

  const tabMatch = useRouteMatch({
    path: `${match.path}/:subTab?`
  })

  const containerMatch = useRouteMatch({
    path: `${tableState.subMatch.path}/:containerId`
  })

  if (containerMatch) {
    return <Container match={containerMatch}/>
  }

  return (
    <PageView url={match.url} title="Container Inventory" titleIsPending={false}>

      <AppBar position="static">
        <Tabs
          value={tabMatch?.url || '/containers'}
          centered
          variant="fullWidth">
          <LinkTab label="Containers" value="/containers" to={'/containers'}/>
          <LinkTab label="Inventory" value="/inventory/containers" to={'/inventory/containers'}/>
        </Tabs>
      </AppBar>
      <Switch>
        <Route path={'/inventory/containers'} render={() => <IngredientInventoryView
          companyId={getCompanyIdFromToken()}
          ingredients={session.ingredients}
        />
        }/>
        <Route path={`${match.path}`}
          render={() => <ContainersView tableState={tableState} ingredients={session.ingredients}/>}/>
      </Switch>
    </PageView>
  )

}

export default ContainersController
