import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { MdFileDownload as FileDownloadIcon } from 'react-icons/md'

import ReportForm from '../ReportForm'
import AppFormDateField from '../form/AppFormDateField'
import { graphQLFetch } from '@myx/console-utils'

const useStyles = makeStyles((theme) => ({
  actionButton: {
    '& span > svg': {
      display: 'block',
      marginRight: theme.spacing(0.5),
    },
  },
}))

// note - AppFormDateField's expect to be wrapped in a MuiPickersUtilsProvider
export function ReportsAlertsCard() {
  const classes = useStyles()

  return (
    <ReportForm
      initialValues={{
        startTime: null,
        endTime: null,
      }}
      tokenAction={(formData) => graphQLFetch(
        `
          mutation ($reportCode: String!, $fileType: ReportFormat!, $startTime: String, $endTime: String) {
            newReportToken(new: {
              reportCode: $reportCode,
              fileType: $fileType,
              startTime: $startTime,
              endTime: $endTime
            })
          }
        `,
        {
          // values for startTime and endTime are Luxon DateTime objects
          reportCode: 'alerts',
          fileType: formData.fileType,
          startTime: formData.startTime && formData.startTime.toISODate(),
          endTime: formData.endTime && formData.endTime.toISODate(),
        }
      ).then(({ newReportToken }) => newReportToken)}
    >
      {(form) =>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h2" color="primary">
              Alerts Report
            </Typography>
            <Typography gutterBottom variant="body1">
              Alerts from all Kiosks by Date Range (inclusive)
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}><AppFormDateField name="startTime" label="Date From"/></Grid>
              <Grid item xs={12} sm={6}><AppFormDateField name="endTime" label="Date To"/></Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button type="submit" onClick={() => form.setFieldValue('fileType', 'csv')} className={classes.actionButton}
              size="small" color="primary" variant="outlined">
              <FileDownloadIcon/>
              CSV
            </Button>
            <Button type="submit" onClick={() => form.setFieldValue('fileType', 'xlsx')}
              className={classes.actionButton} size="small" color="primary" variant="outlined">
              <FileDownloadIcon/>
              XLSX
            </Button>
          </CardActions>
        </Card>
      }
    </ReportForm>
  )
}

export default ReportsAlertsCard
