import React from 'react'
import Typography from '@material-ui/core/Typography'
import QrReader from 'react-qr-reader'
import { DateDisplay, graphQLFetch, parseContainerNumber } from '@myx/console-utils'
import ContainerInfo from '../components/ContainerInfo'
import Button from '@material-ui/core/Button'
import { useAsync } from 'react-async-hook'
import ProgressWrapper from '../components/ProgressWrapper'

type ScannedState = {
  containerPromise: Promise<any> | null,
  containerCompanyPromise: Promise<any> | null,
  error: boolean
}

const defaultScannedState: ScannedState = {
  containerPromise: null,
  containerCompanyPromise: null,
  error: false
}

type Props = {
  initialScannedState: ScannedState | null,
}

const OwnedByContainer = ({ containerCompanyPromise }: { containerCompanyPromise: Promise<any> }) => {
  const getCompanyAsync = useAsync(() => containerCompanyPromise, [])

  const company = getCompanyAsync.result
  return (<ProgressWrapper pending={!company}>
    { company && <Typography align="center">Owned by {company.getContainerCompany.name}</Typography> }
  </ProgressWrapper>)
}

const DisplayScannedState = ({ scannedState, rescanClick }: { scannedState: any, rescanClick: () => void }) => {

  const containerScanAsync = useAsync(() => scannedState.containerPromise
    .catch(() => rescanClick()), [])

  const container : any = containerScanAsync.result

  return <ProgressWrapper pending={!container}>
    { container && <>
      <ContainerInfo
        onClick={null}
        isEnabled={true}
        position={null}
        overrideStyles={undefined}
        container={container.getIngredientContainers.ingredientContainers[0]}
        ingredient={container.getIngredientContainers.ingredientContainers[0].ingredientDetail}
        additionalContent={<>
          <Typography align="center">Expires On <DateDisplay dt={container.getIngredientContainers.ingredientContainers[0].soonestExpireOn}/></Typography>
          <OwnedByContainer containerCompanyPromise={scannedState.containerCompanyPromise}/>
        </>}
      />
      <Button
        fullWidth
        variant="outlined"
        onClick={rescanClick}
      >
      Rescan
      </Button>
    </>
    }
  </ProgressWrapper>
}
function ScanContainersView({ initialScannedState }: Props) {

  const [ scannedState, setScannedState ] = React.useState(initialScannedState || defaultScannedState)

  return <>
    <Typography color="primary">
      Scan the container with your camera
    </Typography>
    { scannedState.containerPromise ? <DisplayScannedState
      scannedState={scannedState}
      rescanClick={() => setScannedState({ containerPromise: null, containerCompanyPromise: null, error: false })}
    /> : <QrReader
      facingMode={'environment'}
      delay={300}
      onScan={(scanned) => {
        if (scanned !== null) {

          const containerPromise = graphQLFetch(`
        query($map: [MapEntryInput!]!) {
          getIngredientContainers(map: $map, limit: 1, offset: 0) {
            total
            ingredientContainers {
                id
                mfgSerialNumber
                remainingPercent
                companyId
                soonestExpireOn
                shelfExpireOn
                ingredientDetail {
                  id
                  name
                }
            }
          }
        }
      `, {
            map: { key: 'mfgSerialNumber', value: parseContainerNumber(scanned || '') }
          })

          const containerCompanyPromise = containerPromise.then(({ getIngredientContainers: { ingredientContainers: data } }) => {
            return graphQLFetch(`
      query($containerId: String!) {
        getContainerCompany(containerId: $containerId) {
          id
          name
        }
      }
  `, { containerId: data[0].id })
          })

          setScannedState({
            containerPromise: containerPromise,
            error: false,
            containerCompanyPromise: containerCompanyPromise,
          })
        }
      }
      }
      onError={() => setScannedState({ containerPromise: null, containerCompanyPromise: null, error: true })}
    />
    }
  </>
}

export default ScanContainersView
