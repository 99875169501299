
import React from 'react'

import AppFormSelectField from './AppFormSelectField'

const countries = {
  'USA': 'United States',
  'CAN': 'Canada',
  'MEX': 'Mexico',
  'GBR': 'United Kingdom',
}

function AppFormCountryField(props) {
  return (
    <AppFormSelectField {...props} options={countries} />
  )
}

export default AppFormCountryField
