import React from 'react'
import { TableCell, useMediaQuery, useTheme } from '@material-ui/core'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import { useAsync } from 'react-async-hook'
import { useHistory } from 'react-router-dom'
import { DataTableRowActions } from './DataTable'
import ProgressWrapper from '../ProgressWrapper'
import { IngredientInventoryOverview } from '../../@types/IngredientInventoryOverview'
import * as backend from '../../backend'

type IngredientInventoryRowActionsProps = {
  ingredientId: number,
  match?: any | undefined,
}

function IngredientInventoryRowActions({ ingredientId, match }: IngredientInventoryRowActionsProps) {

  const history = useHistory()

  return (
    <DataTableRowActions
      onDetailsClick={() => {
        history.push(`${match ? match.url : '/containers'}/ing:${ingredientId}`)
      }}/>
  )
}

type Props = {
  dataTableFetch?: Promise<any>,
  companyId?: number | null,
  match?: any | undefined,
  ingredients: { [key: string]: string } | undefined,
}

/**
 * returns a 0 inventory record for ingredients not returned by the API.
 * @param ingredients
 * @param inventories
 */
const getIngredientInventories = (ingredients: { [key: string]: string } | undefined, inventories: Array<any>): Array<any> => ingredients ? Object.keys(ingredients)
  .filter((ingredientId) => `${ingredientId}` !== `${1}`)
  .map((ingredientId: any) =>
    inventories.find((inv) => `${ingredientId}` === `${inv.ingredientId}`) || {
      ingredientId: ingredientId,
      ingredientName: ingredients[ingredientId],
      total: 0,
    }
  ) : inventories

function IngredientInventoryTable({ dataTableFetch = undefined, match = undefined, companyId, ingredients }: Props) {

  const theme = useTheme()

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('xs'))

  const ingredientResultAsync = useAsync(() => (dataTableFetch
    ? dataTableFetch
    : backend.graphQLFetch(`
           query ($map: [MapEntryInput!]!) {
            getIngredientInventory(map: $map) {
              ingredientId
              ingredientName
              total
              companyId
              companyName
            }
        }
  `, {
      map: companyId ? [
        { key: 'archived', value: 0 },
        { key: 'companyId', value: companyId }
      ] : [ { key: 'archived', value: 0 } ]
    })), [])

  const ingredientInventory: any = ingredientResultAsync.result

  return (
    <ProgressWrapper pending={ingredientResultAsync.loading || !ingredientResultAsync.result}>
      <Table>
        <colgroup>
          <col style={{ width: '33%' }}/>
          <col style={{ width: '33%' }}/>
          <col style={{ width: '33%' }}/>
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>Ingredient Name</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        {ingredientInventory &&
          <TableBody>
            {getIngredientInventories(ingredients, ingredientInventory.getIngredientInventory)
              .sort((aaa: IngredientInventoryOverview, bbb: IngredientInventoryOverview) => {
                return ((aaa?.ingredientName || '') > (bbb?.ingredientName || '') ? 1 : -1)
              })
              .map((inventory: IngredientInventoryOverview, index: number) =>
                <TableRow
                  key={`${index}-${inventory.ingredientId}`}>
                  <TableCell>{inventory.ingredientName}</TableCell>
                  <TableCell>{inventory.total}</TableCell>
                  <TableCell>
                    {inventory.ingredientId &&
                      <IngredientInventoryRowActions match={match} ingredientId={inventory.ingredientId}/>
                    }
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        }
      </Table>
    </ProgressWrapper>
  )
}

export default IngredientInventoryTable
