import React from 'react'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { TextField } from '@material-ui/core'

function ItemCounter({ count, setCount }) {

  return (<>
    <div style={{
      textAlign: 'center',
    }}>
      <ButtonGroup>
        <Button
          onClick={() => {
            setCount(Math.max(count - 1, 0))
          }}
        >
          {' '}
          <RemoveIcon fontSize="small"/>
        </Button>
        <TextField
          style={{
            width: '50px',
          }}
          size="small"
          type="text"
          value={count}
          onChange={(ev) => setCount(Number.parseFloat(ev.target.value))}
        />
        <Button
          onClick={() => {
            setCount(count + 1)
          }}
        >
          {' '}
          <AddIcon fontSize="small"/>
        </Button>
      </ButtonGroup>
    </div>
  </>)
}

export default ItemCounter
