import React from 'react'
import { useAsync } from 'react-async-hook'

import CircularProgress from '@material-ui/core/CircularProgress'

import AppFormSelectField from '../form/AppFormSelectField'
import { graphQLFetch } from '@myx/console-utils'

function SettingsPreferencesDialog() {

  const prefDataAsync = useAsync(() => graphQLFetch(`
    query {
      getTimezones
      getLocales
    }
  `, {}).then(({
    getTimezones: tzs,
    getLocales: lcs,
  }) => ({
    timezones: tzs,
    locales: lcs
  })), [])

  if (prefDataAsync.loading || !prefDataAsync.result) {
    return (
      <CircularProgress/>
    )
  }

  const tzOptions = [] // array will preserve numeric keys vs objects
  prefDataAsync.result.timezones.forEach(tz => {
    tzOptions[tz] = tz
  })

  const lcOptions = [] // array will preserve numeric keys vs objects
  prefDataAsync.result.locales.forEach(lc => {
    lcOptions[lc] = lc
  })

  return (
    <>
      <AppFormSelectField
        name="timezone"
        label="Timezone"
        options={tzOptions}
        required
        helperText="Reporting timezone"
      />

      <AppFormSelectField
        name="locale"
        label="Locale"
        options={lcOptions}
        required
        helperText="Locale for language and regional formatting purposes"
      />
    </>
  )
}

export default SettingsPreferencesDialog
