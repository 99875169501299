import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import { TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import EmailDisplay from '../format/EmailDisplay'
import PhoneDisplay from '../format/PhoneDisplay'
import DeleteIcon from '@material-ui/icons/Delete'
import EmailTextDialog from '../dialogs/EmailTextDialog'
import { useAppModal } from '@myx/console-utils'

function KioskUserDeleteButton({ kioskUser, onDeleteClick, onComplete }) {

  const confirmDeleteModal = useAppModal({
    title: '',
    resultMessage: 'Contact Removed',
    actionLabel: 'Yes',
    action: () => onDeleteClick(kioskUser),
    onComplete: onComplete
  }, <Typography align="center" color="primary" variant="h6" component="h6">
    Are you sure you want to remove this user?
  </Typography>)

  return (<IconButton onClick={confirmDeleteModal}>
    <DeleteIcon/>
  </IconButton>)
}

function KioskUserCard({ kioskId, kioskUsers, editable, onComplete, onContactDelete, onContactAdd }) {

  const addContactModal = useAppModal({
    title: 'Add Kiosk User',
    resultMessage: 'Kiosk User Added',
    initialValues: { email: '', kioskId: kioskId },
    action: (formData) => onContactAdd(formData),
    onComplete: onComplete,
  }, <EmailTextDialog primaryText="Add Kiosk User's email address"/>)

  return (<Card>
    <CardHeader
      action={editable && <IconButton
        aria-label="Edit Kiosk User"
        onClick={addContactModal}
      >
        <EditIcon/>
      </IconButton>
      }
      title="External Users"
    />
    <CardContent>
      <Table size="medium">
        {kioskUsers.length > 0 && <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead> }
        <TableBody>
          {(kioskUsers.length === 0) &&
          <TableRow>
            <TableCell colSpan="3">No Users Defined</TableCell>
          </TableRow>
          }
          {
            kioskUsers && kioskUsers.map((kioskUser) => <TableRow key={kioskUser.id}>
              <TableCell>
                {(kioskUser.givenName || kioskUser.familyName)
                  ? `${kioskUser.givenName} ${kioskUser.familyName}`
                  : '—'
                }
              </TableCell>
              <TableCell>
                <EmailDisplay email={kioskUser.email}/>
              </TableCell>
              <TableCell>
                <PhoneDisplay
                  country={kioskUser.phoneCountry}
                  phone={kioskUser.phone}
                  extension={kioskUser.phoneExtension}
                />
              </TableCell>
              <TableCell>
                <KioskUserDeleteButton
                  kioskUser={kioskUser}
                  onComplete={onComplete}
                  onDeleteClick={onContactDelete}
                />
              </TableCell>
            </TableRow>)
          }
        </TableBody>
      </Table>
    </CardContent>
  </Card>)
}

export default KioskUserCard
