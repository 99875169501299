import React, { useCallback } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useField, useFormikContext } from 'formik'

/**
 * Formik-powered date field. Requires a `<MuiPickersUtilsProvider utils={LuxonUtils}>` to wrap any
 * AppFormDateField components (refer to Reports page for an example).
 */
function AppFormDateField(props) {

  const validator = useCallback((value) => {
    if (!value && props.required) {
      // flag as error if required
      return 'Missing required field'
    }
    return undefined
  }, [ props.required ])

  const [ field, meta ] = useField({
    name: props.name,
    validate: validator
  })

  const form = useFormikContext()

  const error = !!(meta.touched && meta.error)

  return (
    <KeyboardDatePicker
      label={props.label}
      autoFocus={props.autoFocus}
      clearable
      margin="normal" // dense
      format="yyyy-MM-dd"
      fullWidth
      name={field.name}
      value={field.value}
      error={error}
      helperText={(error && meta.error) || props.helperText}
      required={props.required}
      onError={err => {
        // handle as a side effect
        if (err !== meta.error) {
          form.setFieldError(field.name, err)
        }
      }}
      onChange={date => form.setFieldValue(field.name, date)}
      onBlur={field.onBlur}
    />
  )
}

export default AppFormDateField
