import React from 'react'
import { Typography } from '@material-ui/core'
import QrReader from 'react-qr-reader'
import { graphQLFetch } from '@myx/console-utils'
import ContainerInfo from '../ContainerInfo'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ErrorView from '../../pages/ErrorView'

/**
 * IE http://mydrinksmart.com/ingredient?sn=12-7000
 * @param url
 */
const parseContainerNumber = (url) => {
  // eslint-disable-next-line no-unused-vars
  const [ _urlBase, searchParams ] = url.split('?')

  const urlParams = new URLSearchParams(searchParams)
  return urlParams.get('sn')
}

const useStyles = makeStyles((theme) => ({
  ingredient: {
    cursor: 'pointer',
    marginBottom: theme.spacing(3),
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(15),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(20),
    }
  },
}))

const DisplayScannedState = ({ scannedState, rescanClick, position, classes }) => scannedState && scannedState.container
  ? <>
    <Typography align="center">Replace with</Typography>
    <ContainerInfo
      overrideStyles={classes.ingredient}
      isEnabled={position.isEnabled}
      container={scannedState.container}
      ingredient={scannedState.container.ingredientDetail}
    />
    <Button
      fullWidth
      variant="outlined"
      onClick={rescanClick}
    >
      Rescan
    </Button>
  </> : <ErrorView
    title="QR Code could not be read"
    message="Make sure you are scanning a valid MYX container and that the container is active in your inventory."
    onConfirm={rescanClick}
  />

function ContainerQRScanModal({ form, position, defaultState = null, installedcontainer }) {

  const [ scannedState, setScannedState ] = React.useState(defaultState)

  const updateSelected = (container) => {
    form.setFieldValue('serialNumber', container.mfgSerialNumber)
    setScannedState({ error: false, container: container })
  }

  const rescanClick = () => setScannedState(null)

  const doWithParsedScan = (qrCode, func) => {
    if (qrCode !== null) {
      const parsedSerialNumber = qrCode ? parseContainerNumber(qrCode) : null
      if (parsedSerialNumber && parsedSerialNumber.trim() !== '') {
        func(parsedSerialNumber)
      } else {
        setScannedState({ error: true })
      }
    }
  }

  const classes = useStyles()

  return scannedState && (scannedState.container || scannedState.error === true) ? <DisplayScannedState
    scannedState={scannedState}
    classes={classes}
    position={position}
    rescanClick={rescanClick}
  /> : (<>
    <Typography color="primary" variant="h8">Scan the new container with your camera</Typography>
    <QrReader
      facingMode={'environment'}
      delay={300}
      onScan={(scanned) => doWithParsedScan(scanned, (parsedQr =>
        graphQLFetch(`
        query($map: [MapEntryInput!]!) {
          getIngredientContainers(map: $map, limit: 1, offset: 0) {
            total
            ingredientContainers {
                id
                mfgSerialNumber
                remainingPercent
                ingredientDetail {
                  id
                  name
                }
            }
          }
        }
      `, {
          map: { key: 'mfgSerialNumber', value: parsedQr }
        }).then((response) => {
          if (response && response.getIngredientContainers && response.getIngredientContainers.ingredientContainers.length > 0) {
            updateSelected(response.getIngredientContainers.ingredientContainers[0])
          } else {
            setScannedState({ error: true })
          }
        })))
      }
      style={{ width: '100%', height: '75%' }}
    />
  </>)
}

export default ContainerQRScanModal
