import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  link: {
    color: `${theme.palette.primary.main}`,
    '&:visited': {
      color: `${theme.palette.primary.main}`,
    },
    '&:hover': {
      color: `${theme.palette.secondary.main}`,
    },
  },
}))

// return a tel:/ link to the given phone number, or a dash character if no number.
function PhoneDisplay({ country, phone, extension }) {
  const classes = useStyles()

  // delete all non digit characters
  const cleanPhone = (pn) => {
    return ('' + pn).replace(/\D/g, '')
  }

  // add dashes to given `phone` number
  const formatPhone = (pn) => {
    const cleaned = cleanPhone(pn)
    const matchFull = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (matchFull) {
      return `${matchFull[1]}-${matchFull[2]}-${matchFull[3]}`
    }
    const matchShort = cleaned.match(/^(\d{3})(\d{4})$/)
    if (matchShort) {
      return `${matchShort[1]}-${matchShort[2]}`
    }
    return pn
  }

  const countryDisplay = country
    ? (parseInt(country, 10) === 1) ? '' : `+${country} `
    : ''

  const countryHref = country
    ? `+${(parseInt(country, 10)) + ''}`
    : ''

  const phoneDisplay = phone
    ? formatPhone(phone)
    : ''

  const phoneHref = cleanPhone(phone)

  const extensionDisplay = extension ? ` x${extension}` : ''

  const extensionHref = extension ? `p${extension}` : '' // p = 1 sec pause, w = wait for dialtone

  return (
    <span>
      { phoneDisplay
        ? (
          <a
            href={`tel:${countryHref}${phoneHref}${extensionHref}`}
            className={classes.link}
          >
            {countryDisplay + phoneDisplay + extensionDisplay}
          </a>
        )
        : '—'
      }
    </span>
  )
}

export default PhoneDisplay
