import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import { graphQLFetch, useAppModal } from '@myx/console-utils'
import SettingsPreferencesDialog from '../dialogs/SettingsPreferencesDialog'

function SettingsConsolePreferencesCard({ operator, onComplete }) {
  const openEditDialog = useAppModal(
    {
      title: 'Edit Preferences',
      resultMessage: 'Preferences saved successfully',
      initialValues: {
        ...operator
      },
      action: (formData) => graphQLFetch(
        `
          mutation ($data: UpdateCompanyTimezoneLocaleInput!) {
            updateCompanyTimezoneLocale(update: $data) {
              id
            }
          }
        `,
        {
          data: {
            timezone: formData.timezone,
            locale: formData.locale
          }
        }
      ),
      onComplete
    },
    <SettingsPreferencesDialog/>
  )

  return (
    <Card>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            onClick={openEditDialog}
          >
            <EditIcon/>
          </IconButton>
        }
        title="Console Preferences"
      />
      <CardContent>
        <Table size="medium">
          <colgroup>
            <col style={{ width: '25%' }}/>
            <col style={{ width: '75%' }}/>
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Reporting Time Zone</TableCell>
              <TableCell>{operator.timezone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Locale</TableCell>
              <TableCell>{operator.locale}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default SettingsConsolePreferencesCard
