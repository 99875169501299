import { useMemo } from 'react'
import { useSessionContext } from './components/SessionContext'

export const ALLOWED_ROLES = [ 'operator', 'servicetech', 'fulfillment', 'shipping' ]

// given an array of strings that correspond to user roles, return if the user can access the console.
export function userCanAccessConsole(userRoles) {
  return userRoles.some(role => ALLOWED_ROLES.includes(role))
}

function userCanAccessSettings(session) {
  return !!session.roles.operator
}

function userCanAccessUsers(session) {
  return !!session.roles.operator
}

function userCanAccessReports(session) {
  return !!session.roles.operator
}

function userCanEditKiosks(session) {
  return !!session.roles.operator
}

function userCanAccessScanContainers(session) {
  return !!(session.roles.shipping || session.roles.admin)
}

function userCanOnlyScanContainers(session) {
  return (!session.roles.operator && !session.roles.servicetech && !session.roles.fulfillment && !session.roles.admin) &&
    !!session.roles.shipping
}

export function usePermissions() {
  const session = useSessionContext()

  const perms = useMemo(() => {
    return {
      userCanAccessSettings: () => userCanAccessSettings(session),
      userCanAccessUsers: () => userCanAccessUsers(session),
      userCanAccessReports: () => userCanAccessReports(session),
      userCanEditKiosks: () => userCanEditKiosks(session),
      userCanAccessScanContainers: () => userCanAccessScanContainers(session),
      userCanOnlyScanContainers: () => userCanOnlyScanContainers(session),
    }
  }, [ session ])

  return perms
}
