import React, { useCallback } from 'react'

import { useField, useFormikContext } from 'formik'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

function AppFormSelectField(props) {

  const validator = useCallback((value) => {
    if (!value) {
      // flag as error if required
      if (props.required) {
        return 'Missing required field'
      }

      return null
    }

    return null
  }, [ props.required ])

  const [ field, meta ] = useField({
    name: props.name,
    validate: validator
  })

  const formik = useFormikContext()

  const error = !!(meta.touched && meta.error)

  // note re empty value handling: formik works with null, react material works with -1
  return (
    <FormControl fullWidth error={error} margin="normal">
      <InputLabel>{props.label}</InputLabel>
      <Select
        name={field.name}
        value={(field.value === null) ? (-1) : field.value}
        onChange={(event) => {
          const formValue = event.target.value === -1 ? null : event.target.value
          formik.setFieldValue(field.name, formValue)
          // formik.setFieldTouched(field.name)
        }}
        onBlur={field.onBlur}
      >
        <MenuItem value={-1}><em>{props.emptyOptionLabel || <span>&nbsp;</span>}</em></MenuItem>
        {Object.keys(props.options).map(option =>
          <MenuItem
            value={option}
            key={option}
          >
            {props.options[option]}
          </MenuItem>
        )}
      </Select>
      {(error || props.helperText) && (
        <FormHelperText>{(error && meta.error) || props.helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

export default AppFormSelectField
