import React, { useCallback } from 'react'
import { useField } from 'formik'
import TextField from '@material-ui/core/TextField'

function AppFormTextField(props) {

  // eslint-disable-next-line max-statements
  const validator = useCallback((value) => {
    if (!value || value.trim() === '') {
      // flag as error if required
      if (props.required) {
        return 'Missing required field'
      }

      return undefined
    }

    if (!!props.validateWholeNumber) {
      if (Number.isNaN(value) || value < 0 || (value % 1 !== 0)) {
        return 'Must be whole number'
      }
      return undefined
    }

    if (props.type === 'email' && !RegExp(/^\S+@\S+\.\S+$/).test(value)) {
      return 'Valid email address required'
    }

    return undefined
  }, [ props.required, props.type, props.validateWholeNumber ])

  const [ field, meta ] = useField({
    name: props.name,
    validate: validator
  })

  const error = !!(meta.touched && meta.error)

  return (
    <TextField
      autoFocus={props.autoFocus}
      margin="normal" // dense
      name={field.name}
      value={field.value || ''}
      label={props.label}
      type={props.type || 'text'}
      inputProps={props.inputProps || {}}
      fullWidth
      error={error}
      helperText={(error && meta.error) || props.helperText}
      required={props.required}
      onChange={field.onChange}
      onBlur={field.onBlur}
    />
  )
}

export default AppFormTextField
