import React from 'react'
import { Tooltip } from '@material-ui/core'
import DateDisplay from './DateDisplay'
import { DateTime, Interval } from 'luxon'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import Grid from '@material-ui/core/Grid'

type Props = {
  dt: string,
}

const SOON_DAYS = 15

const getDisplayColor = (now: DateTime, date: DateTime) => {
  if (now >= date) {
    return 'red'
  }
  if (now.plus({ days: SOON_DAYS }) >= date) {
    return 'orange'
  }
  return 'black'
}

const getComponentIcon = (color: string) => {
  if (color === 'red') {
    return <Grid item>
      <ErrorIcon fontSize="small" style={{ color: 'red' }}/>
    </Grid>
  }

  if (color === 'orange') {
    return <Grid item>
      <WarningIcon fontSize="small" style={{ color: 'orange' }}/>
    </Grid>
  }
  return null
}

function ExpiryDateDisplay(props: Props) {

  const date = DateTime.fromISO(props.dt)
  const now = DateTime.now()
  const color = getDisplayColor(now, date)
  const daysUntilExpired = Math.floor(Interval.fromDateTimes(now, date).length('days'))

  return (<Tooltip title={`${daysUntilExpired || 0} day(s) until expired`}>
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item>
        <DateDisplay color={color} dt={props.dt}/>
      </Grid>
      {getComponentIcon(color)}
    </Grid>
  </Tooltip>)
}

export default ExpiryDateDisplay
