import React, { useRef } from 'react'
import { SnackbarProvider, useSnackbar, WithSnackbarProps } from 'notistack'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

type Props = {
  children: any
}
// global snack state that persists across content route changes/unmounts/etc
export const AppSnackProvider = ({ children }: Props) => {
  const notistackRef = useRef<WithSnackbarProps>()

  return (
    <SnackbarProvider
      maxSnack={8}
      ref={notistackRef}
      action={(messageKey) => (
        <IconButton
          aria-label="Close"
          color="inherit"
          onClick={() => {
            if (notistackRef && notistackRef.current) {
              notistackRef.current.closeSnackbar(messageKey)
            }
          }}
        >
          <CloseIcon/>
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  )
}

export function useAppSnack() {
  // expose the Notistack trigger directly
  const { enqueueSnackbar } = useSnackbar()

  return enqueueSnackbar
}
