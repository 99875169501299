import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles((theme) => ({
  link: {
    color: `${theme.palette.primary.main}`,
    '&:visited': {
      color: `${theme.palette.primary.main}`,
    },
    '&:hover': {
      color: `${theme.palette.secondary.main}`,
    },
  },
}))

const KioskLink = React.forwardRef((props, ref) => {
  const classes = useStyles()
  const { kioskId, ...otherProps } = props

  return (
    <RouterLink
      className={classes.link}
      innerRef={ref}
      to={`/kiosks/${encodeURIComponent(kioskId)}`}
      {...otherProps}
    />
  )
})

function ContainerLocationCard({ container }) {

  return (
    <Card>
      <CardHeader title="Location" />
      <CardContent>
        <Table size="medium">
          <colgroup>
            <col style={{ width: '50%' }}/>
            <col style={{ width: '50%' }}/>
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Kiosk</TableCell>
              <TableCell>
                {(container.kioskPositionDetail && container.kioskPositionDetail.kioskDetail && container.kioskPositionDetail.kioskDetail.serialDetail)
                  ? (
                    <KioskLink kioskId={container.kioskPositionDetail.kioskDetail.id}>
                      {container.kioskPositionDetail.kioskDetail.serialDetail.serialNumber}
                    </KioskLink>
                  )
                  : '—'
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Position</TableCell>
              <TableCell>
                {(container.kioskPositionDetail && container.kioskPositionDetail.position)
                  ? container.kioskPositionDetail.position
                  : '—'
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default ContainerLocationCard
