import React from 'react'
import { useAsync } from 'react-async-hook'
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Badge from '@material-ui/core/Badge'
import { graphQLFetch, PageView } from '@myx/console-utils'
import KioskAlertsPanelController from './KioskAlertsPanelController'
import KioskPositionsPanel from './KioskPositionsPanelController'
import KioskOverviewController from './KioskOverviewController'

const useStyles = makeStyles(theme => ({
  padding: {
    padding: theme.spacing(0, 2),
    // top: theme.spacing(1),
  },
}))

function LinkTab(props) {
  return (
    <Tab
      component={Link}
      {...props}
    />
  )
}

function Kiosk(props) {
  const classes = useStyles()

  const kioskId = decodeURIComponent(props.match.params.id)

  const tabMatch = useRouteMatch({
    path: `${props.match.path}/:subTab?`
  })

  const kioskDataAsync = useAsync(() => {
    return graphQLFetch(`
      query($id: String!) {
        getKiosks(
          map: [ { key: "eq:id", value: $id } ],
          limit: 1,
          offset: 0
        ) {
          kiosks {
            assetCode
            totalAlertCount
            serialDetail {
              serialNumber
            }
          }
        }
      }
    `, {
      id: kioskId,
    }).then(({
      getKiosks: { kiosks: data },
    }) => (
      data[0]
    ))
  }, [])

  const kiosk = kioskDataAsync.result

  return (
    <PageView
      url={props.match.url}
      title={`Kiosk > ${kiosk ? `${kiosk.assetCode ? kiosk.assetCode : '--'}(${kiosk.serialDetail.serialNumber})` : ''}`}
      titleIsPending={!kiosk}
    >
      <AppBar position="static">
        <Tabs
          value={tabMatch.params.subTab || 'overview'}
          centered
          variant="fullWidth"
        >
          <LinkTab label="Overview" value="overview" to={props.match.url}/>
          <LinkTab
            label={
              <Badge badgeContent={kiosk && kiosk.totalAlertCount} color="secondary" className={classes.padding}>
                Alerts
              </Badge>
            }
            value="alerts"
            to={`${props.match.url}/alerts`}
          />
          <LinkTab label="Positions" value="positions" to={`${props.match.url}/positions`}/>
          {/* <LinkTab label="Pricing" value="pricing" to={`${props.match.url}/pricing`} /> */}
        </Tabs>
      </AppBar>
      <Box pt={3} pb={3}>
        <Switch>
          <Route exact path={`${props.match.path}`} render={() =>
            <KioskOverviewController kioskId={kioskId}/>
          }/>
          <Route path={`${props.match.path}/alerts`} render={() =>
            <KioskAlertsPanelController kioskId={kioskId}/>
          }/>
          <Route path={`${props.match.path}/positions`} render={() =>
            <KioskPositionsPanel kioskId={kioskId}/>
          }/>
          {/*
          <Route path={`${props.match.path}/pricing`} render={() =>
            <KioskPricingPanel kioskId={kioskId} />
          } />
          */}
        </Switch>
      </Box>
    </PageView>
  )

}

export default Kiosk
