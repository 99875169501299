import React from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery, useTheme } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  iconMargin: {
    marginRight: theme.spacing(1),
  }
}))

function UserInvitePaperView({ userRegistrations }) {
  const classes = useStyles()

  const theme = useTheme()
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('xs'))

  return (<Box mb={3}>
    <Paper className={classes.paper}>
      <Box display="flex" justifyContent="space-between" flexDirection={isSmallDevice ? 'column' : 'row'}>
        <Box display="flex" alignItems="center">
          {isSmallDevice ? null : <AccountCircleIcon color="primary" className={classes.iconMargin}/>}
          <Typography component="h6" variant="body1" color="primary">
            {userRegistrations.total === 1
              ? <span><strong>{userRegistrations.total}</strong> User Invite Pending ({userRegistrations.userRegistrations[0].email})</span>
              : <span><strong>{userRegistrations.total}</strong> User Invites Pending (Most Recent: {userRegistrations.userRegistrations[0].email})</span>
            }
          </Typography>
        </Box>
        <Link align={isSmallDevice ? 'center' : 'inherit'} variant="body1" component={RouterLink} to="/users/invites"
          underline="always">
          {userRegistrations.total === 1
            ? <span>View Invite</span>
            : <span>View Invites</span>
          }
        </Link>
      </Box>
    </Paper>
  </Box>
  )
}

export default UserInvitePaperView
