import React from 'react'
import { makeStyles } from '@material-ui/styles'
import imageMap from '../assets/ingredients'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  disabledPaper: {
    backgroundColor: theme.palette.grey[50],
  },
  ingredient: {
    cursor: 'pointer',
    marginBottom: theme.spacing(3),
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(20),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(28),
    }
  },
  imageFrame: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlayLabel: {
    position: 'absolute',
    zIndex: 1,
    padding: theme.spacing(0.5, 2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    border: `1px solid ${theme.palette.error.dark}`,
    color: theme.palette.error.dark,
    textTransform: 'uppercase',
    fontStyle: 'normal',
    maxWidth: '100%',
  },
  image: {
    width: theme.spacing(12),
    height: theme.spacing(12),

    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  },
  containerText: {
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    }
  },
  subdued: {
    filter: 'grayscale(25%) brightness(1.25) opacity(0.8)',
  },
}))

function OverlaySpan(props) {
  return <span style={{ color: (props.color || 'black'), display: 'flex', justifyContent: 'center' }}>{props.children}</span>
}

const isContainerExpired = (container, now = Date.now()) => {
  const punctureExpireOn = (container.punctureExpireOn instanceof Date) ? container.punctureExpireOn : Date.parse(container.punctureExpireOn)
  const shelfExpireOn = (container.shelfExpireOn instanceof Date) ? container.shelfExpireOn : Date.parse(container.shelfExpireOn)
  return (now > shelfExpireOn || now > punctureExpireOn)
}

// eslint-disable-next-line complexity,max-statements
function ContainerInfo({ position, isEnabled, container, ingredient, onClick, additionalContent, overrideStyles = undefined }) {

  const LOW_PERCENT = 15

  const classes = useStyles()

  const remainingPercent = container ? container.remainingPercent : null

  const mfgSerialNumber = container ? container.mfgSerialNumber : null

  const isSmallScreen = false

  const isExpired = isContainerExpired(container)

  const percentageColor = remainingPercent > LOW_PERCENT ? null : (remainingPercent <= 0 ? 'red' : 'orange')

  const percentageStyles = { color: percentageColor }
  const computeImageUrl = () => {
    return imageMap[ingredient.id]
  }

  return (<Box className={overrideStyles?.ingredient || classes.ingredient} onClick={onClick}>
    { position && <Typography noWrap={false} align="center" variant="h6" color={isEnabled ? 'primary' : 'textSecondary'}>
      <span className={overrideStyles?.containerText || classes.containerText} style={percentageStyles}>{position}</span>
    </Typography> }
    {
      container ? (
        <>
          <Typography noWrap={true} align="center" variant="h6" color={isEnabled ? 'primary' : 'textSecondary'}>
            <span className={overrideStyles?.containerText || classes.containerText} style={percentageStyles}>{ingredient.name} {isSmallScreen && <br/>}</span>
          </Typography>
          <div className={classes.imageFrame}>
            {(isExpired || remainingPercent <= LOW_PERCENT) &&
              <div className={classes.overlayLabel}>
                {isExpired && <OverlaySpan>Expired</OverlaySpan>}
                {remainingPercent < LOW_PERCENT && remainingPercent > 0 && <OverlaySpan color={percentageColor}>LOW</OverlaySpan>}
                {remainingPercent <= 0 && <OverlaySpan color="red">EMPTY</OverlaySpan>}
              </div>
            }
            <img src={computeImageUrl()}
              className={`${overrideStyles?.image || classes.image}${(isExpired || remainingPercent <= LOW_PERCENT) ? ` ${classes.subdued}` : ''}`}
              alt="Ingredient"/>
          </div>
          <Typography align="center" variant="h6" color={isEnabled ? 'primary' : 'textSecondary'}>
            <span className={overrideStyles?.containerText || classes.containerText} style={percentageStyles}>{mfgSerialNumber}</span>
          </Typography>
          {additionalContent}
        </>) : (<Typography noWrap={true} align="center" variant="h6" color={isEnabled ? 'primary' : 'textSecondary'}>
        <span className={overrideStyles?.containerText || classes.containerText} style={percentageStyles}>
          Empty
        </span>
      </Typography>)
    }
  </Box>
  )

}

export default ContainerInfo
