import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useMediaQuery, useTheme } from '@material-ui/core'

import AsyncSwitch from '../utils/AsyncSwitch'
import DateDisplay from '../format/DateDisplay'

const useStyles = makeStyles(() => ({
  noWrap: {
    whiteSpace: 'nowrap'
  }
}))

function EnabledSwitch(props) {
  return (
    <AsyncSwitch
      action={(updatedValue) =>
        props
          .updateKioskWaterFilterEnabled(updatedValue)
          .then(({ updateKioskWaterFilterEnabled: { enabled } }) => enabled)
      }
      initialChecked={props.enabled}
    />
  )
}

function KioskPositionsStillTable(props) {
  const classes = useStyles()

  const theme = useTheme()

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Table size="small">
      <colgroup>
        <col style={{ width: '25%' }} />
        <col style={{ width: '25%' }} />
        <col style={{ width: '25%' }} />
        { isSmallDevice ? null : <col style={{ width: '25%' }} /> }
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Filter Install Date</TableCell>
          <TableCell>Filter Replacement Date</TableCell>
          { isSmallDevice ? null : <TableCell align="center">Enabled</TableCell> }
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={classes.noWrap}>Still Water</TableCell>
          <TableCell className={classes.noWrap}>
            <DateDisplay dt={props.tableData.installedOn} />
          </TableCell>
          <TableCell className={classes.noWrap}>
            <DateDisplay dt={props.tableData.expireOn} />
          </TableCell>
          {isSmallDevice ? null
            : <TableCell align="center">
              {props.editable ? (
                <EnabledSwitch
                  updateKioskWaterFilterEnabled={
                    props.updateKioskWaterFilterEnabled
                  }
                  kioskId={props.kioskId}
                  enabled={props.tableData.enabled}
                />
              ) : props.tableData.enabled ? (
                'Yes'
              ) : (
                'No'
              )}
            </TableCell>
          }
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default KioskPositionsStillTable
