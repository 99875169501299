import React from 'react'
import { useAsync } from 'react-async-hook'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

import { usePaginatedTable } from '../components/PaginatedTable'
import { graphQLFetch, PageView } from '@myx/console-utils'
import UserTable from '../components/tables/UserTable'

import ProgressWrapper from '../components/ProgressWrapper'
import { usePermissions } from '../permissions'
import UserInvitePaperView from '../components/UserInvitePaperView'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  iconMargin: {
    marginRight: theme.spacing(1),
  }
}))

// eslint-disable-next-line max-statements
function Users({ match }) {
  const classes = useStyles()
  const perms = usePermissions()

  const userHasAccess = perms.userCanAccessUsers()

  const userRegDataAsync = useAsync(() => {

    // skip execution if user doesn't have permissions
    if (!userHasAccess) {
      return new Promise(() => null)
    }

    // return Promise.resolve({ total: 0, userRegistrations: [] })

    return graphQLFetch(`
      query {
        getUserRegistrations(map: [{key: "orderByDesc", value: "createdOn"}], limit: 1, offset: 0) {
          total
          userRegistrations{
            id
            email
            createdOn
            changedOn
          }
        }
      }
    `, {}).then(({
      getUserRegistrations: { total, userRegistrations },
    }) => (
      {
        total,
        userRegistrations
      }
    ))
  }, [], {
    // preserve previously loaded data while new data is being fetched
    setLoading: state => ({ ...state, loading: true })
  })

  const tableState = usePaginatedTable({
    match,
    filterSlugs: {},
    defaultSortColumn: 'familyName',
    defaultPageSize: 10
  })

  if (!userHasAccess) {
    return <PageView url={match.url} title="Not Authorized">Not Authorized</PageView>
  }

  const userRegistrations = userRegDataAsync.result

  return (
    <PageView url={match.url} title="Users">

      <ProgressWrapper pending={userRegDataAsync.loading || !userRegDataAsync.result}>
        {(userRegistrations && userRegistrations.total > 0) &&
          <UserInvitePaperView userRegistrations={userRegistrations}/>
        }

        {/* wait for user registration spinner to roll before showing table (which has its own spinner) */}
        {userRegistrations &&
          <Paper className={classes.paper}>
            <UserTable
              tableState={tableState}
              onUserAdded={() => userRegDataAsync.execute()}
            />
          </Paper>
        }
      </ProgressWrapper>

    </PageView>
  )
}

export default Users
