import React from 'react'
import { useAsync } from 'react-async-hook'

import Grid from '@material-ui/core/Grid'

import { graphQLFetch } from '@myx/console-utils'
import ProgressWrapper from '../components/ProgressWrapper'
import ContainerInfoCard from '../components/cards/ContainerInfoCard'
import ContainerLocationCard from '../components/cards/ContainerLocationCard'
import ContainerDetailsCard from '../components/cards/ContainerDetailsCard'
import ContainerDatesCard from '../components/cards/ContainerDatesCard'

function ContainerOverviewPanel(props) {

  const containerDataAsync = useAsync(() => {
    return graphQLFetch(`
      query($id: String!) {

        getIngredientContainers(
          map: [{key: "eq:id", value: $id}],
          limit: 5,
          offset: 0
        ) {
        total
        ingredientContainers {
            id
            ingredientId
            companyId
            bufferVolume
            containerCOGS
            mfgSerialNumber
            manufactureLotCode
            changedBy
            remainingPercent
            productionOn
            punctureOn
            purchasedOn
            shippedOn
            shelfExpireOn
            punctureExpireOn
            soonestExpireOn
            lowOn
            outOn
            warnSentOn
            outSentOn
            changedOn
            kioskPositionDetail {
              position
              kioskDetail {
                id
                name
                assetCode
                serialDetail {
                  serialNumber
                }
              }
            }
            ingredientDetail {
                id
                name
                ingredientTypeId
                unitSize
                fat
                sodium
                sugars
                iron
                calcium
            }
          }
        }
          getContainerDailyUsePercent(containerId: $id)
      }
    `, {
      id: props.containerId,
    }).then(({
      getIngredientContainers: { ingredientContainers: data },
      getContainerDailyUsePercent,
    }) => (
      {
        ...data[0],
        dailyPercent: getContainerDailyUsePercent
      }
    ))
  }, [], {
    // preserve previously loaded data while new data is being fetched
    setLoading: state => ({ ...state, loading: true })
  })

  const container = containerDataAsync.result

  return (
    <ProgressWrapper pending={containerDataAsync.loading || !containerDataAsync.result}>
      {container && <Grid container spacing={2}>

        <Grid item xs={12} md={6}>
          <ContainerInfoCard container={container}/>
        </Grid>

        <Grid item xs={12} md={6}>
          <ContainerLocationCard container={container}/>
        </Grid>

        <Grid item xs={12} md={12}>
          <ContainerDetailsCard container={container}/>
        </Grid>

        <Grid item xs={12} md={12}>
          <ContainerDatesCard container={container}/>
        </Grid>

      </Grid>}
    </ProgressWrapper>
  )
}

export default ContainerOverviewPanel
