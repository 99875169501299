import React from 'react'
import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core'
import imageMap from '../../assets/ingredients'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import ItemCounter from '../ItemCounter'

const useStyles = makeStyles(theme => ({
  ingredient: {
    cursor: 'pointer',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
  },
  imageFrame: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlayLabel: {
    position: 'absolute',
    zIndex: 1,
    padding: theme.spacing(0.5, 2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    border: '1px solid black',
    color: theme.palette.error.dark,
    textTransform: 'uppercase',
    fontStyle: 'normal',
  },
  image: {
    width: theme.spacing(8),
    height: theme.spacing(8),

    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },
  subdued: {
    filter: 'grayscale(25%) brightness(1.25) opacity(0.8)',
  },
  imagePlaceholder: {
    minWidth: theme.spacing(8),
    minHeight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },
}))

const getIngredientRecommendation = (ingId, recommendedIngredients) => {
  const recommended = recommendedIngredients.find((ing) => Number.parseFloat(ing.ingredientId) === Number.parseFloat(ingId))
  return <Typography align="center" variant="body1">
    {recommended && recommended.quantity && recommended.quantity > 0 ? <>MYX Recommends {recommended.quantity}X</> : <>Add One To Try</>}
  </Typography>
}
const MenuCount = ({ count }) => <div style={{ textAlign: 'center' }}>
  <Typography variant="h6" align="center">{count}</Typography>
</div>

function IngredientMenuCard({ ingredientMenu, ingredientCart, readOnly, updateIngredientItem, recommendedIngredients, gridProps }) {

  const classes = useStyles()

  const computeImageUrl = (ingredientId) => {
    return imageMap[ingredientId]
  }

  const gridItemsProps = gridProps || { xs: 12, md: 12, lg: 3 }

  return (<Card>
    <CardHeader
      title="Ingredient Menu"
    >
    </CardHeader>
    <CardContent>
      <Grid container spacing={1}>
        {ingredientCart.map(ing => <Grid item {...gridItemsProps}>
          <Box className={classes.ingredient}>
            <Typography align="center" style={gridItemsProps.xs <= 4 ? { fontSize: '12px' } : {}} variant="h6">{ingredientMenu[ing.ingredientId]}</Typography>
            <div className={classes.imageFrame}>
              <img src={computeImageUrl(ing.ingredientId)}
                className={classes.image}
                alt="Ingredient"/>
            </div>
            { readOnly ? <MenuCount count={ing.quantity}/> : <><ItemCounter
              count={ing.quantity}
              setCount={(count) => updateIngredientItem(ing.ingredientId, count || 0)}
            />
            {getIngredientRecommendation(ing.ingredientId, recommendedIngredients)}
            </> }
          </Box>
        </Grid>)
        }
      </Grid>
    </CardContent>
  </Card>)
}

export default IngredientMenuCard
