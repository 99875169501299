import React from 'react'
/* eslint-disable no-tabs */
export const MyxLogoSVG = ({
  height = '336.7px',
  width = '237.2px',
  color = 'white',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    id="Layer_1"
    // xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 237.2 336.7"
    // style={{enableBackground:"new 0 0 237.2 336.7;"}}
    // xml:space="preserve"
  >
    {/* <style type="text/css">.st0{fill:#FFFFFF;}*/}
    {/* </style>*/}
    <path className="st0" d="M118.6,21c0,0-98.9,91.1-98.9,181.7c0,62.4,36.5,112.9,98.9,112.9c62.4,0,98.9-50.6,98.9-112.9
	C217.5,112.1,118.6,21,118.6,21 M118.6,250.3c-44.4,0-70.4-36-70.4-80.4c0-64.5,70.4-129.4,70.4-129.4S189,105.3,189,169.8
	C189,214.3,163,250.3,118.6,250.3"/>
    <path className="st0" d="M160.5,137c0,26.4-15.5,47.9-41.9,47.9c-26.4,0-41.9-21.4-41.9-47.9c0-38.4,41.9-77,41.9-77
	S160.5,98.6,160.5,137"/>
  </svg>

)
