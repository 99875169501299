import React from 'react'

import Box from '@material-ui/core/Box'
import AppFormTextField from '../form/AppFormTextField'
import AppFormPhoneCountryField from '../form/AppFormPhoneCountryField'

function UserInfoFormDialog() {
  return (
    <>
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Box mr={1} flex={1}>
            <AppFormTextField
              name="givenName"
              required
              label="First Name"
            />
          </Box>
          <Box ml={1} flex={1}>
            <AppFormTextField
              name="familyName"
              required
              label="Last Name"
            />
          </Box>
        </Box>
        <AppFormTextField
          name="email"
          type="email"
          required
          label="Email Address"
          autoFocus
        />
        <Box display="flex">
          <Box mr={1} flex={1} style={{ maxWidth: '30%' }}>
            <AppFormPhoneCountryField name="phoneCountry" label="Country Code" required />
          </Box>
          <Box mr={1} ml={1} flex={1}>
            <AppFormTextField
              name="phone"
              required
              label="Phone Number"
              type="text" // @TODO: react-text-mask - https://next.material-ui.com/components/text-fields/
            />
          </Box>
          <Box ml={1} flex={1} style={{ maxWidth: '20%' }}>
            <AppFormTextField
              name="phoneExtension"
              label="Extension"
              validateWholeNumber
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default UserInfoFormDialog
