/* eslint-disable max-lines */
import React from 'react' // { useRef }
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'

import { graphQLFetch, useAppModal, UserAddFormDialog } from '@myx/console-utils'
import UserInfoFormDialog from '../dialogs/UserInfoFormDialog'
import UserAlertsFormDialog from '../dialogs/UserAlertsFormDialog'
import UserRolesFormDialog from '../dialogs/UserRolesFormDialog'
import DataTable, { DataTableRowActions, DataTableSortLabel } from '../DataTable'
import PhoneDisplay from '../format/PhoneDisplay'
import { useMediaQuery, useTheme } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  noWrap: {
    whiteSpace: 'nowrap',
  },
  userRoles: {
    whiteSpace: 'nowrap',
    cursor: 'default',
  },
  breakWord: {
    wordWrap: 'break-word'
  },
  tableActions: {
    marginTop: theme.spacing(2),
  }
}))

function UserCreateButton({ onComplete }) {
  const openDialog = useAppModal(
    {
      title: 'Add User',
      resultMessage: 'Email verification sent',
      initialValues: {
        email: '',
        givenName: '',
        familyName: '',
        phone: '',
        phoneCountry: '1',
        phoneExtension: '',
        inventoryAlert: false,
        serviceAlert: false,
        alertWithEmail: false,
        alertWithSms: false,
        roles: [],
      },
      action: (formData) => graphQLFetch(
        `
          mutation ($userInfo: AddUserRegistrationInput!) {
            addUserRegistration(add: $userInfo) {
              email
            }
          }
        `,
        {
          userInfo: {
            email: formData.email,
            givenName: formData.givenName,
            familyName: formData.familyName,
            phone: formData.phone,
            phoneCountry: formData.phoneCountry,
            phoneExtension: formData.phoneExtension,
            roles: formData.roles,
            inventoryAlert: formData.inventoryAlert,
            serviceAlert: formData.serviceAlert,
            alertWithEmail: formData.alertWithEmail,
            alertWithSms: formData.alertWithSms,
          }
        }
      ),
      // use a fake form field to track stepsComplete state
      actionDisabled: (form) => (!form.values.stepsComplete),
      onComplete
    },
    <UserAddFormDialog/>
  )

  return (
    <Button color="primary" onClick={openDialog}>
      Add User
    </Button>
  )
}

function UserRowActions({ user, onComplete }) {
  const openEditInfoDialog = useAppModal(
    {
      title: 'Edit User Information',
      initialValues: {
        email: user.email,
        givenName: user.givenName,
        familyName: user.familyName,
        phoneCountry: user.phoneCountry || '',
        phone: user.phone,
        phoneExtension: user.phoneExtension || '',
      },
      action: (formData) => graphQLFetch(
        `
          mutation updateUserInfo($userId: String!, $userData: UpdateUserInput!) {
            updateUser(userId: $userId, update: $userData) {
              id
            }
          }
        `,
        {
          userId: user.id,
          userData: {
            email: formData.email,
            givenName: formData.givenName,
            familyName: formData.familyName,
            phoneCountry: formData.phoneCountry || null,
            phone: formData.phone,
            phoneExtension: formData.phoneExtension || null
          },
        }
      ),
      onComplete
    },
    <UserInfoFormDialog/>
  )

  const openEditAlertsDialog = useAppModal(
    {
      title: 'Edit User Alerts',
      initialValues: {
        inventoryAlert: user.inventoryAlert,
        serviceAlert: user.serviceAlert,
        alertWithEmail: user.alertWithEmail,
        alertWithSms: user.alertWithSms,
      },
      action: (formData) => graphQLFetch(
        `
          mutation updateUserInfo($userId: String!, $alertData: UpdateUserAlertInput!) {
            updateUserAlert(userId: $userId, update: $alertData) {
              id
            }
          }
        `,
        {
          userId: user.id,
          alertData: {
            serviceAlert: formData.serviceAlert,
            inventoryAlert: formData.inventoryAlert,
            alertWithEmail: formData.alertWithEmail,
            alertWithSms: formData.alertWithSms,
          }
        }
      ),
      onComplete
    },
    <UserAlertsFormDialog/>
  )

  const openEditRolesDialog = useAppModal(
    {
      title: 'Edit User Roles',
      initialValues: {
        roles: user.roleDetails.map(role => role.roleCode), // e.g. ['servicetech', 'operator']
      },
      action: (formData) => graphQLFetch(
        `
          mutation updateUserInfo($userId: String!, $roleData: [OperatorRole!]!) {
            setUserRole(userId: $userId, set: { roles: $roleData }) {
              userId
            }
          }
        `,
        {
          userId: user.id,
          roleData: [ ...formData.roles ]
        }
      ),
      onComplete
    },
    <UserRolesFormDialog/>
  )

  const openDeleteDialog = useAppModal(
    {
      title: 'Delete User',
      action: () => graphQLFetch(
        `
          mutation removeUser($userId: String!) {
            removeUser(userId: $userId) {
              id
            }
          }
        `,
        {
          userId: user.id
        }
      ),
      onComplete,
      actionLabel: 'Delete',
      resultMessage: 'Deleted successfully'
    },
    <Typography variant="body1">Really delete user?</Typography>
  )

  return (
    <DataTableRowActions
      onEditUserClick={openEditInfoDialog}
      onEditAlertsClick={openEditAlertsDialog}
      onEditSecurityClick={openEditRolesDialog}
      onDeleteClick={openDeleteDialog}
    />
  )
}

function UserRolesDisplay({ roles }) {
  const classes = useStyles()

  // output roles in order of privilege
  const roleCodes = roles.map(role => role.roleCode)

  return (
    <span className={classes.userRoles}>
      {roleCodes.includes('operator') ? <Tooltip title="Operator"><span>O</span></Tooltip> : ''}
      {roleCodes.length > 1 ? ', ' : ''}
      {roleCodes.includes('servicetech') ? <Tooltip title="Service Tech"><span>S</span></Tooltip> : ''}
      {roleCodes.length > 2 ? ', ' : ''}
      {roleCodes.includes('fulfillment') ? <Tooltip title="Fulfillment"><span>F</span></Tooltip> : ''}
    </span>
  )
}

function UserTable(props) {
  const classes = useStyles()

  const tableState = props.tableState

  const theme = useTheme()
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <>
      <DataTable
        variant={isSmallDevice ? 'mobile' : null}
        tableState={tableState}
        dataSource={() =>
          graphQLFetch(`
            query($sortKey: String!, $sortValue: String!, $limit: Long!, $offset: Long!) {
              getUsers(
                map: [ { key: $sortKey, value: $sortValue } ],
                limit: $limit,
                offset: $offset
              ) {
                total
                users {
                  id
                  email
                  givenName
                  familyName
                  phoneCountry
                  phone
                  phoneExtension
                  inventoryAlert
                  serviceAlert
                  alertWithEmail
                  alertWithSms
                  roleDetails {
                    roleCode
                  }
                }
              }
            }
          `, {
            limit: tableState.pageSize,
            offset: tableState.pageIndex * tableState.pageSize,
            sortKey: tableState.sortReverse ? 'orderByDesc' : 'orderBy',
            sortValue: tableState.sortColumn
          }).then(({ getUsers: { total, users: data } }) =>
            ({ total, data })
          )
        }
      >
        {(data, subMatch, refresh) => <>
          {isSmallDevice ? <colgroup>
            <col style={{ width: '75%' }}/>
            <col style={{ width: '25%' }}/>
          </colgroup>
            : <colgroup>
              <col style={{ width: '30%' }}/>
              <col style={{ width: '20%' }}/>
              <col style={{ width: '20%' }}/>
              <col style={{ width: '20%' }}/>
              <col style={{ width: '10%' }}/>
              <col style={{ width: '20%' }}/>
            </colgroup>
          }
          <TableHead>
            <TableRow>
              <TableCell>
                <DataTableSortLabel column="email">
                  Email
                </DataTableSortLabel>
              </TableCell>
              {isSmallDevice ? null
                : <>
                  <TableCell>
                    <DataTableSortLabel column="givenName">
                      First
                    </DataTableSortLabel>
                  </TableCell>
                  <TableCell>
                    <DataTableSortLabel column="familyName">
                      Last
                    </DataTableSortLabel>
                  </TableCell>
                  <TableCell>
                    <DataTableSortLabel column="phone">
                      Phone
                    </DataTableSortLabel>
                  </TableCell>
                </>
              }
              <TableCell>Role(s)</TableCell>
              {isSmallDevice ? null : <TableCell align="right">&nbsp;</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(user => (
              <TableRow key={user.id}>
                <TableCell className={classes.noWrap}>
                  {user.email ? ('' + user.email).trim().toLowerCase() : '—'}
                  {/* <EmailDisplay email={user.email} /> */}
                </TableCell>
                {isSmallDevice ? null
                  : <>
                    <TableCell>{user.givenName || '—'}</TableCell>
                    <TableCell>{user.familyName || '—'}</TableCell>
                    <TableCell className={classes.noWrap}>
                      <PhoneDisplay
                        country={user.phoneCountry}
                        phone={user.phone}
                        extension={user.phoneExtension}
                      />
                    </TableCell>
                  </>
                }
                <TableCell><UserRolesDisplay roles={user.roleDetails}/></TableCell>
                {isSmallDevice ? null
                  : <TableCell>
                    <UserRowActions user={user} onComplete={refresh}/>
                  </TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </>}
      </DataTable>
      <div className={classes.tableActions}>
        <UserCreateButton onComplete={props.onUserAdded}/>
      </div>
    </>
  )
}

export default UserTable
