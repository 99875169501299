import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { green, red } from '@material-ui/core/colors'

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Warning'

import DateDisplay from '../format/DateDisplay'

const useStyles = makeStyles((theme) => ({
  connected: {
    color: green[800],
  },
  offline: {
    color: red[800],
  },
}))

function KioskConnectivityCard({ kiosk }) {
  const classes = useStyles()

  return (
    <Card>
      <CardHeader title="Connectivity" />
      <CardContent>
        <Table size="medium">
          <colgroup>
            <col style={{ width: '50%' }}/>
            <col style={{ width: '50%' }}/>
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Cloud Status</TableCell>
              <TableCell>
                <Box display="flex" alignItems="center">
                  {(kiosk.onlineDetail && kiosk.onlineDetail.online)
                    ? <><span>CONNECTED&nbsp;</span><CheckIcon className={classes.connected} fontSize="small" /></>
                    : <><span>OFFLINE&nbsp;</span><WarningIcon className={classes.offline} fontSize="small" /></>
                  }
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Since</TableCell>
              <TableCell><DateDisplay dt={kiosk.onlineDetail && kiosk.onlineDetail.changedOn} /></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )

}

export default KioskConnectivityCard
