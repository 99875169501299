import React from 'react'

import AppFormCheckboxFieldGroup from '../form/AppFormCheckboxFieldGroup'

function MyAccountAlertsDialog() {
  return (
    <>
      <AppFormCheckboxFieldGroup
        label="Subscriptions"
        options={{
          serviceAlert: 'Service Alerts',
          inventoryAlert: 'Inventory Alerts',
        }}
      />
      <AppFormCheckboxFieldGroup
        label="Delivery Preference"
        options={{
          alertWithEmail: 'Email',
          // alertWithSms: 'SMS',
        }}
      />
    </>
  )
}

export default MyAccountAlertsDialog
