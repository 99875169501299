import React from 'react'
import { PageView } from '@myx/console-utils'
import ScanContainersView from './ScanContainersView'

type Props = {
  match: any,
}

function ScanContainersController({ match }: Props) {

  return (<PageView url={match.url} title="Scan Container">
    <ScanContainersView initialScannedState={null}/>
  </PageView>)
}

export default ScanContainersController
