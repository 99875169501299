import React from 'react'
import Box from '@material-ui/core/Box'
import AppFormTextField from '../form/AppFormTextField'
import { Typography } from '@material-ui/core'

function EmailTextDialog({ primaryText }) {
  return (
    <>
      { primaryText && <Typography align="center" component="h6" variant="h6">{primaryText}</Typography> }
      <Box display="flex">
        <Box mr={1} flex={1}>
          <AppFormTextField
            name="email"
            requred
            type="email"
            label="Email Address"
          />
        </Box>
      </Box>
    </>)
}

export default EmailTextDialog
