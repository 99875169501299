import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  loader: {
    marginLeft: theme.spacing(2),
  },
}))

function PageHeading(props) {
  const classes = useStyles()

  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      <Typography variant="h4" component="h1" color="primary">
        {props.children}
      </Typography>

      {props.isPending && <CircularProgress size={28} className={classes.loader} />}
    </Box>
  )

}

export default PageHeading
