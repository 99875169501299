import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import KioskPositionsTable from '../tables/KioskPositionsTable'
import KioskPositionsStillTable from '../tables/KioskPositionsStillTable'
import KioskPositionsSparklingTable from '../tables/KioskPositionsSparklingTable'
import TimezoneDisplay from '../format/TimezoneDisplay'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
      4
    )}px`,
    overflow: 'auto'
  }
}))

// eslint-disable-next-line prefer-promise-reject-errors
const defaultReject = () => Promise.reject('Function required..')

const defaultActions = {
  updatePositionsEnabled: defaultReject,
  updateCo2Enabled: defaultReject,
  updateKioskWaterFilterEnabled: defaultReject
}

function KioskPositionsPanelView({
  kioskId,
  perms,
  positionData,
  updateActions = defaultActions
}) {
  const classes = useStyles()

  const editable = perms.userCanEditKiosks()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Container Positions
          </Typography>
          <KioskPositionsTable
            updatePositionsEnabled={updateActions.updatePositionsEnabled}
            editable={editable}
            kioskId={kioskId}
            tableData={positionData}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Still Water
          </Typography>
          <KioskPositionsStillTable
            updateKioskWaterFilterEnabled={
              updateActions.updateKioskWaterFilterEnabled
            }
            editable={editable}
            kioskId={kioskId}
            tableData={positionData.waterFilterDetail}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Sparkling Water
          </Typography>
          <KioskPositionsSparklingTable
            updateCo2Enabled={updateActions.updateCo2Enabled}
            editable={editable}
            kioskId={kioskId}
            tableData={{
              id: positionData?.co2Detail?.id,
              enabled: positionData?.co2Detail?.enabled,
              psi: positionData?.getKioskCo2LatestReading?.psi,
              lastReportedOn: positionData?.getKioskCo2LatestReading?.time
            }}
          />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Box pl={2}>
          Timezone: <TimezoneDisplay />
        </Box>
      </Grid>
    </Grid>
  )
}

export default KioskPositionsPanelView
