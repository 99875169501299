import React from 'react'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import DateDisplay from '../format/DateDisplay'
import KioskPositionEnabledSwitch from './KioskPositionEnabledSwitch'
import { UpdateKioskCo2Enabled } from '../../@types/UpdateKioskCo2Enabled'

const useStyles = makeStyles(() => ({
  noWrap: {
    whiteSpace: 'nowrap'
  }
}))

type Props = {
  tableData: any,
  editable: boolean,
  updateCo2Enabled: (state: boolean) => Promise<UpdateKioskCo2Enabled>;
}

function KioskPositionsSparklingTable({ tableData, editable, updateCo2Enabled }: Props) {
  const classes = useStyles()

  const theme = useTheme()

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Table size="small">
      <colgroup>
        <col style={{ width: '25%' }}/>
        <col style={{ width: '25%' }}/>
        <col style={{ width: '25%' }}/>
        {isSmallDevice ? null : <col style={{ width: '25%' }}/>}
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>CO2 Pressure</TableCell>
          <TableCell>Last Reading Date</TableCell>
          {isSmallDevice ? null : <TableCell align="center">Enabled</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={classes.noWrap}>Sparkling Water</TableCell>
          <TableCell className={classes.noWrap}>{tableData.psi} psi</TableCell>
          <TableCell className={isSmallDevice ? '' : classes.noWrap}>
            <DateDisplay dt={tableData.lastReportedOn} displayTime/>
          </TableCell>
          {isSmallDevice
            ? null
            : <TableCell align="center">
              {editable
                ? <KioskPositionEnabledSwitch
                  updateCo2Enabled={updateCo2Enabled}
                  isEnabled={tableData.enabled}/>
                : tableData.enabled
                  ? 'Yes'
                  : 'No'
              }
            </TableCell>
          }
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default KioskPositionsSparklingTable
