import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import { graphQLFetch, useAppModal } from '@myx/console-utils'
import OperatorAddressDialog from '../dialogs/OperatorAddressDialog'
import AddressDisplay from '../format/AddressDisplay'

const useStyles = makeStyles(theme => ({
  iconTableCell: {
    padding: `calc(${theme.spacing(1)}px + 3px) ${theme.spacing(2)}px`
  }
}))

function SettingsAddressesCard({ companyName, billingAddress, shippingAddress, onComplete }) {
  const classes = useStyles()

  const openBillingAddressEditDialog = useAppModal(
    {
      title: 'Edit Billing Address',
      resultMessage: 'Billing address saved successfully',
      initialValues: {
        // handle possible null address
        address1: '',
        address2: '',
        address3: '',
        city: '',
        state: '',
        zip: '',
        country: 'USA',
        ...billingAddress
      },
      action: (formData) => graphQLFetch(
        `
          mutation ($data: AddressInput!) {
            updateCompanyBillingAddress(update: $data) {
              id
            }
          }
        `,
        {
          data: {
            address1: formData.address1,
            address2: formData.address2,
            address3: formData.address3,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            country: formData.country,
          }
        }
      ),
      onComplete
    },
    <OperatorAddressDialog/>
  )

  const openShippingAddressEditDialog = useAppModal(
    {
      title: 'Edit Shipping Address',
      resultMessage: 'Shipping address saved successfully',
      initialValues: {
        // handle possible null address
        address1: '',
        address2: '',
        address3: '',
        city: '',
        state: '',
        zip: '',
        country: 'USA',
        ...shippingAddress
      },
      action: (formData) => graphQLFetch(
        `
          mutation ($data: AddressInput!) {
            updateCompanyShippingAddress(update: $data) {
              id
            }
          }
        `,
        {
          data: {
            address1: formData.address1,
            address2: formData.address2,
            address3: formData.address3,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            country: formData.country,
          }
        }
      ),
      onComplete
    },
    <OperatorAddressDialog/>
  )

  return (
    <Card>
      <CardHeader
        title="Addresses"
      />
      <CardContent>
        <Table size="medium">
          <colgroup>
            <col style={{ width: '25%' }}/>
            <col style={{ width: '75%' }}/>
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell className={classes.iconTableCell}>Billing Address</TableCell>
              <TableCell className={classes.iconTableCell}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <div>
                    {(billingAddress && billingAddress.address1)
                      ? (
                        <>
                          {companyName && (
                            <span>{companyName}<br/></span>
                          )}
                          <AddressDisplay address={billingAddress}/>
                        </>
                      )
                      : <em>Please add a billing address</em>
                    }
                  </div>
                  <Box>
                    <IconButton
                      aria-label="settings"
                      size="small"
                      onClick={openBillingAddressEditDialog}
                    >
                      <EditIcon/>
                    </IconButton>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.iconTableCell}>Shipping Address</TableCell>
              <TableCell className={classes.iconTableCell}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <div>
                    {(shippingAddress && shippingAddress.address1)
                      ? (
                        <>
                          {companyName && (
                            <span>{companyName}<br/></span>
                          )}
                          <AddressDisplay address={shippingAddress}/>
                        </>
                      )
                      : <em>Please add a shipping address</em>
                    }
                  </div>
                  <Box>
                    <IconButton
                      aria-label="settings"
                      size="small"
                      onClick={openShippingAddressEditDialog}
                    >
                      <EditIcon/>
                    </IconButton>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default SettingsAddressesCard
