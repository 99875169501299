import React from 'react'
// import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { PageView } from '@myx/console-utils'

function Error404(props) {
  return (
    <PageView url={props.match.url} title="Not Found">
      <Grid container spacing={3}>{/* // container prop on Grid */}

        <Grid item xs={12}>
          <Typography component="h2" variant="h6" gutterBottom>
            Sorry, the resource <strong>{props.location.pathname}</strong> was not found (Error 404).
          </Typography>
        </Grid>

      </Grid>
    </PageView>
  )

}

export default Error404
