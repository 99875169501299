import React from 'react'

import Box from '@material-ui/core/Box'
// import { makeStyles } from '@material-ui/core/styles'
import AppFormCheckboxFieldGroup from '../form/AppFormCheckboxFieldGroup'

function UserAlertsFormDialog() {
  // const classes = useStyles()

  return (
    <>
      <Box>
        <AppFormCheckboxFieldGroup
          label="Alert Notifications"
          options={{
            serviceAlert: 'Service Alerts',
            inventoryAlert: 'Inventory Alerts',
          }}
        />
        <AppFormCheckboxFieldGroup
          label="Alert Notification Preferences"
          options={{
            alertWithEmail: 'Email',
            // alertWithSms: 'SMS',
          }}
        />
      </Box>
    </>
  )
}

export default UserAlertsFormDialog
