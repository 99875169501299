import React from 'react'
import { useAsync } from 'react-async-hook'
import { graphQLFetch, PageView } from '@myx/console-utils'
import ProgressWrapper from '../components/ProgressWrapper'
import ChangePositionContainersCard from '../components/cards/ChangePositionContainersCard'
import { catchKioskConnectError, kioskApiFetch, useInterval } from '../touchlessKioskFetch'
import { useHistory } from 'react-router-dom'

export const CONNECT_REDIRECT_TO = '/changecontainer/connectionfailed'

const PING_INTERVAL_SECONDS = 25

function ChangeContainerAtKioskPage({ match }) {

  const token = decodeURIComponent(match.params.token)

  const kioskId = decodeURIComponent(match.params.id)

  const history = useHistory()

  async function ping() {
    try {
      await kioskApiFetch('/v1/ping', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'myx-session-token': token
        }
      })
    } catch (exc) {
      catchKioskConnectError(exc, history, CONNECT_REDIRECT_TO, false)
    }
  }

  useInterval(ping, PING_INTERVAL_SECONDS * 1000)

  const kioskPositionsAsync = useAsync(() => {
    return graphQLFetch(`
      query($id: String!) {
        getKiosks(
          map: [ { key: "eq:id", value: $id } ],
          limit: 1,
          offset: 0
        ) {
          total
          kiosks {
            id
            serialDetail {
              serialNumber
            }
          }
        }

        getKioskPositions(
          map: [ { key: "orderByDesc", value: "position" } ],
          limit: 1000,
          offset: 0,
          kioskId: $id
          ) {
            total
            kioskPositions {
              id
              kioskId
              position
              enabled
              ingredientContainerDetail {
                id
                mfgSerialNumber
                remainingPercent
                shelfExpireOn
                punctureExpireOn
                ingredientDetail {
                  id
                  name
                }
              }
            }
        }
      }
    `, {
      id: kioskId
    }).then(({ getKiosks, getKioskPositions }) => (
      {
        positions: getKioskPositions.kioskPositions,
        kiosk: getKiosks.kiosks[0]
      }
    ))
  }, [], {
    setLoading: state => ({ ...state, loading: true })
  })

  const serviceScreenAsync = useAsync(() => kioskApiFetch('/v1/consumer/service', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'myx-session-token': token,
    }
  }).catch((ex) => catchKioskConnectError(ex, history, CONNECT_REDIRECT_TO)).then(() =>
    kioskApiFetch('/v1/service/component/door/FRONT_DOOR/open', {
      method: 'POST',
      headers: {
        'myx-session-token': token,
      }
    })), [], {
    setLoading: state => ({ ...state, loading: true })
  })

  return (<PageView url={match.url} title={'Add/Replace Container'}>
    <ProgressWrapper pending={kioskPositionsAsync.loading || serviceScreenAsync.loading || !kioskPositionsAsync.result}>
      {
        (kioskPositionsAsync.result
          ? <ChangePositionContainersCard
            token={token}
            kiosk={kioskPositionsAsync.result.kiosk}
            positions={kioskPositionsAsync.result.positions}
            onComplete={() => kioskPositionsAsync.execute()}
          /> : null)
      }
    </ProgressWrapper>
  </PageView>)
}

export default ChangeContainerAtKioskPage
