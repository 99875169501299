import React from 'react'

import Grid from '@material-ui/core/Grid'

import KioskAssetCard from '../components/cards/KioskAssetCard'
import KioskConfigurationCard from '../components/cards/KioskConfigurationCard'
import KioskActivityCard from '../components/cards/KioskActivityCard'
import KioskConnectivityCard from '../components/cards/KioskConnectivityCard'
import KioskLocationCard from '../components/cards/KioskLocationCard'
import KioskSizesCard from '../components/cards/KioskSizesCard'
import KioskOneTimePinCard from '../components/cards/KioskOneTimePinCard'
import KioskUserCard from '../components/cards/KioskUserCard'
import KioskContactCard from '../components/cards/KioskContactCard'
import { graphQLFetch } from '@myx/console-utils'

const onContactAdd = (newContact) => graphQLFetch(`
  mutation($add: AddKioskUserInput!) {
    addKioskUser(add: $add) {
      id
    }
  }
`, { add: { kioskId: newContact.kioskId, email: newContact.email } })

const onContactDelete = (contact) => graphQLFetch(`
  mutation($kioskUserId: String!) {
    removeKioskUser(kioskUserId: $kioskUserId) {
      id
    }
  }
`, { kioskUserId: contact.kioskUserId })

function KioskOverviewPanel({
  kiosk, editable, onComplete, apiSpec = {
    onContactDelete: onContactDelete,
    onContactAdd: onContactAdd,
  }
}) {

  return (<Grid container spacing={2}>

    <Grid item xs={12} md={6}>
      <KioskAssetCard kiosk={kiosk} editable={editable} onComplete={onComplete}/>
    </Grid>

    <Grid item xs={12} md={6}>
      <KioskConfigurationCard kiosk={kiosk}/>
    </Grid>

    <Grid item xs={12} md={6}>
      <KioskConnectivityCard kiosk={kiosk}/>
    </Grid>

    <Grid item xs={12} md={6}>
      <KioskActivityCard kiosk={kiosk}/>
    </Grid>

    <Grid item xs={12} md={12}>
      <KioskLocationCard kiosk={kiosk} editable={editable} onComplete={onComplete}/>
    </Grid>

    <Grid item xs={12} md={12}>
      <KioskContactCard kiosk={kiosk} editable={editable} onComplete={onComplete}/>
    </Grid>

    <Grid item xs={12} md={12}>
      <KioskUserCard
        kioskId={kiosk.id}
        kioskUsers={kiosk.kioskUsers.map((kioskUser) => {
          return {
            kioskUserId: kioskUser.id,
            ...kioskUser.userDetail
          }
        })}
        editable={editable}
        onComplete={onComplete}
        onContactAdd={apiSpec.onContactAdd}
        onContactDelete={apiSpec.onContactDelete}/>
    </Grid>

    <Grid item xs={12} md={12}>
      <KioskSizesCard kiosk={kiosk} editable={editable} onComplete={onComplete}/>
    </Grid>

    <Grid item xs={12} md={12}>
      <KioskOneTimePinCard kiosk={kiosk}/>
    </Grid>
  </Grid>)
}

export default KioskOverviewPanel
