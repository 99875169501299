import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import FilterListIcon from '@material-ui/icons/FilterList'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    overflow: 'auto',
  },

  titleBar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: `${theme.spacing(2)}px`,
  },

  filterCaption: {
    marginLeft: theme.spacing(1),
  },

  spacer: {
    flex: '1 1 0',
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: `${theme.spacing(1)}px`,
  },
}))

function Panel({ title, hasFilter, children, onFilterClick }) {

  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <div className={classes.titleBar}>
        <Typography component="h2" variant="h6" color="primary">
          {title}
        </Typography>

        <div className={classes.spacer} />

        {hasFilter &&
          <Typography className={classes.filterCaption} variant="subtitle2" color="secondary">
            Filter Applied
          </Typography>
        }

        <div className={classes.actions}>
          {onFilterClick &&
            <IconButton color={hasFilter ? 'secondary' : 'primary'} size="small" onClick={onFilterClick}>
              <FilterListIcon />
            </IconButton>
          }
        </div>
      </div>

      {children}
    </Paper>
  )

}

export default Panel
