export default {
  palette: {
    chart: [
      '#ff0000', '#00ff00', '#0000ff',
      '#000000', '#00FFFF', '#FF00FF',
      '#808080', '#800000', '#808000',
      '#008000', '#000080', '#800080'
    ],
    toolbar: {
      main: '#52348c',
    },
    primary: {
      light: '#6B4FA1',
      main: '#52348c',
      primary: '#52348c',
      dark: '#3D2076',
      contrastText: '#fff',
    },
    secondary: {
      light: '#D460E4',
      main: '#c53bd9',
      dark: '#9D02B3',
      contrastText: '#000',
    },
  },
}

