import React from 'react'
import { DateTime } from 'luxon'

function DaysSinceDateDisplay({ dt }) {
  return (
    <span>{ Math.floor((Date.now() - DateTime.fromISO(dt, { zone: 'utc' }).toMillis()) / 86400000) }</span>
  )
}

export default DaysSinceDateDisplay
