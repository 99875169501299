import React, { useContext, useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'

const PageContext = React.createContext(null)

export function usePageContext() {
  const [ pageState, setPageState ] = useState(null)

  const providerComponent = useMemo(() => {
    return ({ children }) => (
      <PageContext.Provider value={setPageState}>
        {children}
      </PageContext.Provider>
    )
  }, [])

  return {
    PageContextProvider: providerComponent,

    ...(pageState || {
      url: '',
      title: '',
      titleIsPending: false,
      contents: null
    })
  }
}

export function PageView({ url, title, titleIsPending, children }) {
  const setRootPageState = useContext(PageContext)

  if (!setRootPageState) {
    throw new Error('expected to be inside page view context')
  }

  // set current page view contents on every change
  useEffect(() => {
    setRootPageState({
      url,
      title,
      titleIsPending,
      contents: children
    })
  }, [ setRootPageState, url, title, titleIsPending, children ])

  // on unmount only, clean up if still relevant
  useEffect(() => {
    return () => {
      setRootPageState((prevState) =>
        prevState && prevState.url === url
          ? null
          : prevState
      )
    }
  }, [ setRootPageState, url ])

  // nothing to render directly, since contents are hoisted up to root
  return null
}

PageView.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.node,
  titleIsPending: PropTypes.bool,
  children: PropTypes.node,
}
