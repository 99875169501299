import React from 'react'
import { useAsync } from 'react-async-hook'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'

import ProgressWrapper from '../components/ProgressWrapper'
import { usePermissions } from '../permissions'
import { graphQLFetch, PageView } from '@myx/console-utils'
import UserInvitesTable from '../components/tables/UserInvitesTable'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
}))

function UserInvites({ match }) {
  const classes = useStyles()
  const perms = usePermissions()

  const invitesDataAsync = useAsync(() => {
    return graphQLFetch(`
      query {
        getUserRegistrations(map: [{key: "orderByDesc", value: "createdOn"}], limit: 500, offset: 0) {
          total
          userRegistrations{
            id
            email
            createdOn
            changedOn
          }
        }
      }
    `, {}).then(({ getUserRegistrations: { userRegistrations: data } }) => (
      {
        data
      }
    ))
  }, [], {
    // preserve previously loaded data while new data is being fetched
    setLoading: state => ({ ...state, loading: true })
  })

  const pageTitle = 'Users > Invites'

  if (!perms.userCanAccessUsers()) {
    return <PageView url={match.url} title="Not Authorized">Not Authorized</PageView>
  }

  const tableData = invitesDataAsync.result

  return (
    <PageView url={match.url} title={pageTitle}>
      <ProgressWrapper pending={invitesDataAsync.loading || !invitesDataAsync.result}>
        {tableData && <>
          <Box mb={3}>
            <Typography variant="body1">The following users have been sent a verification link via email:</Typography>
          </Box>
          <Paper className={classes.paper}>
            <UserInvitesTable tableData={tableData.data} refresh={() => invitesDataAsync.execute()}/>
          </Paper>
        </>}
      </ProgressWrapper>
    </PageView>
  )
}

export default UserInvites
