import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'

import NavList from './NavList'

function NavDrawer(props) {

  const classes = makeStyles(theme => ({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: props.drawerWidth,
        flexShrink: 0,
      }
    },
    drawerPaper: {
      width: props.drawerWidth,
    },
    toolbarSpacer: {
      ...theme.mixins.toolbar,
      marginBottom: theme.spacing(2),
    },
  }))()

  return (
    <nav className={classes.drawer}>

      {/* mobile-only temporary drawer */}
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          open={props.menuDrawerOpen}
          onClose={props.onMenuDrawerClose}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // better open performance on mobile
          }}
        >
          <div className={classes.toolbarSpacer} />
          <NavList onHide={props.onMenuDrawerClose} />
        </Drawer>
      </Hidden>

      {/* desktop-only permanent drawer */}
      <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.toolbarSpacer} />
          <NavList />
        </Drawer>
      </Hidden>

    </nav>
  )

}

export default NavDrawer
