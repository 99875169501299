import Paper from '@material-ui/core/Paper'
import KioskTable from './components/tables/KioskTable'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { usePaginatedTable } from './components/PaginatedTable'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
}))

function KiosksView({ match, kiosksFetch }) {

  const classes = useStyles()

  const tableState = usePaginatedTable({
    match,
    filterSlugs: {},
    defaultSortColumn: 'kiosk',
    defaultPageSize: 10
  })

  return (<Paper className={classes.paper}>
    <KioskTable title="Kiosks" tableState={tableState} kiosksFetch={kiosksFetch}/>
  </Paper>)
}

export default KiosksView
