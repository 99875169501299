
// eslint-disable-next-line no-shadow
export enum QrTokenTypes {
  login,
  flash,
  container,
}

const removePrefix = (rawToken: string): string => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ __, token ] = rawToken.split(':')
  return token || rawToken
}

export const getUrlFromToken = (token: string, tokenType: QrTokenTypes): string => {
  switch (tokenType) {
  case QrTokenTypes.login:
    return ''
  case QrTokenTypes.container:
    return ''
  case QrTokenTypes.flash:
    return `/flash?token=${removePrefix(token)}`
  default:
    return ''
  }
}

const tokenByPrefix = (token: String): QrTokenTypes | null => {
  const [ tokenPrefix ] = token.split(':')

  let returnEnum: QrTokenTypes | null = null

  if (tokenPrefix) {
    const enumOrNull = QrTokenTypes[tokenPrefix as keyof typeof QrTokenTypes]
    if (enumOrNull) {
      returnEnum = enumOrNull
    }
  }
  return returnEnum
}

const tokenByParam = (token: String): QrTokenTypes | null => {
  const [ hostPart, paramsPart ] = token.split('?')

  const params = new URLSearchParams(paramsPart || hostPart)

  let returnEnum: QrTokenTypes | null = null

  if (params.has('sn')) {
    returnEnum = QrTokenTypes.container
  }
  return returnEnum
}

export const getQrTokenType = (token: string): QrTokenTypes =>
  tokenByPrefix(token) || tokenByParam(token) || QrTokenTypes.login
