import React from 'react'
import { Field, FieldArray } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Switch from '@material-ui/core/Switch'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { MAX_ALLOWED_SIZES } from '@myx/console-utils'

const useStyles = makeStyles(theme => ({
  nameField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  smallFieldLabel: {
    marginLeft: theme.spacing(1),
  },
  smallField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 75,
  },
}))

function KioskSizeDefaultSwitchField({ form, name, isChecked }) {
  return (
    <Tooltip title="Default size selected on the Kiosk user interface" placement="bottom-start">
      <FormControl margin="normal">
        <InputLabel htmlFor={name} shrink={true}>Default</InputLabel>

        <Box display="flex" pt={2.5}>
          <Switch
            size="small"
            checked={isChecked}
            onChange={() => {
              if (isChecked) {
                return
              }

              // clear all and enable this one
              form.values.sizes.forEach((otherSize, otherSizeIndex) => {
                form.setFieldValue(`sizes[${otherSizeIndex}].defaultSize`, false)
              })

              form.setFieldValue(name, true)
            }}
            inputProps={{ 'id': name }}
          />
        </Box>
      </FormControl>
    </Tooltip>
  )
}

function nameValidator(value) {
  if (value.length < 1 || value.length > 15) {
    return 'Required, 15 characters max'
  }
  return null
}

function sizeValidator(value) {
  if (Number.isNaN(value) || (value % 1 !== 0)) {
    return 'Whole number required'
  }

  const size = parseInt(value, 10)
  if (!size || size < 8 || size > 40) {
    return '8-40oz required'
  }

  return null
}

function KioskSizeDialog(props) {
  const classes = useStyles()

  const sizes = props.form.values.sizes

  return (
    <FieldArray name="sizes">
      {(sizesHelper) => (
        <>
          {sizes.map((size, index) => (
            <Box key={size.id || size.tempId} display="flex" alignItems="flex-start">
              <Field name={`sizes[${index}].defaultSize`}>
                {({ field }) =>
                  <Box mr="auto">
                    <KioskSizeDefaultSwitchField
                      form={props.form}
                      name={field.name}
                      isChecked={field.value}
                    />
                  </Box>
                }
              </Field>

              <Field
                name={`sizes[${index}].name`}
                validate={nameValidator}
              >
                {({ field, meta }) =>
                  <Tooltip title="15 Characters Max" placement="bottom-start">
                    <TextField
                      margin="normal" // dense
                      label="Size Name"
                      type="text"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required
                      error={!!(meta.touched && meta.error)}
                      helperText={meta.touched && meta.error}
                      inputProps={{ maxLength: '15' }}
                      className={classes.nameField}
                      {...field}
                    />
                  </Tooltip>
                }
              </Field>
              <Field
                name={`sizes[${index}].size`}
                validate={sizeValidator}
              >
                {({ field, meta }) =>
                  <Tooltip title="Between 8 and 40 Oz" placement="bottom-start">
                    <TextField
                      margin="normal"
                      label="Volume"
                      type="number"
                      inputProps={{
                        min: 8,
                        max: 40,
                        step: 1,
                      }}
                      required
                      error={!!(meta.touched && meta.error)}
                      helperText={meta.touched && meta.error}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      className={classes.smallField}
                      {...field}
                    />
                  </Tooltip>
                }
              </Field>

              <FormControl margin="normal">
                <InputLabel htmlFor="sizeUnit" className={classes.smallFieldLabel}>Unit</InputLabel>
                <Select
                  autoWidth
                  inputProps={{
                    id: 'sizeUnit' // for input label
                  }}
                  value="oz"
                  className={classes.smallField}
                  // onChange={(event) => ()}
                >
                  <MenuItem value="oz">Oz</MenuItem>
                  {/* <MenuItem value="ml">mL</MenuItem> */}
                </Select>
              </FormControl>

              <Box alignSelf="center" pt={3} ml="auto" mr={-0.5}>
                <Tooltip title="Delete Size">
                  <IconButton
                    color="primary"
                    size="small"
                    disabled={sizes.length === 1}
                    onClick={() => {
                      sizesHelper.remove(index)

                      if (size.defaultSize) {
                        // sort sizes and find either a step-down or the next one up
                        const orderedSizes = [ ...sizes ]
                        orderedSizes.sort((sizeA, sizeB) => (sizeA.size * 1 || 0) - (sizeB.size * 1 || 0))
                        const orderedCurrentIndex = orderedSizes.indexOf(size)
                        const nextSmallestSize = orderedSizes[
                          orderedCurrentIndex > 0
                            ? orderedCurrentIndex - 1
                            : 1
                        ]

                        // find the index that that will be after this is removed and queue a field update
                        const sizesAfterChange = sizes.filter(nextSize => nextSize !== size)
                        const nextSmallestIndex = sizesAfterChange.indexOf(nextSmallestSize)

                        props.form.setFieldValue(`sizes[${nextSmallestIndex}].defaultSize`, true)
                      }
                    }}
                  >
                    <CloseIcon/>
                  </IconButton>
                </Tooltip>
              </Box>

              {/*
              <Field name="unit">
                {({ field }) =>
                  <FormControl margin="normal">
                    <InputLabel htmlFor="sizeUnit">Unit</InputLabel>
                    <Select
                      autoWidth
                      inputProps={{
                        id: 'sizeUnit' // for input label
                      }}
                      value="oz"
                      {...field}
                    >
                      <MenuItem value="oz">Oz</MenuItem>
                      <MenuItem value="ml">mL</MenuItem>
                    </Select>
                  </FormControl>
                }
              </Field>
              */}
            </Box>
          ))}

          <Box display="flex" justifyContent="flex-end" mt={1} mr={-1.5}>
            <Button
              color="primary"
              disabled={sizes.length >= MAX_ALLOWED_SIZES}
              onClick={() => {
                sizesHelper.push({
                  tempId: `tmp${Date.now()}`,
                  defaultSize: false,
                  name: '',
                  size: ''
                })
              }}
            >
              + Add Size
            </Button>
          </Box>
        </>
      )}
    </FieldArray>
  )
}

export default KioskSizeDialog
