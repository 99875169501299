import React from 'react'
import Grid from '@material-ui/core/Grid'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'

type Props = {
  percent: number,
}

const LOW = 20

const getColor = (percent: number) => {
  if (percent < 1) {
    return 'red'
  }
  if (percent < LOW) {
    return 'orange'
  }
  return 'black'
}

const getIcon = (color: string) => {
  if (color === 'red') {
    return <Grid item>
      <ErrorIcon fontSize="small" style={{ color: 'red' }}/>
    </Grid>
  }

  if (color === 'orange') {
    return <Grid item>
      <WarningIcon fontSize="small" style={{ color: 'orange' }}/>
    </Grid>
  }
  return null
}

function LevelPercentDisplay(props: Props) {

  const color = getColor(props.percent)

  return (<Grid container direction="row" alignItems="center" spacing={1}>
    <Grid item>
      <span style={{ color: color }}>
        {`${props.percent || '0'}%` }
      </span>
    </Grid>
    {getIcon(color)}
  </Grid>)
}

export default LevelPercentDisplay
