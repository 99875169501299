import React from 'react'

import AppFormTextField from '../form/AppFormTextField'

function MyAccountPasswordDialog() {
  return (
    <>
      <AppFormTextField
        name="oldPassword"
        label="Current Password"
        type="password"
        required
        autoFocus
      />
      <AppFormTextField
        name="newPassword"
        label="New Password"
        type="password"
        required
      />
    </>
  )
}

export default MyAccountPasswordDialog
