import { Theme } from '@material-ui/core/styles/createTheme'
import { ThemeOptions } from '@material-ui/core'

export function getMyxMuiOverrides(theme: Theme): ThemeOptions {
  return (
    {
      palette: {
        ...theme.palette,
      },
      overrides: {
        MuiLink: {
          root: {
            color: `${theme.palette.primary.main}`,
            '&:visited': {
              color: `${theme.palette.primary.main}`,
            },
            '&:hover': {
              color: `${theme.palette.secondary.main}`,
            },
          }
        },
        MuiContainer: {
          root: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            [theme.breakpoints.up('md')]: {
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),
            },
            [theme.breakpoints.down('md')]: {
              paddingLeft: theme.spacing(1),
              paddingRight: theme.spacing(1),
            }
          },
        },
        MuiTable: {
          root: {
            [theme.breakpoints.down('md')]: {
              tableLayout: 'fixed',
            },
            [theme.breakpoints.up('md')]: {
              minWidth: 350,
            },
          },
        },
        MuiTableCell: {
          sizeSmall: {
            [theme.breakpoints.down('md')]: {
              fontSize: '12px',
            },
          }
        },
        MuiTablePagination: {
          selectIcon: {
            color: 'white',
          },
          actions: {
            '& .MuiButtonBase-root:disabled': {
              color: 'rgba(200, 200, 200, 0.54)'
            }
          }
        },
        MuiToolbar: {
          regular: {
            width: '100%',
            backgroundColor: theme.palette.primary.main,
            zIndex: 1201,
            paddingLeft: theme.spacing(2),
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: 'white',
            [theme.breakpoints.down('sm')]: {
              paddingLeft: theme.spacing(1),
            },
          }
        },
        MuiTabs: {
          root: {
            flex: 1,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
          },
        },
        MuiAvatar: {
          root: {
            margin: theme.spacing(1, 0, 2),
            backgroundColor: theme.palette.secondary.main
          }
        },
        MuiAppBar: {
          colorPrimary: {
            backgroundColor: theme.palette.primary.main,
          },
          root: {
            padding: theme.spacing(0),
            minHeight: theme.spacing(8),
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            zIndex: 1201,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1)
          },
        },
        MuiDrawer: {
          paper: {
            zIndex: 1199,
            padding: 0
          }
        },
        MuiDialog: {
          paperWidthSm: { // applied when Dialog has maxWidth="sm" (default)
            'minWidth': 365,
          },
        },
        MuiDialogContent: {
          root: { // prevents vertical bar bounce
            overflowY: 'hidden'
          },
        },
        MuiCard: {
          root: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          },
        },

        MuiCardHeader: {
          title: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            color: theme.palette.primary.main,
          },
          action: {
            marginBottom: `-${theme.spacing(2)}px`,
          },
        },
        MuiPaper: {
          root: {
            padding: theme.spacing(1)
          }
        },
        MuiCardActions: {
          root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            justifyContent: 'flex-end',
          },
        },
      },
    }
  )
}
