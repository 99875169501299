import React, { useMemo } from 'react'
import { FieldArray } from 'formik'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

import FilterEntryField from './FilterEntryField'
import { useAppModal } from '@myx/console-utils'

function FilterDialog({ filterEntries, filterOptions }) {
  const usedFilterTypes = useMemo(() => (
    filterEntries.map(entry => entry.type)
  ), [ filterEntries ])

  return (
    <FieldArray name="entries">
      {(filterEntriesHelper) => <>
        {
          filterEntries.map((filterEntry, filterEntryIndex) =>
            <FilterEntryField
              key={filterEntryIndex}
              name={`entries[${filterEntryIndex}]`}
              filterOptions={filterOptions}
              usedFilterTypes={usedFilterTypes}
              onRemove={() => filterEntriesHelper.remove(filterEntryIndex)}
            />
          )
        }

        <Box
          display="flex"
          justifyContent="flex-end"
          mr={-1}
        >
          <Button
            type="button"
            onClick={() => {
              filterEntriesHelper.push({ type: '', value: '' })
            }}
          >
            Add Filter
          </Button>
        </Box>
      </>}
    </FieldArray>
  )
}

// convert table state filter values into form list entries
function getFilterEntries(filterValues) {
  return Object.keys(filterValues).filter(
    type => filterValues[type] !== null
  ).map(
    type => {
      return { type, value: filterValues[type] }
    }
  )
}

// convert form list entries back into table state filter values
function getFilterValues(filterEntries, filterOptions) {
  const filterData = {}

  // pre-fill values with null
  Object.keys(filterOptions).forEach(type => {
    filterData[type] = null
  })

  // collect from form
  filterEntries.forEach(filterEntry => {
    // ignore empties
    if (!filterEntry.type) {
      return
    }

    const trimmedValue = filterEntry.value.trim()
    filterData[filterEntry.type] = (
      trimmedValue === ''
        ? null
        : trimmedValue
    )
  })

  return filterData
}

export function useDataFilter(tableState, filterOptions) {
  const filterEntries = useMemo(
    () => {
      const entries = getFilterEntries(tableState.filter)

      // pre-fill with an empty entry if needed
      return (
        entries.length === 0
          ? [ { type: '', value: '' } ]
          : entries
      )
    },
    [ tableState.filter ]
  )

  const openFilterDialog = useAppModal(
    {
      title: 'Filter Results',
      initialValues: {
        entries: filterEntries
      },
      actionLabel: 'Update Filter',
      resultMessage: 'Updated filter criteria',
      action: (values) => {
        const filterData = getFilterValues(values.entries, filterOptions)

        // apply to state and return dummy promise
        tableState.setFilter(filterData)

        return Promise.resolve()
      }
    },
    (form) => (
      <FilterDialog
        filterEntries={form.values.entries}
        filterOptions={filterOptions}
      />
    ),
    [ tableState ] // trigger modal contents re-render when table state updates
  )

  return openFilterDialog
}
