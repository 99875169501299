import React from 'react'
import { usePermissions } from '../permissions'
import { useAsync } from 'react-async-hook'
import { graphQLFetch } from '@myx/console-utils'
import ProgressWrapper from '../components/ProgressWrapper'
import KioskOverviewPanel from './KioskOverviewPanel'

function KioskOverviewController({ kioskId }) {
  const perms = usePermissions()

  const kioskDataAsync = useAsync(() => {
    return graphQLFetch(`
      query($id: String!) {
        getKiosks(
          map: [ { key: "eq:id", value: $id } ],
          limit: 1,
          offset: 0
        ) {
          total
          kiosks {
            id
            assetCode
            companyId
            address1
            address2
            address3
            city
            state
            country
            zip
            locale
            freePlayMode
            accessInstructions
            contactGivenName
            contactFamilyName
            contactPhoneCountry
            contactPhone
            contactPhoneExtension
            contactEmail
            pour30Days
            pour1Day
            serviceAlertCount
            inventoryAlertCount
            statusDetail {
              id
              name
              changedOn
            }
            accountDetail {
              id
              accountCode
              name
            }
            modelDetail {
              name
            }
            onlineDetail {
              online
              changedOn
            }
          }
        }

        getKioskUsers(
          map: [{ key: "EQ:kioskId", value: $id }, { key: "EQ:roleCode", value: "fulfillment" }],
          limit: 1000,
          offset: 0
        ) {
          total
          kioskUsers {
            id
            kioskId
            userId
            userDetail {
              givenName
              familyName
              email
              phoneCountry
              phone
              phoneExtension
            }
          }
        }

        getKioskSizes(
          map: [{ key: "orderBy", value: "size"}],
          limit: 1000,
          offset: 0,
          kioskId: $id
        ) {
          total
          kioskSizes {
            id
            kioskId
            size
            name
            defaultSize
            changedBy
            changedOn
          }
        }
      }
    `, {
      id: kioskId,
    }).then(({
      getKiosks: { kiosks: data },
      getKioskSizes: { kioskSizes: sizes },
      getKioskUsers: { kioskUsers },
    }) => (
      data[0] && {
        ...data[0],
        sizes,
        kioskUsers,
      }
    ))
  }, [], {
    // preserve previously loaded data while new data is being fetched
    setLoading: state => ({ ...state, loading: true })
  })

  const kiosk = kioskDataAsync.result

  const editable = perms.userCanEditKiosks()

  return (
    <ProgressWrapper pending={kioskDataAsync.loading || !kioskDataAsync.result}>
      {kiosk && <KioskOverviewPanel
        kiosk={kiosk}
        editable={editable}
        onComplete={() => kioskDataAsync.execute()}
      />}
    </ProgressWrapper>
  )
}

export default KioskOverviewController
