import React, { useEffect, useRef } from 'react'

import { Link, useLocation } from 'react-router-dom'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'

import DashboardIcon from '@material-ui/icons/Dashboard'
import LocalDrinkIcon from '@material-ui/icons/LocalDrink'
import PeopleIcon from '@material-ui/icons/People'
import BarChartIcon from '@material-ui/icons/BarChart'
import DomainIcon from '@material-ui/icons/Domain'
import SettingsIcon from '@material-ui/icons/Settings'
import CropFreeIcon from '@material-ui/icons/CropFree'
import OpacityIcon from '@material-ui/icons/Opacity'

import { usePermissions } from '../permissions'

// forwardRef approach required for react-router-dom < 6.0.0 per docs for material-ui 4.0-beta

const DashboardLink = React.forwardRef((innerProps, ref) => (
  <Link innerRef={ref} to="/dashboard" {...innerProps} />
))

const KiosksLink = React.forwardRef((innerProps, ref) => (
  <Link innerRef={ref} to="/kiosks" {...innerProps} />
))

const ContainersLink = React.forwardRef((innerProps, ref) => (
  <Link innerRef={ref} to="/containers" {...innerProps} />
))

const UsersLink = React.forwardRef((innerProps, ref) => (
  <Link innerRef={ref} to="/users" {...innerProps} />
))

const KioskLoginLink = React.forwardRef((innerProps, ref) => (
  <Link innerRef={ref} to="/qrlogin" {...innerProps} />
))

const ReportsLink = React.forwardRef((innerProps, ref) => (
  <Link innerRef={ref} to="/reports" {...innerProps} />
))

const SettingsLink = React.forwardRef((innerProps, ref) => (
  <Link innerRef={ref} to="/settings" {...innerProps} />
))

const ChangeContainerLink = React.forwardRef((innerProps, ref) => (
  <Link innerRef={ref} to="/changecontainer" {...innerProps}/>
))

const ScanContainerLink = React.forwardRef((innerProps, ref) => (
  <Link innerRef={ref} to="/scancontainer" {...innerProps}/>
))

const MyAccountLink = React.forwardRef((innerProps, ref) => (
  <Link innerRef={ref} to="/account" {...innerProps}/>
))

function NavList(props) {

  const { pathname } = useLocation()

  // hide menu when navigation changes
  const onHideRef = useRef(props.onHide)
  onHideRef.current = props.onHide

  useEffect(() => {
    if (onHideRef.current) {
      onHideRef.current()
    }
  }, [ pathname ])

  const perms = usePermissions()

  return (
    perms.userCanOnlyScanContainers() ? <>
      <List>
        <ListItem button component={ScanContainerLink} selected={pathname.startsWith('/scancontainers')}>
          <ListItemIcon>
            <CropFreeIcon/>
          </ListItemIcon>
          <ListItemText primary="Scan Container"/>
        </ListItem>
        <ListItem button component={MyAccountLink} selected={pathname.startsWith('/account')}>
          <ListItemIcon>
            <SettingsIcon/>
          </ListItemIcon>
          <ListItemText primary="My Account"/>
        </ListItem>
      </List>
    </> : <>
      <List>
        <ListItem button component={DashboardLink} selected={pathname.startsWith('/dashboard')}>
          <ListItemIcon>
            <DashboardIcon/>
          </ListItemIcon>
          <ListItemText primary="Dashboard"/>
        </ListItem>
        <ListItem button component={KiosksLink} selected={pathname.startsWith('/kiosks')}>
          <ListItemIcon>
            <DomainIcon/>
          </ListItemIcon>
          <ListItemText primary="Kiosks"/>
        </ListItem>
        <ListItem button component={ContainersLink} selected={pathname.startsWith('/containers')}>
          <ListItemIcon>
            <LocalDrinkIcon/>
          </ListItemIcon>
          <ListItemText primary="Containers"/>
        </ListItem>
        {
          perms.userCanAccessScanContainers() &&
          <ListItem button component={ScanContainerLink} selected={pathname.startsWith('/scancontainers')}>
            <ListItemIcon>
              <CropFreeIcon/>
            </ListItemIcon>
            <ListItemText primary="Scan Container"/>
          </ListItem>
        }
        {perms.userCanAccessUsers() &&
          <ListItem button component={UsersLink} selected={pathname.startsWith('/user')}>
            <ListItemIcon>
              <PeopleIcon/>
            </ListItemIcon>
            <ListItemText primary="Users"/>
          </ListItem>
        }
        <ListItem button component={KioskLoginLink} selected={pathname.startsWith('/qrlogin')}>
          <ListItemIcon>
            <CropFreeIcon/>
          </ListItemIcon>
          <ListItemText primary="Login at kiosk"/>
        </ListItem>
        <ListItem button component={ChangeContainerLink} selected={pathname.startsWith('/changecontainer')}>
          <ListItemIcon>
            <OpacityIcon/>
          </ListItemIcon>
          <ListItemText primary="Change Containers"/>
        </ListItem>
        {perms.userCanAccessReports() &&
          <ListItem button component={ReportsLink} selected={pathname.startsWith('/reports')}>
            <ListItemIcon>
              <BarChartIcon/>
            </ListItemIcon>
            <ListItemText primary="Reports"/>
          </ListItem>
        }
      </List>
      {perms.userCanAccessSettings() &&
        <>
          <Divider/>
          <List>
            <ListItem button component={SettingsLink} selected={pathname.startsWith('/settings')}>
              <ListItemIcon>
                <SettingsIcon/>
              </ListItemIcon>
              <ListItemText primary="Settings"/>
            </ListItem>
          </List>
        </>
      }
    </>
  )

}

export default NavList
