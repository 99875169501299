/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Link as RouterLink, Redirect, Route, Switch } from 'react-router-dom'
import { ModalProvider } from 'react-modal-hook'
import { TransitionGroup } from 'react-transition-group'
import { makeStyles, MuiThemeProvider, useTheme, withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

import { SessionProvider, useSessionContext, useSessionLoadedStatus } from './components/SessionContext'
import { AppSnackProvider } from './components/AppSnack'
import NavDrawer from './components/NavDrawer'
import PageHeading from './components/PageHeading'

import Kiosks from './pages/KiosksController'
import ContainersController from './pages/ContainersController'
import Users from './pages/Users'
import UserInvites from './pages/UserInvites'
import Reports from './pages/Reports'
import Settings from './pages/Settings'
import MyAccount from './pages/MyAccount'
import Error404 from './pages/Error404'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import QrLogin from './pages/QrLogin'
import ChangeContainerPage from './pages/ChangeContainerPage'
import {
  ConsoleSignin,
  CONSUMER_URL,
  GoogleSigninController,
  Header,
  MyxLogoSVG,
  signIn,
  themeContext,
  usePageContext
} from '@myx/console-utils'
import ScanContainersController from './pages/ScanContainersController'
import { useAuthErrorListener, useNetworkErrorListener } from './components/AppErrors'
import OperatorDashboardController from './components/OperatorDashboardController'

const drawerWidth = 200

const GOOGLE_OAUTH_REGISTRATION_ID: string = 'operator-google'

const useStyles = makeStyles(theme => ({
  content: {
    [theme.breakpoints.up('sm')]: { // force width to fit screen w/ permanent drawer
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`
    }
  },

  logoImage: {
    flex: 'none',
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(1),
    height: '2em'
  },
  homeLink: {
    flex: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    flex: 'none',
    color: 'white',
  },
  leftSpacer: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      flexGrow: 0,
    }
  },
  rightSpacer: {
    flexGrow: 1,
  },
}))

const ContentContainer = withStyles(_theme => ({
  root: {
    paddingTop: _theme.spacing(1),
    paddingBottom: _theme.spacing(1),
    [_theme.breakpoints.up('md')]: {
      paddingLeft: _theme.spacing(1),
      paddingRight: _theme.spacing(1),
    },
  },
}))(Container)

function RootRedirect() {
  return (
    <Redirect to="/dashboard"/>
  )
}

// memoized routes component to avoid infinite render loop due to page context updates
const AppSecureRoutes = React.memo(() => (
  <Switch>
    <Route exact path="/" component={RootRedirect}/>
    <Route path="/dashboard" component={OperatorDashboardController}/>
    <Route path="/kiosks" component={Kiosks}/>
    <Route path="/containers" component={ContainersController}/>
    <Route path="/inventory/containers" component={ContainersController}/>
    <Route path="/users/invites" component={UserInvites}/>
    <Route path="/users" component={Users}/>
    <Route path="/reports" component={Reports}/>
    <Route path="/settings" component={Settings}/>
    <Route path="/account" component={MyAccount}/>
    <Route path="/qrlogin" component={QrLogin}/>
    <Route path="/changecontainer" component={ChangeContainerPage}/>
    <Route path="/scancontainer" component={ScanContainersController}/>
    <Route component={Error404}/>
  </Switch>
))

// eslint-disable-next-line max-statements
function AppSecureScreen() {

  const sessionIsLoaded = useSessionLoadedStatus()

  useAuthErrorListener()
  useNetworkErrorListener()

  if (sessionIsLoaded) {
    return <AppSecureScreenSession/>
  }
  return null

}

function AppSecureScreenSession() {

  const session: any = useSessionContext()

  const [ menuDrawerOpen, setMenuDrawerOpen ] = useState(false)

  const theme = useTheme()

  const {
    PageContextProvider,
    url: pageUrl,
    title: pageTitle,
    titleIsPending: pageTitleIsPending,
    contents: pageContents
  } = usePageContext()

  const classes = useStyles()

  // update document title
  useEffect(() => {
    document.title = pageTitle
      ? `${pageTitle}${pageTitleIsPending ? '...' : ''} - MYX Operator Console`
      : 'MYX Operator Console'
  }, [ pageTitle, pageTitleIsPending ])

  // reset scroll on navigation
  useEffect(() => {
    if (!pageUrl) {
      return
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [ pageUrl ])

  // loading state
  if (!session.email) {

    return (
      <Box>
        <AppBar position="fixed">
          <div className={classes.leftSpacer}/>
          <RouterLink to="/dashboard">
            <MyxLogoSVG
              height={'2em'}
              width={'2em'}
              color={'#FFFFFF'}
            />
            <Typography component="h1" variant="h6" color="inherit" noWrap>
              MYX Console
            </Typography>
          </RouterLink>
          <Box className={classes.rightSpacer}/>

        </AppBar>
        <Box style={{ height: '128px' }}/>
        <Box style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center' }}>
          <Typography component="h2" variant="h6" color="primary" style={{ color: theme.palette.primary.main }}>
            Loading
          </Typography>
          <Box p={3} style={{ height: '100%', display: 'flex', alignContent: 'center' }}>
            <CircularProgress/>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Header
        session={session}
        pageUrl={pageUrl}
        onMenuClick={() => setMenuDrawerOpen(!menuDrawerOpen)}/>

      <NavDrawer
        drawerWidth={drawerWidth}
        menuDrawerOpen={menuDrawerOpen}
        onMenuDrawerClose={() => setMenuDrawerOpen(false)}/>
      <main className={classes.content}>
        <div/>

        <ContentContainer maxWidth="xl">
          <div key={pageUrl}>
            <PageHeading isPending={pageTitleIsPending}>
              {pageTitle}
            </PageHeading>

            {pageContents}
          </div>
        </ContentContainer>

        {/* hide the box just in case something is not wrapped in PageView */}
        <Box display="none">
          <PageContextProvider>
            <AppSecureRoutes/>
          </PageContextProvider>
        </Box>
      </main>
    </>
  )

}

function Myx() {
  const { theme } = themeContext.useThemeContext()
  if (!theme) {
    return null
  }
  return (
    <MuiThemeProvider theme={theme}>
      <AppSnackProvider>
        <ModalProvider container={TransitionGroup}>
          <BrowserRouter basename="/operator">
            <CssBaseline/>
            <Switch>
              <Route path="/google/signin"
                render={() => <GoogleSigninController registrationId={GOOGLE_OAUTH_REGISTRATION_ID}/>}/>
              <Route
                exact path="/sign-in"
                render={({ history }) =>
                  <ConsoleSignin
                    consumerUrl={CONSUMER_URL}
                    signIn={signIn}
                    onSignIn={() => {
                      history.push('/')
                    }}
                    registrationId={GOOGLE_OAUTH_REGISTRATION_ID}
                  />}/>
              <Route>
                <SessionProvider>
                  <AppSecureScreen/>
                </SessionProvider>
              </Route>
            </Switch>
          </BrowserRouter>
        </ModalProvider>
      </AppSnackProvider>
    </MuiThemeProvider>
  )
}

function App() {

  return (
    <themeContext.ThemeProvider>
      <Myx/>
    </themeContext.ThemeProvider>
  )

}

export default App
