import React, { useEffect, useRef } from 'react'
import { Field, Formik } from 'formik'
import { useAsyncCallback } from 'react-async-hook'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import BuiltWithLove from './BuiltWithLove'
import * as passwordHelper from '../helpers/passwordHelper'
import themeContext from '../context/ThemeProvider'
import GoogleButton from './google/GoogleButton'
import { AUTH_BASE } from '../backend'

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // fixes ie11 issue
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

type Props = {
  onSignIn: () => void,
  signIn: (email: string, password: string) => Promise<void>,
  consumerUrl: string,
  registrationId: string,
}

function ConsoleSignin({ onSignIn, signIn, consumerUrl, registrationId }: Props) {

  function requireText(value: string) {
    if (value === '') {
      return 'Required field'
    }

    return null
  }

  // wrap latest callback in ref to avoid re-triggering effect
  const onSignInRef = useRef(onSignIn)
  onSignInRef.current = onSignIn

  // form action async state
  const formActionAsync = useAsyncCallback(
    ({ email, password }) => signIn(email, password).then(() => true), // ensure truthy result
    // @ts-ignore
    []
  )

  const googleOnClickHandler = () => {
    window.location.href = `https://${AUTH_BASE}/consumer-auth/oauth2/authorization/${registrationId}`
    // window.location.href = `http://localhost:9090/oauth2/authorization/${registrationId}`
  }

  // listen for form action success
  useEffect(() => {
    if (!formActionAsync.result) {
      return
    }
    // notify current parent callback
    onSignInRef.current()
  }, [ formActionAsync.result ])

  const { theme } = themeContext.useThemeContext()
  const classes = useStyles(theme)

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.paper}>
        <Avatar>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Typography component="h2" variant="subtitle1">
          MYX Operator Console
        </Typography>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={(values, form) => {
            form.setSubmitting(false) // clear internal flag
            formActionAsync.execute(values)
          }}
        >
          {(form) => (
            <form
              className={classes.form}
              noValidate
              onSubmit={form.handleSubmit}
            >
              <Field name="email" validate={requireText}>
                {({ field }: any) => (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Email Address"
                    autoComplete="email"
                    autoFocus
                    // @ts-ignore
                    error={!!form.errors[field.name]}
                    // @ts-ignore
                    helperText={form.errors[field.name]}
                    {...field}
                  />
                )}
              </Field>
              <Field name="password" validate={requireText}>
                {({ field }: any) => (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    // @ts-ignore
                    error={!!form.errors[field.name]}
                    // @ts-ignore
                    helperText={form.errors[field.name]}
                    {...field}
                  />
                )}
              </Field>
              <Typography variant="body2" color="textSecondary">
                *{passwordHelper.PASSWORD_VALIDATION_ERROR_MSG}
              </Typography>
              {formActionAsync.error && (
                <Typography variant="body2" color="error">
                  {formActionAsync.error.message ||
                    formActionAsync.error.toString()}
                </Typography>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={formActionAsync.loading}
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    href={`${consumerUrl}/#forgot-password`}
                    variant="body2"
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="mailto:support@myxdrinks.com" variant="body2">
                    Don't have an account?
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
        <GoogleButton
          onClick={googleOnClickHandler}
        />
      </div>
      <Box mt={5}>
        <BuiltWithLove/>
      </Box>
    </Container>
  )
}

export default ConsoleSignin
