import React from 'react'

import Panel from '../components/Panel'
import { useDataFilter } from '../components/filter/DataFilter'
import { usePaginatedTable } from '../components/PaginatedTable'

import SettingsAccountsTable from '../components/tables/SettingsAccountsTable'

function SettingsAccountsPanel(props) {

  const tableState = usePaginatedTable({
    match: props.match,
    filterSlugs: {
      accountCode: 'accountCode',
      name: 'name',
    },
    defaultSortColumn: 'totalKiosks',
    defaultPageSize: 10
  })

  const openFilterDialog = useDataFilter(tableState, {
    accountCode: 'Account Code',
    name: 'Name',
  })

  return (
    <Panel
      title="Accounts"
      hasFilter={tableState.hasFilter}
      onFilterClick={() => {
        openFilterDialog()
      }}
    >
      <SettingsAccountsTable tableState={tableState} />
    </Panel>
  )
}

export default SettingsAccountsPanel
