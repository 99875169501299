import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

// for app to work offline and load faster, change unregister() to register() below.
// note that this comes with pitfalls - more on service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
