import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

function ContainerInfoCard({ container }) {
  return (
    <Card>
      <CardHeader title="Container" />
      <CardContent>
        <Table size="medium">
          <colgroup>
            <col style={{ width: '50%' }}/>
            <col style={{ width: '50%' }}/>
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Ingredient</TableCell>
              <TableCell>{ container.ingredientDetail && container.ingredientDetail.name }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Level</TableCell>
              <TableCell>{ `${container.remainingPercent}%` }</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default ContainerInfoCard
