import React from 'react'

import AsyncSwitch from '../utils/AsyncSwitch'
import { UpdateKioskCo2Enabled } from '../../@types/UpdateKioskCo2Enabled'

type Props = {
  isEnabled: boolean,
  updateCo2Enabled: (state: boolean) => Promise<UpdateKioskCo2Enabled>
}

function KioskPositionEnabledSwitch({ isEnabled, updateCo2Enabled }: Props) {
  return (
    <AsyncSwitch
      action={(updatedValue: boolean) =>
        updateCo2Enabled(updatedValue)
          .then(({ updateKioskCo2Enabled: { enabled } }) => enabled)
      }
      initialChecked={isEnabled}
    />
  )
}

export default KioskPositionEnabledSwitch
