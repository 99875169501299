/* eslint-disable max-lines */
import React, { useRef, useCallback, useContext, useImperativeHandle } from 'react'
import { useAsync } from 'react-async-hook'
import isEqual from 'lodash/isEqual'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import EditIcon from '@material-ui/icons/Edit'
import NotificationsIcon from '@material-ui/icons/Notifications'
import LockIcon from '@material-ui/icons/Lock'
import CloseIcon from '@material-ui/icons/Close'
import PersonIcon from '@material-ui/icons/Person'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: 'fixed',
    minWidth: 800,
  },
  smallTable: {
    tableLayout: 'fixed',
  },
  spinnerContainer: {
    position: 'relative',
    display: 'flex', // ensure child content negative margin does not spill out
    flexDirection: 'column'
  },
  spinnerContainerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.75)',
    opacity: 0, // hide by default
    transition: 'opacity 0.2s ease-out',
    pointerEvents: 'none', // allow clicks on content just in case

    '&[data-active=true]': {
      opacity: 1
    }
  },
  pagination: {
    // compensate for excess padding against container gutter
    marginRight: -theme.spacing(1),
    marginBottom: -theme.spacing(2)
  }
}))

function SpinnerContainer({ pending, children }) {
  const classes = useStyles()

  return (
    <div className={classes.spinnerContainer}>
      {children}

      <div className={classes.spinnerContainerOverlay} data-active={!!pending}>
        <CircularProgress />
      </div>
    </div>
  )
}

/**
 * @return {[ any, { data: any[] } | undefined, boolean, () => void ]}
 */
function usePaginatedData(tableState, dataSource) { // eslint-disable-line max-statements
  // wrap latest data source callback to avoid re-triggering effect
  const dataSourceRef = useRef(dataSource)
  dataSourceRef.current = dataSource

  // keep a stable cursor state reference, triggering change on deep-equality only
  const { filter, sortColumn, sortReverse, pageSize } = tableState
  const updatedCursor = { filter, sortColumn, sortReverse, pageSize }

  const stableCursorRef = useRef()
  if (!isEqual(stableCursorRef.current, updatedCursor)) {
    stableCursorRef.current = updatedCursor
  }

  const stableCursor = stableCursorRef.current

  // load page data when cursor state or page index have changed
  const pageAsync = useAsync(() => {
    const latestDataSource = dataSourceRef.current

    // not passing any parameters in (since data source callback has access to them)
    return latestDataSource().then(data => {
      // retain original stable cursor reference as well as table state known
      // at time of invocation
      return {
        cursor: stableCursor,
        tableState,
        data
      }
    })
  }, [ stableCursor, tableState.pageIndex ])

  // keep results around while new loads happen (but clear immediately on cursor change)
  const resultCacheRef = useRef()

  if (resultCacheRef.current && resultCacheRef.current.cursor !== stableCursor) {
    // clear out right away when cursor has changed
    resultCacheRef.current = undefined // eslint-disable-line no-undefined
  }

  if (pageAsync.result && pageAsync.result.cursor === stableCursor) {
    // update cache if results are present and match current cursor
    resultCacheRef.current = pageAsync.result
  }

  // stable reloader hook
  const pageAsyncRef = useRef(pageAsync)
  pageAsyncRef.current = pageAsync

  const refreshCallback = useCallback(() => {
    pageAsyncRef.current.execute()
  }, [])

  // return relevant cached result
  return [
    resultCacheRef.current && resultCacheRef.current.tableState,
    resultCacheRef.current && resultCacheRef.current.data,
    pageAsync.loading,
    refreshCallback
  ]
}

// used to pass down current table state within displayed data table component
/** @type {React.Context<import('./PaginatedTable').PaginatedTableState | undefined>} */
const dataTableContext = React.createContext()

/**
 * @param {Object} props
 * @param {string} props.column
 * @param {React.ReactNode} props.children
 */
export function DataTableSortLabel({ column, children }) {
  const tableState = useContext(dataTableContext)

  if (!tableState) {
    throw new Error('sort label must be used within data table')
  }

  return (
    <TableSortLabel
      active={tableState.sortColumn === column}
      direction={tableState.sortReverse ? 'desc' : 'asc'}
      onClick={() =>
        // toggle sort if same column
        tableState.setSort(
          column,
          column === tableState.sortColumn && !tableState.sortReverse
        )
      }
    >
      {children}
    </TableSortLabel>
  )
}

/**
 * Specify appropriate on__Click handler to turn on the corresponding icon button.
 *
 * @param {Object} props
 * @param {(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void} [props.onDetailsClick]
 * @param {(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void} [props.onEditClick]
 * @param {(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void} [props.onEditUserClick]
 * @param {(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void} [props.onEditAlertsClick]
 * @param {(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void} [props.onEditSecurityClick]
 * @param {(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void} [props.onDeleteClick]
 */
export function DataTableRowActions({
  onDetailsClick,
  onEditClick,
  onEditUserClick,
  onEditAlertsClick,
  onEditSecurityClick,
  onDeleteClick
}) {
  // apply negative margin to align button icon content with parent edge
  return (
    <Box display="flex" justifyContent="flex-end" mx={-1}>
      {onDetailsClick &&
        <Tooltip title="View Details">
          <IconButton color="primary" size="small" onClick={onDetailsClick} aria-label="View Details">
            <MoreHorizIcon />
          </IconButton>
        </Tooltip>
      }

      {onEditClick &&
        <Tooltip title="Edit">
          <IconButton color="primary" size="small" onClick={onEditClick} aria-label="Edit">
            <EditIcon />
          </IconButton>
        </Tooltip>
      }

      {onEditUserClick &&
        <Tooltip title="User Info">
          <IconButton color="primary" size="small" onClick={onEditUserClick} aria-label="Edit User Information">
            <PersonIcon />
          </IconButton>
        </Tooltip>
      }

      {onEditAlertsClick &&
        <Tooltip title="Alert Notifications">
          <IconButton color="primary" size="small" onClick={onEditAlertsClick} aria-label="Edit Alert Notifications">
            <NotificationsIcon />
          </IconButton>
        </Tooltip>
      }

      {onEditSecurityClick &&
        <Tooltip title="Roles">
          <IconButton color="primary" size="small" onClick={onEditSecurityClick}>
            <LockIcon />
          </IconButton>
        </Tooltip>
      }

      {onDeleteClick &&
        <Tooltip title="Delete">
          <IconButton color="primary" size="small" onClick={onDeleteClick}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      }
    </Box>
  )
}

/**
 * @param {Object} props
 * @param {import('./PaginatedTable').PaginatedTableState} props.tableState
 */
function DataTable({ tableState, dataSource, variant, children }, ref) {

  const classes = useStyles()

  // active page data fetch, caching the results to still be displayed during next page load
  // (table parameters and resulting data might lag behind requested page index, by design)
  const [ cachedTableState, cachedTableResult, tableResultLoading, refreshTable ] = usePaginatedData(tableState, dataSource)

  // expose the refresh() method - usage: pass a `ref` to DataTable, call refName.current.refresh()
  useImperativeHandle(ref, () => ({
    refresh: refreshTable
  }), [ refreshTable ])

  return (
    <SpinnerContainer pending={tableResultLoading || !cachedTableState}>
      {/* expose active (not cached) table state for sort labels */}
      <dataTableContext.Provider value={tableState}>
        <Table size="small" className={variant === 'mobile' ? classes.smallTable : classes.table}>
          {cachedTableResult
            ? (cachedTableResult.data.length > 0
              ? children(
                cachedTableResult.data,
                cachedTableState.subMatch,
                refreshTable
              )
              : <TableBody>
                <TableRow>
                  <TableCell>No matching records found.</TableCell>
                </TableRow>
              </TableBody>
            )
            : children([], null) // show preview of columns while loading
          }
        </Table>
      </dataTableContext.Provider>

      {cachedTableResult && cachedTableResult.data.length > 0 &&
        <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[ 10, 25, 50, 100 ]}
          component="div"
          count={cachedTableResult.total}
          rowsPerPage={cachedTableState.pageSize}
          page={cachedTableState.pageIndex}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={(event, newPage) => tableState.setPage(newPage, tableState.pageSize)}
          onChangeRowsPerPage={event => tableState.setPage(0, event.target.value)}
        />
      }
    </SpinnerContainer>
  )

}

export default React.forwardRef(DataTable)
