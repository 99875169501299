import React from 'react'
import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core'
import ReOrderRecommendationCard from '../components/cards/ReOrderRecommendationCard'
import IngredientMenuCard from '../components/cards/IngredientMenuCard'
import Button from '@material-ui/core/Button'

const IngredientReorderDescriptionCard = () => <Card>
  <CardHeader
    title="Ingredient Container Auto Reorder"
  />
  <CardContent>
    <Typography variant="body2">
      Myx fulfills auto orders on the 1st and 15th of every month. On these dates the number of non installed,
      full containers in your organizations possession will be subtracted from the quantity selected per ingredient to determine how many containers will be sent.
      For Example, if you have 2 full Lemonade containers, not installed in a kiosk and have a quantity of 5 selected below, then you will be sent 3 containers.
      If all of your lemonade containers are in kiosks, then you will be sent 5.
    </Typography>
  </CardContent>
</Card>

const findIngredientSubscription = (ingredientId, ingredientSubscriptions) => {
  const subscriptions = ingredientSubscriptions.find((sub) => Number.parseFloat(sub.ingredientId) === Number.parseFloat(ingredientId))
  return subscriptions ? subscriptions.quantity : 0
}

const getIngredientCartState = (ingredients, ingredientSubscriptions) => Object.keys(ingredients).map((ingId) => {
  return {
    ingredientId: ingId,
    name: ingredients[ingId],
    quantity: findIngredientSubscription(ingId, ingredientSubscriptions)
  }
}).sort((i1, i2) => {
  if (i1.name > i2.name) {
    return 1
  }
  if (i1.name < i2.name) {
    return -1
  }
  return 0
})

function IngredientSubscriptionGrid({ onComplete, ingredientSubscriptions, ingredients, recommendedIngredients }) {

  const [ ingredientCart, setIngredientCart ] = React.useState(getIngredientCartState(ingredients, ingredientSubscriptions))

  const updateIngredientItem = (ingId, count) => setIngredientCart(ingredientCart.map((ingredientItem) => {
    if (ingredientItem.ingredientId === ingId) {
      return {
        ...ingredientItem,
        quantity: count,
      }
    }
    return ingredientItem

  }))

  const updateIngredientItems = (recommendations) => setIngredientCart(ingredientCart.map((ingredientCartItem) => {
    const recommendedItem = recommendations.find(rec => `${rec.ingredientId}` === `${ingredientCartItem.ingredientId}`)
    return {
      ...ingredientCartItem,
      quantity: recommendedItem && recommendedItem.quantity > ingredientCartItem.quantity ? recommendedItem.quantity : ingredientCartItem.quantity
    }
  }))

  return (<Grid container spacing={2}>
    <Grid item xs={12}>
      <IngredientReorderDescriptionCard/>
    </Grid>
    <Grid item xs={12}>
      <ReOrderRecommendationCard
        updateIngredientItem={updateIngredientItem}
        ingredients={ingredientCart}
        ingredientMenu={ingredients}
        recommendedIngredients={recommendedIngredients}
        onRecommendationUpdate={(recommended) => updateIngredientItems(recommended)}
      />
    </Grid>
    <Grid item xs={12}>
      <IngredientMenuCard
        updateIngredientItem={updateIngredientItem}
        ingredientCart={ingredientCart}
        ingredientMenu={ingredients}
        recommendedIngredients={recommendedIngredients}
      />
    </Grid>
    <Grid item xs={12}>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => onComplete(ingredientCart)}
      >Update My Order</Button>
    </Grid>
  </Grid>)
}

export default IngredientSubscriptionGrid
