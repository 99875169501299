import React, { useCallback } from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import * as backend from '@myx/console-utils'
import Switch from '@material-ui/core/Switch'
import { Grid, Typography } from '@material-ui/core'
import OneTimePinCountDownWheel from '../OneTimePinCountDownWheel'
import KioskIdPicker from '../kioskPicker/KioskIdPicker'

function KioskOneTimePinCard({ kiosk }) {

  const [ showOTP, setShowOTP ] = React.useState(false)
  const [ otp, setOtp ] = React.useState('------')
  const [ kioskId, setKioskId ] = React.useState((kiosk ? kiosk.id : null))

  /** gets the kiosks One-Time-Pin from Myx Cloud **/

  const updateOTP = useCallback(async () => {
    if (kioskId) {
      try {
        const _otp = await backend.graphQLFetch(`
      query($kioskId: String!) {
        getOneTimePin(kioskId: $kioskId) {
            code
        }
      }
    `,
        {
          kioskId: kioskId
        })
        if (_otp.getOneTimePin) {
          setOtp(_otp.getOneTimePin.code)
        } else {
          setOtp('ERROR')
        }
      } catch (error) {
      }
    }
    return '!!!!!!'
  }, [ kioskId ])

  React.useEffect(() => {
    updateOTP()
  }, [ updateOTP ])

  return (
    <Card>
      <CardHeader title="Time Based Access Pin"/>
      <CardContent>

        <Grid container
          spacing={4}
          align={'center'}
          display={'flex'}
          alignItems={'center'}
          alignContent={'center'}
        >
          <Grid item xs={12} md={4}>
            One Time Pin(Show):
            <Switch
              size="small"
              checked={showOTP}
              onChange={() => {
                setShowOTP(!showOTP)
              }}
              color="primary"/>
          </Grid>
          <Grid item xs={12} md={4}>
            {
              showOTP && <Typography variant="h5">{otp}</Typography>
            }
          </Grid>
          <Grid item xs={12} md={4}>
            {showOTP &&
              <OneTimePinCountDownWheel
                updateTOTP={() => {
                  if (showOTP) {
                    setOtp('------')
                    setTimeout(() => {
                      updateOTP()
                    }, 100)
                  }
                }}
              />
            }
          </Grid>

          <Grid item xs={12}>
            {(!kiosk || !kiosk.id) && showOTP &&
              <KioskIdPicker
                selectedKioskId={kioskId}
                handleKioskIdSelected={(_kioskId) => {
                  setKioskId(_kioskId)
                }}
                showDeployedPicker={true}
                deployedOnly={false}/>
            }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )

}

export default KioskOneTimePinCard
