import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  link: {
    color: `${theme.palette.primary.main}`,
    '&:visited': {
      color: `${theme.palette.primary.main}`,
    },
    '&:hover': {
      color: `${theme.palette.secondary.main}`,
    },
  },
}))

function EmailDisplay({ email }) {
  const classes = useStyles()

  const emailAddress = ('' + email).trim().toLowerCase()

  return (
    <span>
      { email
        ? (
          <a
            href={`mailto:${emailAddress}`}
            className={classes.link}
          >
            {emailAddress}
          </a>
        )
        : '—'
      }
    </span>
  )
}

export default EmailDisplay
