import React from 'react'

import Box from '@material-ui/core/Box'

import AppFormTextField from '../form/AppFormTextField'
import AppFormPhoneCountryField from '../form/AppFormPhoneCountryField'

function KioskContactDialog() {
  return (
    <>
      <Box display="flex">
        <Box mr={1} flex={1}>
          <AppFormTextField
            name="contactGivenName"
            required
            label="First Name"
          />
        </Box>
        <Box ml={1} flex={1}>
          <AppFormTextField
            name="contactFamilyName"
            required
            label="Last Name"
          />
        </Box>
      </Box>
      <AppFormTextField
        name="contactEmail"
        type="email"
        required
        label="Email Address"
        autoFocus
      />
      <Box display="flex">
        <Box mr={1} flex={1} style={{ maxWidth: '30%' }}>
          <AppFormPhoneCountryField name="contactPhoneCountry" label="Country Code" required />
        </Box>
        <Box mr={1} ml={1} flex={1}>
          <AppFormTextField
            name="contactPhone"
            required
            label="Phone"
            type="text" // @TODO: react-text-mask - https://next.material-ui.com/components/text-fields/
          />
        </Box>
        <Box ml={1} flex={1} style={{ maxWidth: '20%' }}>
          <AppFormTextField
            name="contactPhoneExtension"
            label="Extension"
            validateWholeNumber
          />
        </Box>
      </Box>
    </>
  )
}

export default KioskContactDialog
