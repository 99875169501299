import React from 'react'
import { Button, ButtonGroup, Card, CardContent, CardHeader, Grid } from '@material-ui/core'
import PositionChangeContainerController from '../PositionChangeContainerController'
import { makeStyles } from '@material-ui/core/styles'
import QrCodeScannerIcon from '@material-ui/icons/CameraAlt'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import { useHistory } from 'react-router'

const useStyles = makeStyles((theme) => ({
  on: {
    color: '#fff',
    background: theme.palette.primary.main,
    fontWeight: 700,
    '&:hover': {
      background: theme.palette.primary.main,
    },
    '&+*': {
      borderLeftColor: theme.palette.primary.main,
    },
  },
  off: {
    color: theme.palette.primary.main,
  },
  grid: {
    marginTop: theme.spacing(2),
  }
}))

/**
 * Consider turning this into an API call for the kiosk model...
 */
const e430PosDesOrder = {
  1: 9,
  2: 10,
  3: 11,
  4: 12,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 1,
  10: 2,
  11: 3,
  12: 4,
}

const e430PosSort = (p1, p2) => e430PosDesOrder[p1] > e430PosDesOrder[p2] ? 1 : -1

export const enterMethods = Object.freeze({ 'keyboard': 'keyboard', 'qr': 'qr' })

function ChangePositionContainersCard({ onComplete, token, kiosk, positions, initialEnterMethod = enterMethods.qr }) {

  const classes = useStyles()

  const history = useHistory()

  const [ enterMethod, setEnterMethod ] = React.useState(initialEnterMethod)

  return <Card>
    <CardHeader title={`Kiosk: ${kiosk?.serialDetail.serialNumber}`}/>
    <CardContent>
      <ButtonGroup>
        <Button
          onClick={() => setEnterMethod(enterMethods.qr)}
          className={enterMethod === enterMethods.qr ? classes.on : classes.off}
          variant="outlined"
          disableElevation
        >
          QR Scan
          <QrCodeScannerIcon/>
        </Button>
        <Button
          onClick={() => setEnterMethod(enterMethods.keyboard)}
          className={enterMethod === enterMethods.keyboard ? classes.on : classes.off}
          variant="outlined"
          disableElevation
        >
          Keyboard
          <KeyboardIcon/>
        </Button>
      </ButtonGroup>
      <Grid className={classes.grid} container spacing={1}>
        {positions?.sort((pos1, pos2) => e430PosSort(pos1.position, pos2.position)).map((position) =>
          <Grid item xs={3}>
            <PositionChangeContainerController
              onComplete={onComplete}
              token={token}
              key={position.id}
              position={position}
              enterMethod={enterMethod}
            />
          </Grid>)}
      </Grid>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => history.push('/dashboard')}
      >Complete</Button>
    </CardContent>
  </Card>
}

export default ChangePositionContainersCard
