import React from 'react'
import { NewDashboardController } from '@myx/console-utils'
import { usePermissions } from '../permissions'
import imageMap from '../assets/ingredients'
type Props = {
  match: any,
}

const imageMapType: {[index: number]:any} = imageMap

function OperatorDashboardController(props: Props) {
  const permissions = usePermissions()

  return <NewDashboardController
    match={props.match}
    userCanOnlyScanContainers={permissions.userCanOnlyScanContainers()}
    userCanAccessUsers={permissions.userCanAccessUsers()}
    imageMap={imageMapType}
  />
}

export default OperatorDashboardController
