/* eslint-disable global-require */
// note that supplements (75,79,80,82) have a different aspect ratio ... @todo match aspect ratio of images
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  1: require('./1-waterstill-sm.png'),
  2: require('./2-watersparkling-sm.png'),
  27: require('./27-sugarcane-sm.png'),
  28: require('./28-monkfruit-sm.png'),
  11: require('./57-apple-sm.png'),
  10: require('./59-blacktea-sm.png'),
  12: require('./62-blueberry-sm.png'),
  7: require('./7-citric.png'),
  32: require('./82-cbd-sm.png'),
  33: require('./59-blacktea-sm.png'),
  13: require('./63-cranberry-sm.png'),
  31: require('./81-dragonfruit-sm.png'),
  29: require('./80-electrolytes-sm.png'),
  5: require('./75-energy-sm.png'),
  26: require('./77-ginger-sm.png'),
  30: require('./77-ginger-sm.png'),
  25: require('./77-ginger-sm.png'),
  9: require('./60-greentea-sm.png'),
  16: require('./64-lemon-sm.png'),
  8: require('./64-lemon-sm.png'),
  17: require('./65-lime-sm.png'),
  18: require('./66-mango-sm.png'),
  14: require('./58-orange-sm.png'),
  19: require('./67-peach-sm.png'),
  20: require('./68-pomegranate-sm.png'),
  21: require('./69-raspberry-sm.png'),
  15: require('./70-strawberry-sm.png'),
  6: require('./79-immunity-sm.png'),
}
