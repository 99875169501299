import React, { BaseSyntheticEvent, useEffect } from 'react'

import { Link as RouterLink, useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PersonIcon from '@material-ui/icons/Person'

import { Box, useTheme } from '@material-ui/core'
import themeContext from '../context/ThemeProvider'
import { signOut } from '../backend'
import ThemeToggle from './theme/ThemeToggle'
import { UserSession } from '../@types/UserSession'

// regarding z-index below - drawer is 1200, and when temporary it's 1300, so initially set + 101 = 1301 (1400 prob safest)
// however this caused an issue with top bar not being covered by modal/popups and menus spilling out, so now add +1
const useStyles = makeStyles(theme => ({
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

type Props = {
  pageUrl: string,
  session: UserSession
}

function UserAvatarMenu({ pageUrl, session }: Props) {
  const { handleThemeChange, dark } = themeContext.useThemeContext()
  const theme = useTheme()
  const classes = useStyles(theme)
  const [ anchorEl, setAnchorEl ] = React.useState(null)

  const history = useHistory()

  function handleClick(event: BaseSyntheticEvent) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const handleSignOut = () => {
    handleClose()
    signOut()
    history.push('/sign-in')
  }

  // close menu when user goes between pages
  useEffect(() => {
    handleClose()
  }, [ pageUrl ])

  return (
    <React.Fragment>
      <Box
        style={{
          display: 'flex',
          cursor: 'pointer',
          marginLeft: theme.spacing(1),
          alignItems: 'center'
        }}
        onClick={handleClick}>
        <Box className={classes.userInfo}>
          <Typography variant="body1">
            {session.givenName} {session.familyName}
          </Typography>
          <Typography variant="body2">
            {session.email}
          </Typography>
        </Box>
        <Box>
          <Avatar aria-haspopup="true" aria-controls="user-menu">
            <PersonIcon/>
          </Avatar>
        </Box>
      </Box>
      <Menu
        id="user-menu"
        style={{ zIndex: 1400 }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <RouterLink to="/account">
          <MenuItem>
            <Typography>My Account</Typography>
          </MenuItem>
        </RouterLink>
        <MenuItem onClick={handleSignOut}>
          <Typography>Sign Out</Typography>
        </MenuItem>
        <MenuItem>
          <ThemeToggle
            handleThemeChange={handleThemeChange}
            isDark={dark}
          />
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default UserAvatarMenu
