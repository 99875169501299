import React from 'react'
import { useSessionContext } from '../components/SessionContext'
import { useAsync } from 'react-async-hook'
import { graphQLFetch } from '@myx/console-utils'
import ProgressWrapper from '../components/ProgressWrapper'
import IngredientSubscriptionGrid from './IngredientSubscriptionGrid'
import { useAppSnack } from '../components/AppSnack'

function IngredientSubscriptionPage() {

  const { ingredients } = useSessionContext()

  const showSnack = useAppSnack()
  // eslint-disable-next-line no-warning-comments
  // todo ingredients should be filtered based on archived or other filters..
  const ingredientsFiltered = { ...ingredients }
  delete ingredientsFiltered['1']
  delete ingredientsFiltered['8']

  const ingredientSubscriptionAsync = useAsync(() => {
    return graphQLFetch(`
      query {
        getCompanyIngredientSubscriptions(map: [ { key: "orderBy", value: "ingredientDetail.name" } ]) {
          id
          ingredientId
          quantity
          ingredientDetail {
            id
            name
          }
        }

        getCompanyIngredientRecommendation {
          ingredientId
          recommendation
          ingredientDetail {
            id
            name
          }
        }
      }
    `, {})
      .then(({ getCompanyIngredientSubscriptions, getCompanyIngredientRecommendation }) => ({
        getCompanyIngredientSubscriptions,
        getCompanyIngredientRecommendation
      }))
  }, [])

  return (
    <>
      <ProgressWrapper pending={ingredientSubscriptionAsync.loading || !ingredientSubscriptionAsync.result}>
        {ingredientSubscriptionAsync.result && <IngredientSubscriptionGrid
          ingredients={ingredientsFiltered}
          recommendedIngredients={ingredientSubscriptionAsync.result.getCompanyIngredientRecommendation.map((recommendation) => {
            return {
              quantity: recommendation.recommendation,
              ...recommendation
            }
          })}
          ingredientSubscriptions={ingredientSubscriptionAsync.result.getCompanyIngredientSubscriptions}
          onComplete={(ingredientCart) => {
            graphQLFetch(
              `
            mutation ($data: [UpdateCompanyIngredientSubscriptionInput!]!) {
              updateCompanyIngredientSubscription(updates: $data) {
                id
              }
            }
        `,
              {
                data: ingredientCart.map(ingCart => {
                  return {
                    ingredientId: ingCart.ingredientId,
                    quantity: ingCart.quantity,
                  }
                })
              }
            ).then(__ => {
              ingredientSubscriptionAsync.execute()
              showSnack('Order updated')
            }).catch((__) => showSnack('Order Update Failed!'))
          }
          }
        />
        }
      </ProgressWrapper>
    </>)
}

export default IngredientSubscriptionPage
