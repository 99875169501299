import React from 'react'
import { useAsync } from 'react-async-hook'

import CircularProgress from '@material-ui/core/CircularProgress'

import AppFormTextField from '../form/AppFormTextField'
import AppFormSelectField from '../form/AppFormSelectField'

import { graphQLFetch } from '@myx/console-utils'

function KioskAssetDialog() {

  const accountDataAsync = useAsync(() => graphQLFetch(`
    query {
      getAccounts(
        map: [],
        limit: 100,
        offset: 0
      ) {
        accounts {
          id
          accountCode
          name
        }
      }
    }
  `, {}).then(({
    getAccounts: { accounts },
  }) => (
    accounts
  )), [])

  if (accountDataAsync.loading || !accountDataAsync.result) {
    return (
      <CircularProgress/>
    )
  }

  const accountOptions = [] // array will preserve numeric keys vs objects
  accountDataAsync.result.forEach(account => {
    accountOptions[account.id] = `${account.name} (${account.accountCode})`
  })

  return (
    <>
      <AppFormTextField
        name="assetCode"
        label="Asset Code"
        helperText="Optional unique identifier to appear in reports"
      />
      <AppFormSelectField
        name="accountId"
        label="Account"
        options={accountOptions}
        emptyOptionLabel="None"
        helperText="Associate this kiosk with a client account"
      />
    </>
  )
}

export default KioskAssetDialog
