import React from 'react'
import Box from '@material-ui/core/Box'

import AppFormTextField from '../form/AppFormTextField'
import AppFormStateField from '../form/AppFormStateField'
import AppFormCountryField from '../form/AppFormCountryField'

function AccountFormDialog() {
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box mr={1} flex={1} style={{ maxWidth: '25%' }}>
          <AppFormTextField
            name="accountCode"
            inputProps={{
              maxLength: 6,
            }}
            required
            label="Account Code"
            helperText="2-6 Character Identifier"
          />
        </Box>
        <Box ml={1} flex={1}>
          <AppFormTextField
            name="name"
            required
            label="Account Name"
            helperText="Full Business Name of Account"
          />
        </Box>
      </Box>
      <AppFormTextField
        name="address1"
        required
        label="Street Address"
      />
      <Box display="flex" justifyContent="space-between">
        <Box mr={1} flex={1}>
          <AppFormTextField
            name="address2"
            label="Suite Number, Building, etc"
          />
        </Box>
        <Box ml={1} flex={1}>
          <AppFormTextField
            name="address3"
            label="Care-of, Attention, etc"
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box mr={1} flex={1}>
          <AppFormTextField
            name="city"
            required
            label="City"
          />
        </Box>
        <Box ml={1} flex={1}>
          <AppFormStateField
            name="state"
            required
            label="State/Province"
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box mr={1} flex={1}>
          <AppFormCountryField
            name="country"
            required
            label="Country"
          />
        </Box>
        <Box ml={1} flex={1}>
          <AppFormTextField
            name="zip"
            required
            label="Zip/Postal"
          />
        </Box>
      </Box>
    </>
  )
}

export default AccountFormDialog
