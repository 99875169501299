import React from 'react'
import { Avatar, Paper, TableCell, Tooltip, useMediaQuery, useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Grid from '@material-ui/core/Grid'
import { AvatarGroup } from '@material-ui/lab'
import Co2Icon from '../icons/Co2Icon'
import InvertColorsOffIcon from '@material-ui/icons/InvertColorsOff'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import KioskLink from '../link/KioskLink'
import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/core/styles'
import { green, red } from '@material-ui/core/colors'

type Props = {
  kiosks: Array<any>,
  title: string,
  imageMap: { [index: number]: any },
}

type RowProps = {
  kiosk: any,
  isSmallDevice: boolean,
  imageMap: { [index: number]: any },
}

type ContainerItemProps = {
  position: number,
  ingredient: any,
  // container: any,
  faultCode: Array<string>,
  imageMap: { [index: number]: any },
}

type AlertItemProps = {
  faultCode: Array<string>,
  id: string,
}

function OverlaySpan(props: any) {
  return (
    <span style={{ color: (props.color || 'black'), display: 'flex', justifyContent: 'center' }}>
      {props.children}
    </span>
  )
}

const getHumanReadableCode = (faultCode: string): string => faultCode.replace(/_/g, ' ')

const getOverlaySpan = (faultCode: Array<string>) => {
  return <React.Fragment>{faultCode.map((fault) => getOverlaySpanForFault(fault))}</React.Fragment>
}

const getOverlaySpanForFault = (faultCode: string) => {
  switch (faultCode) {
  case 'CONTAINER_OUT':
    return <OverlaySpan color="red">EMPTY</OverlaySpan>
  case 'CONTAINER_LOW':
    return <OverlaySpan color="orange">LOW</OverlaySpan>
  case 'CONTAINER_EXPIRED':
    return <OverlaySpan color="red">EXPIRED</OverlaySpan>
  case 'CONTAINER_EXPIRES_SOON':
    return <OverlaySpan color="orange">EXPIRES SOON</OverlaySpan>
  default:
    return null
    break
  }
}

const getAvatarForFaultCode = (id: string, faultCode: Array<string>) => {
  if (faultCode[0].startsWith('WATER')) {
    return <Avatar key={id}>
      <InvertColorsOffIcon color="primary"/>
    </Avatar>
  }
  if (faultCode[0].startsWith('CO2')) {
    return <Avatar key={id}><Co2Icon/></Avatar>
  }
  return <Avatar key={id} style={{ color: 'red', backgroundColor: 'unset' }}><ErrorOutlineIcon/></Avatar>
}

function AlertItem(props: AlertItemProps) {
  return (
    <Box display="flex" justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
      {getAvatarForFaultCode(props.id, props.faultCode)}
      <Typography>{getHumanReadableCode(props.faultCode[0])}</Typography>
    </Box>
  )
}

function ContainerItem(props: ContainerItemProps) {
  return (
    <Box display="flex" justifyContent={'center'} alignItems={'center'} flexDirection={'column'} boxShadow={1}>
      <Typography>Position: {props.position}</Typography>
      <Avatar style={{ backgroundColor: 'white', margin: '8px 0px 1px' }}
        src={props.imageMap[props.ingredient.id as number]}/>
      {getOverlaySpan(props.faultCode)}
      <Typography>{props.ingredient.name}</Typography>
    </Box>
  )
}

type ContainerAvatarGroupProps = {
  kioskId: number,
  inventoryAlerts: Array<any>,
  serviceAlerts: Array<any>,
  onClick: () => void,
  imageMap: { [index: number]: any },
}

function ContainerAvatarGroup(props: ContainerAvatarGroupProps) {
  return <AvatarGroup key={props.kioskId} max={4} onClick={props.onClick}>
    {props.inventoryAlerts.map((alert) =>
      <Tooltip key={alert.id} title={alert.ingredientContainerDetail?.ingredientDetail?.name}>
        <Avatar key={alert.id} style={{ backgroundColor: 'white' }}
          src={props.imageMap[alert.ingredientContainerDetail?.ingredientDetail.id as number]}/>
      </Tooltip>)
    }
    {
      props.inventoryAlerts.length < 1 && props.serviceAlerts.map((alert) => getAvatarForFaultCode(alert.id, alert.faultCode))
    }
  </AvatarGroup>
}

const useStyles = makeStyles((theme) => ({
  connected: {
    color: green[800],
  },
  offline: {
    color: red[800],
  },
}))

function AlertRow(props: RowProps) {
  const classes = useStyles()

  const [ open, setOpen ] = React.useState(false)

  return (
    <React.Fragment>
      <TableRow>
        {props.isSmallDevice
          ? <React.Fragment>
            <TableCell>
              <KioskLink kiosk={props.kiosk}>{props.kiosk.assetCode || props.kiosk.serialNumber}</KioskLink>
            </TableCell>
            <TableCell>{props.kiosk.inventoryAlerts.length + props.kiosk.serviceAlerts.length}</TableCell>
          </React.Fragment>
          : <React.Fragment>
            <TableCell>
              <KioskLink kiosk={props.kiosk}>{props.kiosk.serialNumber}</KioskLink>
            </TableCell>
            <TableCell>{props.kiosk.assetCode}</TableCell>
            <TableCell>
              {props.kiosk.onlineDetail.online
                ? <CheckIcon className={classes.connected} fontSize="small"/>
                : <WarningIcon className={classes.offline} fontSize="small"/>
              }
            </TableCell>
            <TableCell>{props.kiosk.inventoryAlerts.length}</TableCell>
            <TableCell>{props.kiosk.serviceAlerts.length}</TableCell>
          </React.Fragment>
        }
        <TableCell>
          {
            open
              ? <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                <KeyboardArrowUpIcon/>
              </IconButton>
              : <ContainerAvatarGroup
                kioskId={props.kiosk.id}
                imageMap={props.imageMap}
                onClick={() => setOpen(!open)}
                inventoryAlerts={props.kiosk.inventoryAlerts}
                serviceAlerts={props.kiosk.serviceAlerts}
              />
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={props.isSmallDevice ? 3 : 6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {props.kiosk.serviceAlerts && props.kiosk.serviceAlerts.length > 0 &&
                <React.Fragment>
                  <Typography variant="h6" gutterBottom component="div">
                    Alerts
                  </Typography>
                  <Grid container spacing={1}>
                    {props.kiosk.serviceAlerts.map((serviceAlert: any) =>
                      <Grid key={serviceAlert.id} item xs={4}>
                        <AlertItem id={serviceAlert.id} faultCode={serviceAlert.faultCode}/>
                      </Grid>
                    )}
                  </Grid>
                </React.Fragment>
              }
              <Typography variant="h6" gutterBottom component="div">
                Containers
              </Typography>
              <Grid container spacing={1}>
                {props.kiosk.inventoryAlerts
                  .sort((pos1: any, pos2: any) => pos1.ingredientContainerDetail?.kioskPositionDetail?.position > pos2.ingredientContainerDetail?.kioskPositionDetail?.position)
                  .map((inventoryAlert: any) => <Grid key={inventoryAlert.id} item xs={4}>
                    <ContainerItem
                      faultCode={inventoryAlert.faultCode}
                      position={inventoryAlert.ingredientContainerDetail?.kioskPositionDetail?.position}
                      ingredient={inventoryAlert.ingredientContainerDetail?.ingredientDetail}
                      imageMap={props.imageMap}
                    />
                  </Grid>
                  )
                }
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

function KiosksInventoryAlertTable(props: Props) {

  const theme = useTheme()

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {props.title}
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          {isSmallDevice
            ? <colgroup>
              <col style={{ width: '25%' }}/>
              <col style={{ width: '25%' }}/>
              <col style={{ width: '50%' }}/>
            </colgroup>
            : <colgroup>
              <col style={{ width: '15%' }}/>
              <col style={{ width: '25%' }}/>
              <col style={{ width: '10%' }}/>
              <col style={{ width: '15%' }}/>
              <col style={{ width: '15%' }}/>
              <col style={{ width: '20%' }}/>

            </colgroup>
          }
          <TableHead>
            {isSmallDevice
              ? <TableRow>
                <TableCell>Kiosk</TableCell>
                <TableCell>Total Alerts</TableCell>
                <TableCell>Alerts</TableCell>
                <TableCell/>
              </TableRow>
              : <TableRow>
                <TableCell>Kiosk Serial #</TableCell>
                <TableCell>Asset</TableCell>
                <TableCell>Online</TableCell>
                <TableCell>Container Alerts</TableCell>
                <TableCell>Service Alerts</TableCell>
                <TableCell>Alerts</TableCell>
                <TableCell/>
              </TableRow>
            }
          </TableHead>
          <TableBody>
            {props.kiosks.filter(it => it.onlineDetail.online).map((kiosk) =>
              <AlertRow key={kiosk.id} imageMap={props.imageMap} isSmallDevice={isSmallDevice} kiosk={kiosk}/>)
            }
            {props.kiosks.filter(it => !it.onlineDetail.online).map((kiosk) =>
              <AlertRow key={kiosk.id} imageMap={props.imageMap} isSmallDevice={isSmallDevice} kiosk={kiosk}/>)
            }
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default KiosksInventoryAlertTable
