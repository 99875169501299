import React from 'react'
import Panel from '../components/Panel'
import ContainerTable from '../components/tables/ContainerTable'
import { useDataFilter } from '../components/filter/DataFilter'

function ContainersView({ tableState, ingredients, containersFetch = null }) {

  const openFilterDialog = useDataFilter(tableState, {
    mfgSerialNumber: 'Serial Number',
    manufactureLotCode: 'Lot Code',
    kiosk: 'Kiosk', // kiosk serial number
    soonestExpireOn: {
      label: 'Expiry',
      dateRange: true
    },
    ingredientId: {
      label: 'Ingredient',
      options: ingredients
    }
  })

  return (<Panel
    title="Containers"
    hasFilter={tableState.hasFilter}
    onFilterClick={() => {
      openFilterDialog()
    }}
  >
    <ContainerTable
      containersFetch={containersFetch}
      tableState={tableState}
      ingredients={ingredients}
    />
  </Panel>)
}

export default ContainersView
