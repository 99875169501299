import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import DateDisplay from '../format/DateDisplay'

function ContainerDatesCard({ container }) {
  return (
    <Card>
      <CardHeader title="Key Dates" />
      <CardContent>
        <Table size="medium">
          <colgroup>
            <col style={{ width: '35%' }}/>
            <col style={{ width: '65%' }}/>
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Low Date</TableCell>
              <TableCell>
                <DateDisplay dt={container.lowOn} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Empty Date</TableCell>
              <TableCell>
                <DateDisplay dt={container.outOn} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Expiration Date</TableCell>
              <TableCell>
                <DateDisplay dt={container.soonestExpireOn} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Puncture Date</TableCell>
              <TableCell><DateDisplay dt={container.punctureOn} /></TableCell>
            </TableRow>
            {/*
            // remove for now per PD - currently TBD how this value will be obtained from shipper integration
            <TableRow>
              <TableCell>Delivery Date</TableCell>
              <TableCell><DateDisplay dt={null} />TBD</TableCell>
            </TableRow>
            */}
            <TableRow>
              <TableCell>Shipped Date</TableCell>
              <TableCell><DateDisplay dt={container.shippedOn} /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Order Date</TableCell>
              <TableCell><DateDisplay dt={container.purchasedOn} /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Production Date</TableCell>
              <TableCell><DateDisplay dt={container.productionOn} /></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default ContainerDatesCard
