import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { useAsyncCallback } from 'react-async-hook'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useAppSnack } from './AppSnack'
import { getReportURL } from '@myx/console-utils'

const useStyles = makeStyles(theme => ({
  spinnerContainer: {
    position: 'relative',
    display: 'flex', // ensure child content negative margin does not spill out
    flexDirection: 'column'
  },
  spinnerContainerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.75)',
    opacity: 0, // hide by default
    transition: 'opacity 0.2s ease-out',
    pointerEvents: 'none', // allow clicks on content just in case

    '&[data-active=true]': {
      opacity: 1
    }
  },
}))

/**
 * Provides a Formik context for a reports form that gets a token onSubmit and then rigs up the
 * RESTful report download.
 */
function ReportForm(props) {
  const showSnack = useAppSnack()
  const classes = useStyles()

  // form action async state
  const formActionAsync = useAsyncCallback(props.tokenAction, [])

  // listen for form action success
  useEffect(() => {
    if (!formActionAsync.result) {
      return
    }

    const reportToken = formActionAsync.result

    showSnack('Report ready for download')

    const anchor = document.createElement('a')
    document.body.appendChild(anchor)

    anchor.href = getReportURL(reportToken)
    anchor.download = true
    anchor.click()
    document.body.removeChild(anchor)

  }, [ formActionAsync.result, showSnack ])

  // handle error with report token retrieval
  useEffect(() => {
    if (!formActionAsync.error) {
      return
    }

    showSnack('Error generating report')

  }, [ formActionAsync.error, showSnack ])

  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={(values, form) => {
        form.setSubmitting(false) // clear internal flag
        formActionAsync.execute(values)
      }}
    >
      {(form) =>
        <div className={classes.spinnerContainer}>
          <Form>
            {props.children(form)}
          </Form>
          <div className={classes.spinnerContainerOverlay} data-active={!!formActionAsync.loading}>
            <CircularProgress/>
          </div>
        </div>
      }
    </Formik>
  )
}

export default ReportForm
