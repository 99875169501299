import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import { graphQLFetch, useAppModal } from '@myx/console-utils'
import AppFormTextField from '../form/AppFormTextField'

function SettingsBusinessDetailsCard({ operator, onComplete }) {
  const openEditDialog = useAppModal(
    {
      title: 'Edit Details',
      resultMessage: 'Business name saved successfully',
      initialValues: {
        ...operator
      },
      action: (formData) => graphQLFetch(
        `
          mutation ($data: UpdateCompanyNameInput!) {
            updateCompanyName(update: $data) {
              id
              name
            }
          }
        `,
        {
          data: {
            name: formData.name
          }
        }
      ),
      onComplete
    },
    <>
      <AppFormTextField
        name="name"
        label="Business Name"
        helperText="Operating name of your business"
      />
    </>
  )

  return (
    <Card>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            onClick={openEditDialog}
          >
            <EditIcon/>
          </IconButton>
        }
        title="Business Details"
      />
      <CardContent>
        <Table size="medium">
          <colgroup>
            <col style={{ width: '25%' }}/>
            <col style={{ width: '75%' }}/>
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Business Name</TableCell>
              <TableCell>{operator.name || '—'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default SettingsBusinessDetailsCard
