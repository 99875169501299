import React from 'react'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import { DateTime } from 'luxon'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    fontSize: '1em'
  },
}))

export const DateTimeToolTip = ({ dateTime, title, children }) => <Tooltip
  arrow
  classes={{
    tooltip: useStyles().customTooltip
  }}
  title={`${(title || ' Since: ')}
${dateTime ? DateTime.fromISO(dateTime).toFormat('yyyy-MM-dd hh:mm:ss a') : ' Never'}`}
>
  {children}
</Tooltip>

export default DateTimeToolTip
