import React from 'react'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

const BuiltWithLove = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Built with love by the <Link color="inherit" href="https://myxdrinks.com/"> MYX Drinks</Link> team test
    </Typography>
  )
}

export default BuiltWithLove
