import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

function KioskActivityCard({ kiosk }) {
  return (
    <Card>
      <CardHeader title="Activity" />
      <CardContent>
        <Table size="medium">
          <colgroup>
            <col style={{ width: '50%' }}/>
            <col style={{ width: '50%' }}/>
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>1-Day Pours</TableCell>
              <TableCell>{kiosk.pour1Day}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>30-Day Pours</TableCell>
              <TableCell>{kiosk.pour30Days}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )

}

export default KioskActivityCard
