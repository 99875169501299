import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import DateDisplay from '../format/DateDisplay'

const useStyles = makeStyles((theme) => ({
  noWrap: {
    whiteSpace: 'nowrap',
  },
}))

function ContainerHistoryTable({ history }) {
  const classes = useStyles()

  return (
    <Table size="medium">
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>User</TableCell>
          <TableCell>Ingredient</TableCell>
          <TableCell>Action</TableCell>
          <TableCell>Kiosk</TableCell>
          <TableCell>Kiosk Position</TableCell>
          <TableCell>Fill %</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(history.length === 0) &&
          <TableRow>
            <TableCell colSpan="7"><em>No Container History Records</em></TableCell>
          </TableRow>
        }
        {history.map((row, index) => (
          <TableRow key={index}>
            <TableCell className={classes.noWrap}>
              <DateDisplay dt={row.changedOn} />
            </TableCell>
            <TableCell>
              {row.changedByUser &&
                `${row.changedByUser.givenName || ''} ${row.changedByUser.familyName || ''}`
              }
            </TableCell>
            <TableCell>
              {row.ingredientDetail && row.ingredientDetail.name}
            </TableCell>
            <TableCell>
              {row.action || ''}
            </TableCell>
            <TableCell>
              {(row.kioskPositionDetail && row.kioskPositionDetail.kioskDetail && row.kioskPositionDetail.kioskDetail.serialDetail)
                ? row.kioskPositionDetail.kioskDetail.serialDetail.serialNumber
                : '—'
              }
            </TableCell>
            <TableCell>
              {(row.kioskPositionDetail && row.kioskPositionDetail.position)
                ? row.kioskPositionDetail.position
                : '—'
              }
            </TableCell>
            <TableCell>
              {`${row.remainingPercent}%`}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )

}

export default ContainerHistoryTable
