import React from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { Paper } from '@material-ui/core'
import KiosksInventoryAlertTable from './KiosksInventoryAlertTable'
import ProgressWrapper from '../ProgressWrapper'

type Props = {
  totalKiosks: number,
  totalContainers: number,
  totalUsers: number,
  userCanAccessUsers: boolean,
  kioskInventoryAlerts: Array<any> | undefined,
  imageMap: { [index: number]: any },
}

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeightPaper: { // chart's ResponsiveContainer uses % https://github.com/recharts/recharts/issues/854
    padding: theme.spacing(2),
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    flexDirection: 'column',
    height: theme.spacing(30),
  },
  tableActions: {
    marginTop: theme.spacing(1),
  },
  noAlerts: {
    paddingTop: theme.spacing(1),
  },
}))

function NewDashboardView(props: Props) {
  const classes = useStyles()

  const history = useHistory()
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h2" color="primary">
              Kiosks
            </Typography>
            <Box display="flex" justifyContent="center" mt={3}>
              <Typography variant="h2" color="textSecondary" component="h2">
                {props
                  ? <span>{props.totalKiosks}</span>
                  : <CircularProgress/>
                }
              </Typography>
            </Box>
          </CardContent>
          <CardActions>
            <Button color="primary" onClick={() => history.push('/kiosks')}>
              View Kiosks
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h2" color="primary">
              Containers
            </Typography>
            <Box display="flex" justifyContent="center" mt={3}>
              <Typography variant="h2" color="textSecondary" component="h2">
                {props
                  ? <span>{props.totalContainers}</span>
                  : <CircularProgress/>
                }
              </Typography>
            </Box>
          </CardContent>
          <CardActions>
            <Button color="primary" onClick={() => history.push('/containers')}>
              View Containers
            </Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h2" color="primary">
              Users
            </Typography>
            <Box display="flex" justifyContent="center" mt={3}>
              <Typography variant="h2" color="textSecondary" component="h2">
                {props.totalUsers
                  ? <span>{props.totalUsers}</span>
                  : <CircularProgress/>
                }
              </Typography>
            </Box>
          </CardContent>
          <CardActions>
            {props.userCanAccessUsers &&
              <Button color="primary" onClick={() => history.push('/users')}>
                View Users
              </Button>
            }
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {
            // show sorted if companyId 1 is detected
            <ProgressWrapper pending={!props.kioskInventoryAlerts}>
              {props.kioskInventoryAlerts &&
                <Box>
                  <KiosksInventoryAlertTable
                    title="Kiosks Requiring Service"
                    kiosks={props.kioskInventoryAlerts.filter(it => it.companyDetail.id !== 1 && it.companyDetail.id !== 21) || []}
                    imageMap={props.imageMap}
                  />
                  {props.kioskInventoryAlerts.filter(it => it.companyDetail.id === 1).length > 0 &&
                    <KiosksInventoryAlertTable
                      title="HQ Kiosks Requiring Service"
                      kiosks={props.kioskInventoryAlerts.filter(it => it.companyDetail.id === 1) || []}
                      imageMap={props.imageMap}
                    />
                  }
                  {props.kioskInventoryAlerts.filter(it => it.companyDetail.id === 21).length > 0 &&
                    <KiosksInventoryAlertTable
                      title="Warehouse Kiosks Requiring Service"
                      kiosks={props.kioskInventoryAlerts.filter(it => it.companyDetail.id === 21) || []}
                      imageMap={props.imageMap}
                    />
                  }
                </Box>
              }
            </ProgressWrapper>
          }
        </Paper>
      </Grid>

    </Grid>
  )
}

export default NewDashboardView
