import React, { useContext, useMemo } from 'react'
import { useAsync } from 'react-async-hook'

import { graphQLFetch, reportUnauthorizedAccess } from '@myx/console-utils'
import { userCanAccessConsole } from '../permissions'

/**
 * @typedef {{ id: string, companyId: string }} Session
 * @return {Promise<Session>}
 */
async function fetchSession() {

  const {
    getUser: data,
  } = await graphQLFetch(`
    {
      getUser {
        id
        companyId
        givenName
        familyName
        email
        roleDetails {
          roleCode
        }
      }
    }
  `)

  if (!userCanAccessConsole(data.roleDetails.map(role => role.roleCode))) {
    reportUnauthorizedAccess()
    throw new Error('Unauthorized')
  }

  const {
    getKioskStatuses: kioskStatusList,
    getIngredients: { ingredients: ingredientList }
  } = await graphQLFetch(`
    {
      getKioskStatuses(map: []) {
        id
        name
      }

      getIngredients(map: [], limit: 1000, offset: 0) {
        ingredients {
          id
          name
        }
      }
    }
  `)

  return {
    ...data,

    // convert list to object for fast-lookup
    roles: data.roleDetails.reduce((acc, roleDetail) => {
      return {
        ...acc,
        [roleDetail.roleCode]: true
      }
    }, {}),

    // convert list to map
    kioskStatuses: kioskStatusList.reduce((acc, entry) => {
      return {
        ...acc,
        [entry.id]: entry.name
      }
    }, {}),

    // convert list to map
    ingredients: ingredientList.reduce((acc, entry) => {
      return {
        ...acc,
        [entry.id]: entry.name
      }
    }, {})
  }
}

/** @type {Session | null} */
const defaultSessionValue = null
const SessionContext = React.createContext(defaultSessionValue)

export function SessionProvider({ children }) {

  const sessionAsync = useAsync(fetchSession, [], {
    // preserve previously loaded data while new data is being fetched
    setLoading: state => ({ ...state, loading: true })
  })

  const [ refreshTimer, setRefreshTime ] = React.useState(null)

  React.useEffect(() => {
    if (sessionAsync.result) {
      if (refreshTimer !== null) {
        clearTimeout(refreshTimer)
      }
    } else {
      setRefreshTime(prevState => {
        if (prevState === null) {
          return setTimeout(() => {
            sessionAsync.execute()
            clearTimeout(refreshTimer)
            setRefreshTime(null)
          }, 12000)
        }
        return prevState
      })
    }
  }
  , [ refreshTimer, sessionAsync ])
  // coalesce to null if not loaded yet
  const contextValue = useMemo(() => {
    return (sessionAsync.result
      ? {
        ...sessionAsync.result,
        refresh: () => sessionAsync.execute(),
      }
      : null
    )
  }, [ sessionAsync ])

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  )
}

export function useSessionLoadedStatus() {
  return useContext(SessionContext) !== null
}

export function useSessionContext() {
  const session = useContext(SessionContext)

  // fail-fast since this is used in places that render only after session loads
  if (session === null) {
    throw new Error('session context is not ready yet')
  }

  return session
}
