import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

function KioskLink(props: any) {
  const { kiosk, ...otherProps } = props
  return (kiosk
    ? <RouterLink
      to={`/kiosks/-online/${encodeURIComponent(kiosk.id)}`}
      {...otherProps}
    /> : null
  )
}

export default KioskLink
