import React from 'react'

import Box from '@material-ui/core/Box'
import AppFormTextField from '../form/AppFormTextField'
import AppFormPhoneCountryField from '../form/AppFormPhoneCountryField'

function MyAccountProfileDialog() {
  return (
    <>
      <Box display="flex">
        <Box mr={1} flex={1}>
          <AppFormTextField
            name="givenName"
            label="Given Name"
            required
          />
        </Box>
        <Box ml={1} flex={1}>
          <AppFormTextField
            name="familyName"
            label="Family Name"
            required
          />
        </Box>
      </Box>
      <AppFormTextField
        name="email"
        type="email"
        label="Contact Email"
        required
      />
      <Box display="flex">
        <Box mr={1} flex={1}>
          <AppFormPhoneCountryField
            name="phoneCountry"
            label="Country Code"
          />
        </Box>
        <Box mr={1} ml={1} flex={1}>
          <AppFormTextField
            name="phone"
            label="Phone"
          />
        </Box>
        <Box ml={1} flex={1}>
          <AppFormTextField
            name="phoneExtension"
            label="Extension"
          />
        </Box>
      </Box>
    </>
  )
}

export default MyAccountProfileDialog
