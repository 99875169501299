import React, { useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppSnack } from './AppSnack'
import { backendErrors, signOut, useAppModal } from '@myx/console-utils'

/**
 * Listen for backend auth errors and report them as modal
 * or simple redirect to homepage.
 */
export function useAuthErrorListener() {
  const history = useHistory()
  const routerLocation = useLocation()

  const openAuthExpiryDialog = useAppModal(
    {
      title: 'Signed Out',
      action: () => {
        history.push('/sign-in')

        // dummy success
        return Promise.resolve()
      },
      actionLabel: 'Sign In',
      resultMessage: false // suppress default snack
    },
    <span>You are signed out</span>
  )

  // avoid re-triggering effect from location changes
  const routerLocationRef = useRef(routerLocation)
  routerLocationRef.current = routerLocation

  useEffect(() => {
    const authListener = () => {
      if (routerLocationRef.current.pathname === '/') {
        history.push('/sign-in')
      }
      openAuthExpiryDialog()
    }

    backendErrors.on('authError', authListener)

    // clean-up handler
    return () => {
      backendErrors.off('authError', authListener)
    }
  }, [ history, openAuthExpiryDialog ])

  // listen for unauthorized access and redirect to myxdrinks.com
  useEffect(() => {
    const errorListener = () => {
      setTimeout(() => {
        signOut()
        window.location.assign('https://myxdrinks.com')
      }
      , 500)
    }

    backendErrors.on('unauthorizedAccessError', errorListener)

    // clean-up handler
    return () => {
      backendErrors.off('unauthorizedAccessError', errorListener)
    }
  }, [])

}

/**
 * Listen for generic network errors and report them to user on snackbar.
 */
export function useNetworkErrorListener() {
  const showSnack = useAppSnack()

  const recentErrorCooldownRef = useRef(null)

  useEffect(() => {
    const errorListener = () => {
      // debounce if several errors happen in a row
      if (recentErrorCooldownRef.current) {
        return
      }

      recentErrorCooldownRef.current = setTimeout(() => {
        recentErrorCooldownRef.current = null
      }, 200)

      // show the debounced error
      showSnack('Error with network request')
    }

    backendErrors.on('networkError', errorListener)

    // clean-up handler
    return () => {
      backendErrors.off('networkError', errorListener)
    }
  }, [ showSnack ])
}
