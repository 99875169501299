import React from 'react'
import KioskAlertsPanel from './KioskAlertsPanel'
import { graphQLFetch } from '@myx/console-utils'
import { useAsync } from 'react-async-hook'
import ProgressWrapper from '../components/ProgressWrapper'

function KioskAlertsPanelController(props) {
  const alertDataAsync = useAsync(() => {
    return graphQLFetch(`
      query($id: String!) {
        getKioskFaultIncidents(
          map: [],
          limit: 1000,
          offset: 0,
          kioskId: $id
        ) {
          total
          kioskFaultIncidents {
            id
            kioskId
            faultCode
            occurredOn
            changedOn
            command
            changedBy
          }
        }

        getKioskContainerFaultIncidents(
          map: [],
          limit: 1000,
          offset: 0,
          kioskId: $id
        ) {
          total
          kioskContainerFaultIncidents {
            id
            kioskId
            faultCode
            occurredOn
            changedOn
            command
            changedBy
            ingredientContainerDetail {
              id
              mfgSerialNumber
              manufactureLotCode
              ingredientDetail {
                name
              }
            }
          }
        }
      }
    `, {
      id: props.kioskId,
    }).then(({
      getKioskFaultIncidents: { kioskFaultIncidents: serviceAlerts },
      getKioskContainerFaultIncidents: { kioskContainerFaultIncidents: inventoryAlerts },
    }) => (
      {
        serviceAlerts,
        inventoryAlerts,
      }
    ))
  }, [], {
    // preserve previously loaded data while new data is being fetched
    setLoading: state => ({ ...state, loading: true })
  })

  const { serviceAlerts, inventoryAlerts } = alertDataAsync.result || {}

  return (
    <ProgressWrapper pending={alertDataAsync.loading || !alertDataAsync.result}>
      {(serviceAlerts && inventoryAlerts) && <KioskAlertsPanel
        serviceAlerts={serviceAlerts}
        inventoryAlerts={inventoryAlerts}
      />}
    </ProgressWrapper>)
}

export default KioskAlertsPanelController
