import React from 'react'
import { Button, Card, CardContent, CardHeader, Chip } from '@material-ui/core'
import IngredientMenuCard from './IngredientMenuCard'
import { useAppModal } from '@myx/console-utils'

const MAX_CHIPS = 15

function ReOrderRecommendationCard({ recommendedIngredients, ingredientMenu, ingredients, onRecommendationUpdate, onComplete }) {

  const openRecommendationDialog = useAppModal({
    title: 'Based on your usage, we recommend...',
    resultMessage: 'Cart Updated',
    initialValues: {},
    action: (formData) => {
      onRecommendationUpdate(recommendedIngredients)
      return Promise.resolve()
    },
    onComplete,
    actionLabel: 'Update My Subscription',
  },
  <IngredientMenuCard
    gridProps={{ xs: 4 }}
    readOnly
    ingredientMenu={ingredientMenu}
    ingredientCart={recommendedIngredients}
  />
  )

  return (<Card>
    <CardHeader
      action={
        <Button onClick={openRecommendationDialog}>Learn more</Button>
      }
      title="Based on your usage MYX recommends"
    >
    </CardHeader>
    <CardContent>
      <div style={{
        textAlign: 'center'
      }}>
        {recommendedIngredients
          .map((recommendedIngredient, index) => {
            const returnChip = index === MAX_CHIPS - 1 && recommendedIngredients.length > MAX_CHIPS ? <Chip label={`And ${recommendedIngredients.length - MAX_CHIPS} More ...`}/> : <Chip label={`${recommendedIngredient.quantity}X ${recommendedIngredient.ingredientDetail.name}`} />
            return index < MAX_CHIPS ? returnChip : null
          })
        }
      </div>
    </CardContent>
  </Card>)
}

export default ReOrderRecommendationCard
