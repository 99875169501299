import React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { getTableFetchParams, graphQLFetch } from '@myx/console-utils'
import DataTable, { DataTableRowActions, DataTableSortLabel } from '../DataTable'
import DateDisplay from '../format/DateDisplay'
import { useMediaQuery, useTheme } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  noWrap: {
    whiteSpace: 'nowrap',
  },
  percentCell: {
    minWidth: 80,
    whiteSpace: 'nowrap'
  },
}))

function ContainerRowActions({ subMatch, container }) {
  const history = useHistory()

  return (
    <DataTableRowActions
      onDetailsClick={() => {
        history.push(`${subMatch.url}/${encodeURIComponent(container.id)}`)
      }}
    />
  )
}

function ContainerTable(props) {

  const classes = useStyles()

  const tableState = props.tableState

  const theme = useTheme()

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <DataTable
      variant={isSmallDevice ? 'mobile' : null}
      tableState={tableState}
      dataSource={() =>
        (props.containersFetch ? props.containersFetch(tableState) : graphQLFetch(`
          query($map: [MapEntryInput!]!, $limit: Long!, $offset: Long!) {
            getIngredientContainers(
              map: $map,
              limit: $limit,
              offset: $offset
            ) {
              total
              ingredientContainers {
                id
                ingredientId
                companyId
                punctureExpireOn
                shelfExpireOn
                soonestExpireOn
                mfgSerialNumber
                manufactureLotCode
                remainingPercent
                ingredientDetail {
                  name
                }
                kioskPositionDetail {
                  kioskDetail {
                    id
                    name
                    assetCode
                    serialDetail {
                      serialNumber
                    }
                  }
                }
              }
            }
          }
        `, getTableFetchParams(
          tableState,
          {
            mfgSerialNumber: [ 'string', 'mfgSerialNumber' ],
            manufactureLotCode: [ 'string', 'manufactureLotCode' ],
            name: [ 'string', 'ingredientDetail.name' ],
            kiosk: [ 'string', 'kioskPositionDetail.kioskDetail.serialDetail.serialNumber' ],
            soonestExpireOn: [ 'dateRange', 'soonestExpireOn' ],
            ingredientId: [ 'id', 'ingredientId' ]
          }
        ))).then(({ getIngredientContainers: { total, ingredientContainers: data } }) =>
          ({ total, data })
        )
      } // ingredientContainers > currentStatus wiped
    >
      {(data, subMatch) => <>
        {isSmallDevice ? <colgroup>
          <col style={{ width: '23%' }}/>
          <col style={{ width: '15%' }}/>
          <col style={{ width: '15%' }}/>
          <col style={{ width: '20%' }}/>
          <col style={{ width: '10%' }}/>

        </colgroup> : <colgroup>
          <col style={{ width: '10%' }}/>
          <col style={{ width: '15%' }}/>
          <col style={{ width: '25%' }}/>
          <col style={{ width: '10%' }}/>
          <col style={{ width: '10%' }}/>
          <col style={{ width: '10%' }}/>
          <col style={{ width: '10%' }}/>
        </colgroup>
        }
        <TableHead>
          <TableRow>
            <TableCell>
              <DataTableSortLabel column="mfgSerialNumber">
                Serial
              </DataTableSortLabel>
            </TableCell>
            {isSmallDevice ? null
              : <TableCell>
                <DataTableSortLabel column="manufactureLotCode">
                  Lot
                </DataTableSortLabel>
              </TableCell>}
            <TableCell>
              <DataTableSortLabel column="name">
                Name
              </DataTableSortLabel>
            </TableCell>
            <TableCell>
              <DataTableSortLabel column="kiosk">
                Kiosk
              </DataTableSortLabel>
            </TableCell>
            {isSmallDevice ? null
              : <TableCell className={classes.noWrap}>
                <DataTableSortLabel column="soonestExpireOn">
                  Expiry
                </DataTableSortLabel>
              </TableCell>}
            <TableCell className={classes.percentCell}>
              <DataTableSortLabel column="remainingPercent">
                Level
              </DataTableSortLabel>
            </TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data.map((row, rowIndex) => (
              // guard against empty serial as key
              <TableRow key={row.serial || `row${rowIndex}`}>
                <TableCell className={classes.noWrap}>{/* {row.companyId}- */}{row.mfgSerialNumber}</TableCell>
                {isSmallDevice ? null : <TableCell>{row.manufactureLotCode}</TableCell>}
                <TableCell>{row.ingredientDetail && row.ingredientDetail.name}</TableCell>
                <TableCell>
                  {(row.kioskPositionDetail && row.kioskPositionDetail.kioskDetail && row.kioskPositionDetail.kioskDetail.serialDetail)
                    ? row.kioskPositionDetail.kioskDetail.serialDetail.serialNumber
                    : '—'
                  }
                </TableCell>
                {isSmallDevice ? null
                  : <TableCell className={classes.noWrap}>
                    <DateDisplay dt={row.soonestExpireOn}/>
                  </TableCell>}
                <TableCell>
                  {row.remainingPercent
                    ? `${Math.floor(row.remainingPercent)}%`
                    : '—'
                  }
                </TableCell>
                <TableCell align="center">
                  <ContainerRowActions
                    subMatch={subMatch}
                    container={row}
                  />
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </>}
    </DataTable>
  )
}

export default ContainerTable
