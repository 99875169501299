import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAsync } from 'react-async-hook'
import { AUTH_BASE, storeTokens } from '../backend'
import { useHistory } from 'react-router'
import GoogleSigninView from './GoogleSigninView'

type Props = {
  registrationId: string
  googleSignInFetch?: Promise<Response>
}

function GoogleSigninController({ registrationId, googleSignInFetch = undefined }: Props) {
  const location = useLocation()

  const history = useHistory()

  const [ __, params ] = location.search.split('?')

  const cookie = document.cookie

  useEffect(() => {
    (googleSignInFetch || fetch(`https://${AUTH_BASE}/consumer-auth/login/oauth2/code/${registrationId}?${params.toString()}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Cookie': cookie,
      }
    })).then((response: Response) => {
      response.json().then((json: any) => {
        storeTokens(json.access_token, json.refresh_token)
        history.push('/dashboard')
      })
    }).catch((err: Error) => {
      history.push('/sign-in')
      throw err
    }
    )
  }, [])

  return (<GoogleSigninView/>)
}

export default GoogleSigninController
