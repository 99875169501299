import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

function ContainerDetailsCard({ container }) {
  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Table size="medium">
          <colgroup>
            <col style={{ width: '35%' }}/>
            <col style={{ width: '65%' }}/>
          </colgroup>
          <TableBody>
            {/*
            <TableRow>
              <TableCell>Manufacturer</TableCell>
              <TableCell>Coastal</TableCell>
            </TableRow>
            */}
            <TableRow>
              <TableCell>Serial Number</TableCell>
              <TableCell>{/* { container.companyId }- */}{ container.mfgSerialNumber }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lot Number</TableCell>
              <TableCell>{ container.manufactureLotCode || 'N/A' }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Average Drinks/Container</TableCell>
              <TableCell>400 {/* @todo hardcoded field per PD, to be obtained from query in future */}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Daily Usage Percent</TableCell>
              <TableCell>{ container.dailyPercent.toFixed(2) }</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default ContainerDetailsCard
