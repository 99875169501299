import React from 'react'
import { useAsync } from 'react-async-hook'
import { Field } from 'formik'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { graphQLFetch } from '@myx/console-utils'

function ContainerSerialNumberAutoComplete({ onChanged, additionalFilters, containerAsyncFetch = null }) {

  const containersAsync = useAsync(inputValue => {
    return (containerAsyncFetch || graphQLFetch(`
            query($map: [MapEntryInput!]!) {
                getIngredientContainers(map: $map, limit: 10, offset: 0) {
                    ingredientContainers {
                        id
                        mfgSerialNumber
                        remainingPercent
                        ingredientDetail {
                          id
                          name
                        }
                    }
                }
            }
        `, {
      map: [ ...additionalFilters, { key: 'li:mfgSerialNumber', value: `${inputValue}%` } ]
    })).then(({
      getIngredientContainers: { ingredientContainers: data },
    }) => data)
  }, [])

  const containers = containersAsync.result
  return (
    <Field name={'containerId'}>
      {
        (field, __) => <Autocomplete
          renderInput={(params) => <TextField
            {...params}{...field}
            onChange={(ev) => containersAsync.execute(ev.target.value)}
            variant="outlined"
          />}
          loading={containersAsync.loading}
          getOptionLabel={(container) => container.mfgSerialNumber}
          options={containers || []}
          onChange={onChanged}
        />
      }
    </Field>
  )
}

export default ContainerSerialNumberAutoComplete
