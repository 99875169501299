import React from 'react'
import { CircularProgress, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'

function GoogleSigninView() {

  return (<Box display="flex" justifyContent="center" flexDirection="column">
    <Box>
      <Typography align="center" variant="h4">Signing you in...</Typography>
    </Box>
    <Box marginTop="20px" display="flex" justifyContent="center">
      <CircularProgress/>
    </Box>
  </Box>)
}

export default GoogleSigninView
