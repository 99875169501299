import React, { useCallback } from 'react'
import { DARK_THEME_LOCAL_STORAGE, loadFromLocalStorage, saveToLocalStorage } from '../helpers/preferenceHelper'
import { Theme } from '@material-ui/core/styles'
import myxThemeColorsDark from '../theme/myxThemeColorsDark'
import myxThemeColors from '../theme/myxThemeColors'
import { createTheme } from '@material-ui/core'
import { getMyxMuiOverrides } from '../theme/MyxMuiOverrides'

const themeContext = React.createContext(null)

export type ThemeProviderType = {
  theme: Theme | undefined
  handleThemeChange: () => void
  dark: boolean
}

function useThemeContext(): ThemeProviderType {
  const context = React.useContext(themeContext)
  if (!context) {
    throw new Error('useThemeContext must be used in a ThemeProvider')
  }
  return context
}

function ThemeProvider(props: any) {

  const [ dark, setDark ] = React.useState<boolean>(loadFromLocalStorage(DARK_THEME_LOCAL_STORAGE))
  const [ theme, setTheme ] = React.useState<Theme | undefined>(createTheme(myxThemeColors))

  const updateTheme = useCallback(() => {
    const colorOptions = dark ? myxThemeColorsDark : myxThemeColors
    const themeColorOptions = createTheme(colorOptions)

    const _allOverrides = getMyxMuiOverrides(themeColorOptions)
    const _theme = createTheme(_allOverrides)
    setTheme(_theme)
  }, [ dark ])

  React.useEffect(() => {
    updateTheme()
  }, [ dark, updateTheme ])

  function handleThemeChange() {
    saveToLocalStorage(DARK_THEME_LOCAL_STORAGE, !dark)
    setDark(!dark)
  }

  const value = React.useMemo(() => {
    return { theme, handleThemeChange, dark }
  },

  // eslint-disable-next-line
    [theme, dark])
  return <themeContext.Provider value={value}{...props}/>
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { ThemeProvider, useThemeContext }
